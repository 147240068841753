// @flow
import Promise from 'bluebird';

import * as Zen from 'lib/Zen';
import Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';
import Dimension from 'models/core/wip/Dimension';
import QueryFilterUtil from 'models/core/wip/QueryFilter/QueryFilterUtil';
import type {
  QueryFilter,
  SerializedQueryFilter,
} from 'models/core/wip/QueryFilter/types';
import type { Serializable } from 'lib/Zen';
import type { SerializedCohortForQuery } from 'models/core/wip/Calculation/CohortCalculation/Cohort';

type DefaultValues = {
  cohorts: Zen.Array<Cohort>,
  filter: QueryFilter | null,
};

type RequiredValues = {
  dimension: string,
};

type SerializedCohortCalculation = {
  cohorts: $ReadOnlyArray<Zen.Serialized<Cohort>>,
  dimension: string,

  // NOTE(stephen): An empty object is a valid serialized filter, but it will
  // cause issues with flow if the type is defined with it. Choosing to define
  // an object with an optional type property so we can get flow to properly
  // refine during deserialization. It will never be used.
  filter: SerializedQueryFilter | { type?: void, ... } | null,
  type: 'COHORT',
};

export type SerializedCohortCalculationForQuery = {
  cohorts: $ReadOnlyArray<SerializedCohortForQuery>,
  dimension: string,
  filter: SerializedQueryFilter | { type?: void, ... } | null,
  type: 'COHORT',
};

/**
 * A cohort calculation will compute the number of unique dimension values that
 * pass through a series of complex rules. For a unique dimension value to be
 * counted in the final result, it must be counted by at least one of the
 * provided cohorts.
 */
class CohortCalculation
  extends Zen.BaseModel<CohortCalculation, RequiredValues, DefaultValues>
  implements Serializable<SerializedCohortCalculation>
{
  tag: 'COHORT' = 'COHORT';

  static defaultValues: DefaultValues = {
    cohorts: Zen.Array.create(),
    filter: null,
  };

  static deserializeAsync(
    values: SerializedCohortCalculation,
  ): Promise<Zen.Model<CohortCalculation>> {
    const { cohorts, dimension, filter } = values;
    const filterPromise =
      filter === null || filter.type === undefined
        ? Promise.resolve(null)
        : QueryFilterUtil.deserializeAsync(filter);
    return Promise.all([
      Promise.all(cohorts.map(Cohort.deserializeAsync)),
      filterPromise,
    ]).then(([fetchedCohorts, fetchedFilter]) =>
      CohortCalculation.create({
        cohorts: Zen.Array.create(fetchedCohorts),
        dimension: Dimension.deserializeToString(dimension),
        filter: fetchedFilter,
      }),
    );
  }

  static UNSAFE_deserialize(
    values: SerializedCohortCalculation,
  ): Zen.Model<CohortCalculation> {
    const cohorts = Zen.Array.create(
      values.cohorts.map(Cohort.UNSAFE_deserialize),
    );
    const newDimension = Dimension.deserializeToString(values.dimension);
    if (values.filter === null || values.filter.type === undefined) {
      return CohortCalculation.create({
        cohorts,
        dimension: newDimension,
        filter: null,
      });
    }

    return CohortCalculation.create({
      cohorts,
      dimension: newDimension,
      filter: QueryFilterUtil.UNSAFE_deserialize(values.filter),
    });
  }

  serialize(): SerializedCohortCalculation {
    const filter = this._.filter();
    return {
      cohorts: this._.cohorts().mapValues(c => c.serialize()),
      dimension: this._.dimension(),
      filter: filter ? filter.serialize() : {},
      type: this.tag,
    };
  }

  serializeForQuery(): SerializedCohortCalculationForQuery {
    const filter = this._.filter();
    return {
      cohorts: this._.cohorts().mapValues(c => c.serializeForQuery()),
      dimension: this._.dimension(),
      filter: filter ? filter.serialize() : {},
      type: this.tag,
    };
  }
}

export default ((CohortCalculation: $Cast): Class<
  Zen.Model<CohortCalculation>,
>);
