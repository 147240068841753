// @flow
import * as React from 'react';

import CustomizableTimeInterval from 'models/core/wip/QueryFilterItem/CustomizableTimeInterval';
import DateFilterCustomizationModule from 'components/common/QueryBuilder/CustomizableFilterTag/DateFilterCustomizationModule';
import I18N from 'lib/I18N';
import Popover from 'components/ui/Popover';
import QueryItemTag from 'components/common/QueryBuilder/QueryItemTag';
import SegmentOptionBlock from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/SegmentOptionBlock';
import type CohortSegment from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';

type Props = {
  onSegmentChange: CohortSegment => void,
  segment: CohortSegment,
};

const DEFAULT_TIME_INTERVAL =
  CustomizableTimeInterval.createDefaultInterval().dateType('ALL_TIME');

export default function TimeIntervalOption({
  onSegmentChange,
  segment,
}: Props): React.Node {
  const [showSelector, setShowSelector] = React.useState<boolean>(false);
  const ref = React.useRef();

  const timeInterval = segment.timeInterval();
  const tagText =
    timeInterval !== undefined
      ? timeInterval.displayValue()
      : I18N.text('any time');

  const onIntervalChange = React.useCallback(
    (item: CustomizableTimeInterval) =>
      onSegmentChange(segment.timeInterval(item)),
    [segment, onSegmentChange],
  );
  const onRemoveTagClick = React.useCallback(() => {
    onSegmentChange(segment.timeInterval(undefined));
    setShowSelector(false);
  }, [segment, onSegmentChange]);
  const option = (
    <div ref={ref}>
      <QueryItemTag
        className="time-interval-option__tag"
        item={timeInterval}
        onRemoveTagClick={onRemoveTagClick}
        onTagClick={() => setShowSelector(true)}
        removable={timeInterval !== undefined}
        showDragHandle={false}
        text={tagText}
      />
      <Popover
        anchorElt={ref.current}
        anchorOrigin={Popover.Origins.BOTTOM_LEFT}
        blurType={Popover.BlurTypes.DOCUMENT}
        containerType="none"
        doNotFlip
        isOpen={showSelector}
        keepInWindow
        onRequestClose={() => setShowSelector(false)}
        popoverOrigin={Popover.Origins.TOP_LEFT}
      >
        <DateFilterCustomizationModule
          itemToCustomize={timeInterval || DEFAULT_TIME_INTERVAL}
          onApplyClick={() => setShowSelector(false)}
          onItemCustomized={onIntervalChange}
        />
      </Popover>
    </div>
  );
  return (
    <SegmentOptionBlock
      option={option}
      prefix={I18N.text('during')}
      title={I18N.text('Time')}
    />
  );
}
