// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function StarInSquare(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#FFF5C2" height="16" rx="4" width="16" />
      <path
        d="M8 10.635L11.09 12.5L10.27 8.985L13 6.62L9.405 6.315L8 3L6.595 6.315L3 6.62L5.73 8.985L4.91 12.5L8 10.635Z"
        fill="#313234"
      />
    </svg>
  );
}
