// @flow
import { ValidationError } from 'yup';

export function handleValidationError<E>(
  error: any,
  setErrors: E => void,
): void {
  if (error instanceof ValidationError) {
    setErrors(
      // Convert the array of errors to a errors object
      error.inner.reduce((acc, innerErr) => {
        if (!acc[innerErr.path]) {
          acc[innerErr.path] = innerErr.message;
        }
        return acc;
      }, {}),
    );
  } else {
    throw error;
  }
}
