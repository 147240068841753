// @flow
import * as Zen from 'lib/Zen';
import NumericValueCohortFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilter';
import type { NumericValueCohortFilterWrapper } from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';
import type { Serializable } from 'lib/Zen';

type Values = {
  value: number,
};

type SerializedLessThanFilter = {
  type: 'LESS_THAN',
  value: number,
};

class LessThanFilter
  extends Zen.BaseModel<LessThanFilter, Values>
  implements
    Serializable<SerializedLessThanFilter>,
    NumericValueCohortFilterWrapper
{
  static tag: 'LESS_THAN' = 'LESS_THAN';
  tag: 'LESS_THAN' = 'LESS_THAN';

  static deserialize(
    serializedLessThanFilter: SerializedLessThanFilter,
  ): Zen.Model<LessThanFilter> {
    const { value } = serializedLessThanFilter;

    return LessThanFilter.create({ value });
  }

  filter(): NumericValueCohortFilter {
    return NumericValueCohortFilter.create({
      upperBound: this._.value(),
      upperBoundStrict: true,
    });
  }

  serialize(): SerializedLessThanFilter {
    return { type: this.tag, value: this._.value() };
  }
}

export default ((LessThanFilter: $Cast): Class<Zen.Model<LessThanFilter>>);
