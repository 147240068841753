// @flow
import * as React from 'react';

import I18N from 'lib/I18N';
import Icon from 'components/ui/Icon';
import Popover from 'components/ui/Popover';
import Spacing from 'components/ui/Spacing';
import useBoolean from 'lib/hooks/useBoolean';
import { IS_ZENYSIS_USER, onLinkClicked } from 'util/util';
import { asButton, isUserInGroup } from 'components/Navbar/util';

const IS_HELPDESK_ENABLED = window.__JSON_FROM_BACKEND.deploymentName !== 'pk';

const INTEGRATION_REQUESTORS_GROUP_NAME = 'Integration Requestors';

const ENABLE_INTEGRATION_REQUEST_BUTTON = [
  'afsa',
  'beyond_zero',
  'za',
].includes(window.__JSON_FROM_BACKEND.deploymentName);

export default function HelpButton(): React.Element<typeof React.Fragment> {
  const helpButtonRef = React.useRef();

  const [isMenuOpen, openMenu, closeMenu] = useBoolean(false);
  const [isIntegrationRequestor, setIsIntegrationRequestor] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    // Add a callback function so that crisp chat box will open automatically
    // if message is received
    if (window.$crisp !== undefined) {
      window.$crisp.push([
        'on',
        'message:received',
        () => window.$crisp.do('chat:open'),
      ]);
    }
    if (ENABLE_INTEGRATION_REQUEST_BUTTON) {
      isUserInGroup(INTEGRATION_REQUESTORS_GROUP_NAME).then(
        setIsIntegrationRequestor,
      );
    }
  }, []);

  const onButtonClick = React.useCallback(() => {
    if (isMenuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  }, [isMenuOpen, closeMenu, openMenu]);

  const onSearchButtonClick = React.useCallback(() => {
    window.$crisp.do('helpdesk:search');
    closeMenu();
  }, [closeMenu]);

  const onChatButtonClick = React.useCallback(() => {
    window.$crisp.do('chat:open');
    closeMenu();
  }, [closeMenu]);

  const searchButton = (
    <button
      className="help-button-menu__item"
      onClick={onSearchButtonClick}
      type="button"
    >
      {I18N.text('Search Helpdesk')}
    </button>
  );

  const maybeRenderIntegrationRequestsButton = React.useCallback(() => {
    if (
      !ENABLE_INTEGRATION_REQUEST_BUTTON ||
      (!isIntegrationRequestor && !IS_ZENYSIS_USER)
    ) {
      return null;
    }
    const onIntegrationRequestsLinkClick = e =>
      onLinkClicked(
        'https://forms.monday.com/forms/embed/ce05ab94beb18c970b178d1c5bef9de2?r=use1%22%20width=%22650%22%20height=%22500%22%20style=%22border:%200;%20box-shadow:%205px%205px%2056px%200px%20rgba(0,0,0,0.25)',
        e,
        'Integration requests navigation link clicked',
        undefined,
        true,
      );
    return (
      <button
        className="help-button-menu__item"
        onClick={onIntegrationRequestsLinkClick}
        type="button"
      >
        <I18N>Integration Requests</I18N>
      </button>
    );
  }, [isIntegrationRequestor]);

  const chatButton = (
    <button
      className="help-button-menu__item"
      onClick={onChatButtonClick}
      type="button"
    >
      {I18N.text('Chat with us')}
    </button>
  );

  const menuOptions = (
    <div className="help-button-menu">
      {IS_HELPDESK_ENABLED && searchButton}
      {chatButton}
      {maybeRenderIntegrationRequestsButton()}
    </div>
  );

  // NOTE(nina): We use 'top' instead of setting the margin or padding because
  // it is the only way to position the icon without also modifying the
  // position of the text.
  const icon = (
    <Spacing marginRight="xxs" style={{ display: 'inline-block', top: 2 }}>
      <Icon type="question-sign" />
    </Spacing>
  );

  return (
    <React.Fragment>
      <span ref={helpButtonRef}>
        {asButton(
          () => onButtonClick(),
          I18N.text('Help'),
          false,
          null,
          '',
          'help-button',
          icon,
        )}
      </span>
      <Popover
        anchorElt={helpButtonRef.current}
        containerType={Popover.Containers.EMPTY}
        isOpen={isMenuOpen}
        onRequestClose={closeMenu}
        popoverOrigin={Popover.Origins.BOTTOM_LEFT}
      >
        {menuOptions}
      </Popover>
    </React.Fragment>
  );
}
