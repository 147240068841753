// @flow
import invariant from 'invariant';

import * as Zen from 'lib/Zen';
import DruidCaseType from 'models/CaseManagementApp/DruidCaseType';
import I18N from 'lib/I18N';
import Moment from 'models/core/wip/DateTime/Moment';
import type CaseStatusDescriptor from 'models/CaseManagementApp/CaseStatusDescriptor';
import type { Deserializable } from 'lib/Zen';

type RequiredValues = {
  /** The last date data is available for this case */
  lastDateAvailable: Moment,

  /**
   * Dimension values we got from druid. This maps a dimension id to the
   * dimension's value (e.g. DistrictName => Beira).
   *
   * If a dimension has a single value, then the value is just a string. If it
   * has multiple values, then we use an array of values.
   *
   * NOTE(pablo): we intentionally don't represent this just as an array of values,
   * with singleton arrays for single values. That takes up too much memory which
   * becomes very significant when there are tens of thousands of cases. Storing
   * singleton values as strings rather than arrays cut down the memory usage of
   * a DruidCaseCoreInfo by 20%. Very significant at 20K+ cases.
   */
  metadataFromDruidDimensions: Zen.Map<?(string | $ReadOnlyArray<string>)>,

  /**
   * Metadata values we get from druid fields. This maps a field id to that
   * field's value (e.g. count_positive_malaria => '42')
   */
  metadataFromDruidFields: Zen.Map<?string>,

  /**
   * The display name of this case (e.g. 'Cidade de Pemba'). This is the
   * dimension value of the DruidCaseType's `primaryDruidDimension`.
   *
   */
  name: string,

  /** The status of the case */
  status: CaseStatusDescriptor,

  /** The type for druid cases */
  type: DruidCaseType,
};

type DerivedValues = {
  /** The druid dimension represented by this case (e.g. 'DistrictName') */
  caseType: string,

  /**
   * The dimension values used to uniquely identify this case. This includes
   * the DruidCaseType's primaryDruidDimension, and all the metadata dimensions
   * that have been flagged to treat as a primary dimension. Any missing values
   * will be empty strings. They will NOT be null or undefined.
   */
  primaryDimensionValues: Zen.Map<string>,
};

type SerializedDruidCaseCoreInfo = {
  caseTypeURI: string,
  lastDateAvailable: number,
  metadataDimensionValues: {
    [dimensionName: string]: ?(string | Array<string>),
  },
  statusURI: string,
};

type DruidDeserializationConfig = {
  caseType: DruidCaseType,
  druidFieldValues: { [fieldId: string]: ?string, ... } | void,
};

/**
 * This model represents the core info to provide a limited view of a CaseUnit.
 * This is the type of model we'd load when doing expensive queries (like
 * getAllCases, so that there is less we have to query for).
 */
class DruidCaseCoreInfo
  extends Zen.BaseModel<DruidCaseCoreInfo, RequiredValues, {}, DerivedValues>
  implements
    Deserializable<SerializedDruidCaseCoreInfo, DruidDeserializationConfig>
{
  static derivedConfig: Zen.DerivedConfig<DruidCaseCoreInfo, DerivedValues> = {
    // TODO(pablo): rename to primaryDruidDimension
    caseType: [
      Zen.hasChangedDeep('type'),
      (caseUnit: Zen.Model<DruidCaseCoreInfo>) =>
        caseUnit.type().primaryDruidDimension(),
    ],

    primaryDimensionValues: [
      Zen.hasChangedDeep('type', 'metadataFromDruidDimensions'),
      (caseUnit: Zen.Model<DruidCaseCoreInfo>) =>
        caseUnit
          .metadataFromDruidDimensions()
          .filter((values, dimensionName) => {
            // TODO(pablo): add a helper function and derived value to
            // CaseType to get set of primary dimensions
            const dimensionInfo = caseUnit
              .type()
              .dimensionInfo()
              .get(dimensionName);

            return dimensionInfo
              ? dimensionInfo.treatAsPrimaryDimension
              : false;
          })
          .map((values, dimensionName) => {
            if (Array.isArray(values)) {
              const valStrs = values.join(', ');
              window.Rollbar.error(
                `Primary dimensions should be strings, not arrays. In dimension '${dimensionName}' we found '${valStrs}'`,
              );
              return values[0] || '';
            }

            // primary values must never be null or undefined. Use empty
            // string as a fallback.
            return values || '';
          }),
    ],
  };

  static deserialize(
    serializedDruidCaseCoreInfo: SerializedDruidCaseCoreInfo,
    druidDeserializationConfig: DruidDeserializationConfig,
  ): Zen.Model<DruidCaseCoreInfo> {
    const { lastDateAvailable, metadataDimensionValues, statusURI } =
      serializedDruidCaseCoreInfo;
    const { caseType, druidFieldValues } = druidDeserializationConfig;
    const namingDimension = caseType.namingDruidDimension();
    const nameVals = metadataDimensionValues[namingDimension] || '';

    const completeDruidFieldValues = druidFieldValues || {};
    if (druidFieldValues === undefined) {
      caseType.metadataFromDruidFields().forEach(fieldInfo => {
        completeDruidFieldValues[fieldInfo.fieldId] = null;
      });
    }

    return DruidCaseCoreInfo.create({
      lastDateAvailable: Moment.create(lastDateAvailable),
      metadataFromDruidDimensions: Zen.Map.create(metadataDimensionValues),
      metadataFromDruidFields: Zen.Map.create(completeDruidFieldValues),
      name: typeof nameVals === 'string' ? nameVals : nameVals[0],
      status: caseType.statusDescriptors().forceGet(statusURI),
      type: caseType,
    });
  }

  /**
   * Get the value for the metadata extracted by a a given Druid fieldId,
   * with no formatting. Just get the value exactly as it was stored in Druid,
   * but as a number.
   */
  getFieldValue(fieldId: string): ?number {
    const val = this._.metadataFromDruidFields().forceGet(fieldId);
    if (val !== undefined && val !== null) {
      if (val === '') {
        return undefined;
      }
      return Number(val);
    }
    return val;
  }

  /**
   * Get the value for the metadata extracted by a a given Druid fieldId,
   * formatted to a string according to its type. For example, booleans
   * are returned as 'Yes'/'No' values.
   */
  getFormattedFieldValue(fieldId: string): string {
    const val = this.getFieldValue(fieldId);
    if (val === undefined || val === null) {
      return '';
    }
    const fieldInfo = this._.type()
      .metadataFromDruidFields()
      .find(f => f.fieldId === fieldId);
    invariant(fieldInfo, `Missing field info for '${fieldId}'`);
    if (fieldInfo.type === 'BOOLEAN') {
      return val > 0 ? I18N.textById('Yes') : I18N.textById('No');
    }
    return String(val);
  }
}

export default ((DruidCaseCoreInfo: $Cast): Class<
  Zen.Model<DruidCaseCoreInfo>,
>);
