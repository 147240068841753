// @flow
import { useEffect, useState, useMemo } from 'react';

import DataUploadService from 'services/DataUploadApp/DataUploadService';
import { cancelPromise } from 'util/promiseUtil';
import type { PipelineRunMetadata } from 'models/DataUploadApp/types';

export default function usePipelineTimes(): {
  lastPipelineRuntime: Date | void,
  nextPipelineRuntime: Date | void,
} {
  const [pipelineRunMetadata, setpipelineRunMetadata] =
    useState<PipelineRunMetadata | void>(undefined);

  useEffect(() => {
    const promise = DataUploadService.getPipelineRunMetadata().then(
      setpipelineRunMetadata,
    );
    return () => cancelPromise(promise);
  }, []);

  return useMemo(() => {
    const lastSuccessfulPipelineData = pipelineRunMetadata?.lastPipelineRuntime;
    const nextPipelineRuntime = pipelineRunMetadata?.nextPipelineRuntime;

    return {
      lastPipelineRuntime: lastSuccessfulPipelineData
        ? new Date(lastSuccessfulPipelineData)
        : undefined,
      nextPipelineRuntime: nextPipelineRuntime
        ? new Date(nextPipelineRuntime)
        : undefined,
    };
  }, [pipelineRunMetadata]);
}
