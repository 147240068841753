// @flow
import * as Zen from 'lib/Zen';
import CaseEvent from 'models/CaseManagementApp/CaseEvent';
import DruidCaseCoreInfo from 'models/CaseManagementApp/DruidCaseCoreInfo';
import DruidCaseType from 'models/CaseManagementApp/DruidCaseType';
import type { Deserializable } from 'lib/Zen';

type Values = {
  coreInfo: DruidCaseCoreInfo,

  /** URI for this case. */
  uri: string,
};

type DefaultValues = {
  events: Zen.Array<CaseEvent>,

  /** A map of URI to values of metadata rows entered by the user */
  metadataValuesFromUser: Zen.Map<string>,
};

// Move some commonly accessed pieces of information from `coreInfo` to a
// top-level `CaseUnit` value, so that we don't have to do things like
// case.coreInfo().name() every time.
type DerivedValues = {
  caseType: string,
  name: string,
  type: DruidCaseType,
};

type SerializedDruidCase = {
  ...Zen.Serialized<DruidCaseCoreInfo>,
  $uri: string,
};

type DeserializationConfig = {
  ...Zen.DeserializationConfig<DruidCaseCoreInfo>,
  events: $ReadOnlyArray<Zen.Serialized<CaseEvent>>,
  metadataValuesFromUser: $ReadOnlyArray<{
    caseMetadataType: string,
    value: string,
  }>,
};

class DruidCase
  extends Zen.BaseModel<DruidCase, Values, DefaultValues, DerivedValues>
  implements Deserializable<SerializedDruidCase, DeserializationConfig>
{
  tag: 'DRUID' = 'DRUID';
  static defaultValues: DefaultValues = {
    events: Zen.Array.create(),
    metadataValuesFromUser: Zen.Map.create(),
  };

  static derivedConfig: Zen.DerivedConfig<DruidCase, DerivedValues> = {
    caseType: [
      Zen.hasChangedDeep('coreInfo.caseType'),
      (caseUnit: Zen.Model<DruidCase>) => caseUnit.coreInfo().caseType(),
    ],
    name: [
      Zen.hasChangedDeep('coreInfo.name'),
      (caseUnit: Zen.Model<DruidCase>) => caseUnit.coreInfo().name(),
    ],
    type: [
      Zen.hasChangedDeep('coreInfo.type'),
      (caseUnit: Zen.Model<DruidCase>) => caseUnit.coreInfo().type(),
    ],
  };

  static deserialize(
    serializedDruidCase: SerializedDruidCase,
    deserializationConfig: DeserializationConfig,
  ): Zen.Model<DruidCase> {
    const { $uri, ...serializedDruidCaseCoreInfo } = serializedDruidCase;
    const { events, metadataValuesFromUser, ...coreInfoDeserializationConfig } =
      deserializationConfig;
    const metadataMap = {};
    metadataValuesFromUser.forEach(({ caseMetadataType, value }) => {
      metadataMap[caseMetadataType] = value;
    });

    return DruidCase.create({
      coreInfo: DruidCaseCoreInfo.deserialize(
        serializedDruidCaseCoreInfo,
        coreInfoDeserializationConfig,
      ),
      events: Zen.deserializeToZenArray(CaseEvent, events),
      metadataValuesFromUser: Zen.Map.create(metadataMap),
      uri: $uri,
    });
  }
}

export default ((DruidCase: $Cast): Class<Zen.Model<DruidCase>>);
