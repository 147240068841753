// @flow
import { Record, Map as ImmutableMap } from 'immutable';
import type { RecordFactory } from 'immutable';

import ColumnSpec from 'models/DataUploadApp/ColumnSpec';
import type {
  ApiUnpublishedDimension,
  ApiUnpublishedField,
} from 'models/DataUploadApp/types';
import type {
  SerializedInputColumnSpec,
  SerializedOutputColumnSpec,
} from 'models/DataUploadApp/ColumnSpec';

const { locale } = window.__JSON_FROM_BACKEND;

export type SerializedInputFileSummary = {
  columnMapping: $ReadOnlyArray<SerializedInputColumnSpec>,
  filePath: string,
  id: number,
  lastModified: string,
  userFileName: string,
};

export type SerializedOutputFileSummary = {
  columnMapping: $ReadOnlyArray<SerializedOutputColumnSpec>,
  filePath: string,
  id?: number,
  userFileName: string,
};
type FileSummaryProps = {
  columnMapping: ImmutableMap<string, ColumnSpec>,
  filePath: string,
  id: number,
  lastModified: Date,
  userFileName: string,
};

const FileSummaryRecord: RecordFactory<FileSummaryProps> = Record({
  columnMapping: undefined,
  filePath: undefined,
  id: undefined,
  lastModified: undefined,
  userFileName: undefined,
});

export default class FileSummary extends FileSummaryRecord {
  static deserialize(data: SerializedInputFileSummary): FileSummary {
    const { columnMapping, lastModified } = data;
    const columns = columnMapping.map(column => ColumnSpec.deserialize(column));
    const colMapping = {};
    columns.forEach(column => {
      colMapping[column.name()] = column;
    });

    return new FileSummary({
      columnMapping: ImmutableMap<string, ColumnSpec>(colMapping),
      filePath: data.filePath,
      id: data.id,
      lastModified: new Date(lastModified),
      userFileName: data.userFileName,
    });
  }

  serialize(sourceId: string): SerializedOutputFileSummary {
    return {
      columnMapping: this.columnMapping()
        .valueSeq()
        .toArray()
        .map(column => column.serialize(sourceId)),
      filePath: this.filePath(),
      id: this.id(),
      userFileName: this.userFileName(),
    };
  }

  getUnpublishedDimensions(
    sourceId: string,
  ): $ReadOnlyArray<ApiUnpublishedDimension> {
    return this.columnMapping()
      .valueSeq()
      .toArray()
      .filter(
        columnSpec =>
          columnSpec.columnType() === 'DIMENSION' &&
          columnSpec.isNewColumn() &&
          !columnSpec.ignoreColumn(),
      )
      .map(columnSpec => columnSpec.serialize(sourceId))
      .map(columnSpec => ({
        authorizable: false,
        dimensionCode: columnSpec.match || '',
        filterable: true,
        nameTranslations: locale
          ? {
              [locale]: columnSpec.name,
            }
          : {},
        published: false,
      }));
  }

  getUnpublishedFields(sourceId: string): $ReadOnlyArray<ApiUnpublishedField> {
    return this.columnMapping()
      .valueSeq()
      .toArray()
      .filter(
        columnSpec =>
          columnSpec.columnType() === 'FIELD' &&
          columnSpec.isNewColumn() &&
          !columnSpec.ignoreColumn(),
      )
      .map(columnSpec => columnSpec.serialize(sourceId))
      .map(columnSpec => ({
        id: columnSpec.match,
        name: columnSpec.name,
        shortName: columnSpec.name,
      }));
  }

  columnMapping(): ImmutableMap<string, ColumnSpec> {
    return this.get('columnMapping');
  }

  filePath(): string {
    return this.get('filePath');
  }

  id(): number {
    return this.get('id');
  }

  lastModified(): Date {
    return this.get('lastModified');
  }

  userFileName(): string {
    return this.get('userFileName');
  }

  setColumnMapping<T>(value: ImmutableMap<string, ColumnSpec>): T {
    return ((this.set('columnMapping', value): any): T);
  }

  setFilePath<T>(value: string): T {
    return ((this.set('filePath', value): any): T);
  }

  setId<T>(value: number): T {
    return ((this.set('id', value): any): T);
  }

  setLastModified<T>(value: Date): T {
    return ((this.set('lastModified', value): any): T);
  }

  setUserFileName<T>(value: string): T {
    return ((this.set('userFileName', value): any): T);
  }
}
