// @flow
import invariant from 'invariant';

import * as Zen from 'lib/Zen';
import CaseEvent from 'models/CaseManagementApp/CaseEvent';
import CaseMetadataDescriptor from 'models/CaseManagementApp/CaseMetadataDescriptor';
import CaseStatusDescriptor from 'models/CaseManagementApp/CaseStatusDescriptor';
import { sortString } from 'util/stringUtil';
import type {
  CaseType,
  SerializedCaseType,
} from 'models/CaseManagementApp/util';
import type { Serializable } from 'lib/Zen';

type DimensionMetadataInfo = {
  +description: string,
  +dimensionName: string,
  +dossierSection: string | void,
  +showInOverviewTable: boolean,
  +treatAsPrimaryDimension: boolean,
};

type FieldMetadataInfo = {
  +description: string,
  +displayName: string,
  +dossierSection: string | void,
  +fieldId: string,
  +showInOverviewTable: boolean,
  +type: 'BOOLEAN' | 'NUMBER',
};

type SerializedDimensionMetadataInfo = $ReadOnly<{
  ...DimensionMetadataInfo,
  +dossierSection: string | null,
}>;

type SerializedFieldMetadataInfo = $ReadOnly<{
  ...FieldMetadataInfo,
  +dossierSection: string | null,
}>;

type RequiredValues = {
  ...CaseType,

  /**
   * Metadata populated from druid dimensions. These are additional pieces
   * of information, like District or Province, that you want to have loaded
   * for any case of this CaseType.
   */
  metadataFromDruidDimensions: Zen.Array<DimensionMetadataInfo>,

  /**
   * Metadata populated from druid fields. These are additional pieces
   * of information, like Number of Cases Positive, that you want to have
   * loaded for any case of this CaseType.
   */
  metadataFromDruidFields: Zen.Array<FieldMetadataInfo>,

  /**
   * The druid dimension used as the display name for any case of this CaseType.
   * This is not necessarily the same as the primaryDruidDimension. For example,
   * a patient might use their Patient ID as the primaryDruidDimension, but the
   * namingDruidDimension would be the Patient Name.
   */
  namingDruidDimension: string,

  /**
   * The primary druid dimension that represents this CaseType,
   * E.g. 'DistrictName'
   */
  primaryDruidDimension: string,
};

type DefaultValues = {
  /** Whether or not the case name has PII */
  caseNameHasPII: boolean,

  /** The dimensions that should be treated as PII */
  piiDimensions: $ReadOnlySet<string>,
};

type DerivedValues = {
  /**
   * This is the same information as `metadataFromDruidDimensions` but turned into a
   * Map to make any dimension's info easily accessible in O(1)
   */
  dimensionInfo: $ReadOnlyMap<string, DimensionMetadataInfo>,

  /**
   * Get all the dimension names that are used to uniquely identify this case.
   */
  primaryDimensionNames: Zen.Array<string>,
};

type SerializedDruidCaseType = {
  ...SerializedCaseType,
  metadataFromDruidDimensions: $ReadOnlyArray<SerializedDimensionMetadataInfo>,
  metadataFromDruidFields: $ReadOnlyArray<SerializedFieldMetadataInfo>,
  namingDruidDimension: string,
  primaryDruidDimension: string,
  spec: {
    +caseNameHasPII?: boolean,
    +piiDimensions?: $ReadOnlyArray<string>,
  } | null,
};

/**
 * DruidCaseType is a description of the configs and default values for a druid
 * case.
 */
class DruidCaseType
  extends Zen.BaseModel<
    DruidCaseType,
    RequiredValues,
    DefaultValues,
    DerivedValues,
  >
  implements Serializable<SerializedDruidCaseType>
{
  tag: 'DRUID' = 'DRUID';

  static defaultValues: DefaultValues = {
    caseNameHasPII: false,
    piiDimensions: new Set(),
  };

  static derivedConfig: Zen.DerivedConfig<DruidCaseType, DerivedValues> = {
    dimensionInfo: [
      Zen.hasChanged('metadataFromDruidDimensions'),
      caseType =>
        caseType
          .metadataFromDruidDimensions()
          .reduce(
            (map, dim) => map.set(dim.dimensionName, dim),
            new Map<string, DimensionMetadataInfo>(),
          ),
    ],
    primaryDimensionNames: [
      Zen.hasChanged('metadataFromDruidDimensions'),
      caseType =>
        caseType
          .metadataFromDruidDimensions()
          .filter(d => d.treatAsPrimaryDimension)
          .map(d => d.dimensionName)
          .sort(sortString),
    ],
  };

  static deserialize(
    serializedDruidCaseType: SerializedDruidCaseType,
  ): Zen.Model<DruidCaseType> {
    const {
      $uri,
      canUsersAddEvents,
      caseType,
      defaultDashboardQueries,
      defaultEvents,
      defaultStatusUri,
      isMetadataExpandable,
      metadataDescriptors,
      metadataFromDruidDimensions,
      metadataFromDruidFields,
      namingDruidDimension,
      primaryDruidDimension,
      quickStatsFields,
      showCaseTypeInDossier,
      spec,
      statusDescriptors,
    } = serializedDruidCaseType;

    const metadataDescriptorsMap = {};
    Zen.deserializeArray(CaseMetadataDescriptor, metadataDescriptors).forEach(
      metadata => {
        metadataDescriptorsMap[metadata.uri()] = metadata;
      },
    );

    const statusDescriptorsMap = {};
    Zen.deserializeArray(CaseStatusDescriptor, statusDescriptors).forEach(
      status => {
        statusDescriptorsMap[status.uri()] = status;
      },
    );
    const statusDescriptorsZenMap = Zen.Map.create(statusDescriptorsMap);

    return DruidCaseType.create({
      canUsersAddEvents,
      caseType,
      isMetadataExpandable,
      namingDruidDimension,
      primaryDruidDimension,
      showCaseTypeInDossier,
      caseNameHasPII: spec ? spec.caseNameHasPII : undefined,
      defaultDashboardQueries: Zen.Array.create(
        defaultDashboardQueries.map(dashboardQuery => ({
          fields: Zen.Array.create(dashboardQuery.fields),
          granularity: dashboardQuery.granularity,
          viewType: dashboardQuery.viewType,
        })),
      ),
      defaultEvents: Zen.deserializeToZenArray(CaseEvent, defaultEvents),
      defaultStatus: statusDescriptorsZenMap.forceGet(defaultStatusUri),
      metadataDescriptors: Zen.Map.create(metadataDescriptorsMap),
      metadataFromDruidDimensions: Zen.Array.create(
        metadataFromDruidDimensions.map(info => ({
          ...info,
          dossierSection: info.dossierSection || undefined,
        })),
      ),
      metadataFromDruidFields: Zen.Array.create(
        metadataFromDruidFields.map(info => ({
          ...info,
          dossierSection: info.dossierSection || undefined,
        })),
      ),
      piiDimensions:
        spec && spec.piiDimensions ? new Set(spec.piiDimensions) : undefined,
      quickStatsFields: Zen.Array.create(quickStatsFields),
      statusDescriptors: statusDescriptorsZenMap,
      uri: $uri,
    });
  }

  /**
   * Given a map of all dimensionNames to dimension values, generate a unique
   * case key, which is a double-underscore-separated join of all primary
   * dimension values for this case type.
   */
  getUniqueCaseKey(dimensionValuesObj: {
    +[dimensionName: string]: ?(string | $ReadOnlyArray<string>),
    ...
  }): string {
    const dimVals = this._.primaryDimensionNames().map(dimName => {
      const val = dimensionValuesObj[dimName];
      return (Array.isArray(val) ? val[0] : val) || '';
    });
    return dimVals.join('__');
  }

  /**
   * Given a dictionary of dimension values, return a dictionary of just the
   * primary dimension values. Primary values will always be strings. If a value
   * does not exist it will be an empty string, it will NOT be null or undefined.
   */
  getPrimaryDimensionValues(dimensionValuesObj: {
    +[dimensionName: string]: ?(string | $ReadOnlyArray<string>),
  }): { +[dimensionName: string]: string, ... } {
    const primaryValues: { [string]: string, ... } = {};
    this._.primaryDimensionNames().forEach(dimName => {
      const v = dimensionValuesObj[dimName];
      const value = Array.isArray(v) ? v[0] : v;
      primaryValues[dimName] = value || '';
    });
    return primaryValues;
  }

  /**
   * Get the DimensionMetadataInfo for a given `dimensionName`
   */
  getDimensionInfo(dimensionName: string): DimensionMetadataInfo {
    const info = this._.dimensionInfo().get(dimensionName);
    invariant(
      info,
      `Could not get dimension info for '${dimensionName}' for case of type '${this._.primaryDruidDimension()}'`,
    );
    return info;
  }

  serialize(): SerializedDruidCaseType {
    const {
      canUsersAddEvents,
      caseNameHasPII,
      caseType,
      defaultDashboardQueries,
      defaultEvents,
      defaultStatus,
      isMetadataExpandable,
      metadataDescriptors,
      metadataFromDruidDimensions,
      metadataFromDruidFields,
      namingDruidDimension,
      piiDimensions,
      primaryDruidDimension,
      quickStatsFields,
      showCaseTypeInDossier,
      statusDescriptors,
      uri,
    } = this.modelValues();

    return {
      canUsersAddEvents,
      caseType,
      isMetadataExpandable,
      namingDruidDimension,
      primaryDruidDimension,
      showCaseTypeInDossier,
      $uri: uri,
      defaultDashboardQueries: defaultDashboardQueries
        .arrayView()
        .map(dashboardQuery => ({
          fields: dashboardQuery.fields.arrayView(),
          granularity: dashboardQuery.granularity,
          viewType: dashboardQuery.viewType,
        })),
      defaultEvents: Zen.serializeArray(defaultEvents),
      defaultStatusUri: defaultStatus.uri(),
      metadataDescriptors: Zen.serializeArray(metadataDescriptors.zenValues()),
      metadataFromDruidDimensions: metadataFromDruidDimensions.mapValues(
        info => ({
          ...info,
          dossierSection: info.dossierSection || null,
        }),
      ),
      metadataFromDruidFields: metadataFromDruidFields.mapValues(info => ({
        ...info,
        dossierSection: info.dossierSection || null,
      })),
      quickStatsFields: quickStatsFields.arrayView(),
      spec: {
        caseNameHasPII,
        piiDimensions: [...piiDimensions],
      },
      statusDescriptors: Zen.serializeArray(statusDescriptors.zenValues()),
    };
  }
}

export default ((DruidCaseType: $Cast): Class<Zen.Model<DruidCaseType>>);
