// @flow
import { Record } from 'immutable';
import type { RecordFactory } from 'immutable';

export type SerializedOutputPipelineDatasource = {
  id: string,
  name: string,
};

export type SerializedInputPipelineDatasource = {
  ...SerializedOutputPipelineDatasource,
  unpublishedFields: number,
};

type PipelineDatasourceProps = {
  id: string,
  name: string,
  unpublishedFields: number,
};

const PipelineDatasourceRecord: RecordFactory<PipelineDatasourceProps> = Record(
  {
    id: undefined,
    name: undefined,
    unpublishedFields: undefined,
  },
);

export default class PipelineDatasource extends PipelineDatasourceRecord {
  static deserialize(
    data: SerializedInputPipelineDatasource,
  ): PipelineDatasource {
    return new PipelineDatasource({
      id: data.id,
      name: data.name,
      unpublishedFields: data.unpublishedFields,
    });
  }

  serialize(): SerializedOutputPipelineDatasource {
    return { id: this.id(), name: this.name() };
  }

  id(): string {
    return this.get('id');
  }

  name(): string {
    return this.get('name');
  }

  unpublishedFields(): number {
    return this.get('unpublishedFields');
  }

  setName<T>(value: string): T {
    return ((this.set('name', value): any): T);
  }
}
