// @flow
import { List, Record } from 'immutable';
import { isBefore } from 'date-fns';
import type { RecordFactory } from 'immutable';

import DataprepFlow from 'models/DataUploadApp/DataprepFlow';
import FileSummary from 'models/DataUploadApp/FileSummary';
import PipelineDatasource from 'models/DataUploadApp/PipelineDatasource';
import { CSV_TYPE, DATAPREP_TYPE } from 'models/DataUploadApp/types';
import { RUNNING_DATAPREP_STATUSES } from 'components/DataUploadApp/constants';
import type {
  SerializedInputSelfServeSource,
  SerializedOutputSelfServeSource,
} from 'services/DataUploadApp/SelfServeSourceService';
import type {
  SourceDateRange,
  ApiUnpublishedDimension,
  ApiUnpublishedField,
  FilePreviewRow,
} from 'models/DataUploadApp/types';

type SelfServeSourceProps = {
  dataprepFlow: DataprepFlow | void,
  // Whether there's errors in the database state
  errorState: boolean,
  filePreview: List<FilePreviewRow> | void,
  fileSummaries: List<FileSummary> | void,
  // TODO: A few of these properties here and in child types are not defined for
  // new sources. Fix it and figure out why immutable + flow isn't flagging it.
  id: number,
  lastModified: Date,
  pipelineDatasource: PipelineDatasource,
  sourceId: string,
  sourceRange: SourceDateRange,
  unpublishedDimensionsCount: number,
  uri: string,
};

const SelfServeSourceRecord: RecordFactory<SelfServeSourceProps> = Record({
  dataprepFlow: undefined,
  errorState: undefined,
  filePreview: undefined,
  fileSummaries: undefined,
  id: undefined,
  lastModified: undefined,
  pipelineDatasource: undefined,
  sourceId: undefined,
  sourceRange: undefined,
  unpublishedDimensionsCount: undefined,
  uri: undefined,
});

export default class SelfServeSource extends SelfServeSourceRecord {
  static deserialize(data: SerializedInputSelfServeSource): SelfServeSource {
    const {
      $uri,
      dataprepFlow,
      errorState,
      filePreview,
      fileSummaries,
      id,
      lastModified,
      pipelineDatasource,
      sourceId,
      sourceRange,
      unpublishedDimensionsCount,
    } = data;

    return new SelfServeSource({
      errorState,
      id,
      sourceId,
      sourceRange,
      unpublishedDimensionsCount,
      dataprepFlow: dataprepFlow
        ? DataprepFlow.deserialize(dataprepFlow)
        : undefined,
      filePreview: filePreview ? List(filePreview) : undefined,
      fileSummaries: fileSummaries
        ? List(fileSummaries.map(FileSummary.deserialize))
        : undefined,
      lastModified: new Date(lastModified),
      pipelineDatasource: PipelineDatasource.deserialize(pipelineDatasource),
      uri: $uri,
    });
  }

  serialize(): SerializedOutputSelfServeSource {
    const dataprepFlow = this.dataprepFlow();
    const fileSummaries = this.fileSummaries();

    return {
      dataUploadFileSummaries: fileSummaries
        ? fileSummaries
            .map(fileSummary => fileSummary.serialize(this.sourceId()))
            .toArray()
        : [],
      dataprepFlow: dataprepFlow ? dataprepFlow.serialize() : null,
      id: this.id(),
      pipelineDatasource: this.pipelineDatasource().serialize(),
      sourceId: this.sourceId(),
      sourceRange: this.sourceRange(),
      /**
       * `unpublishedDimensions` and `unpublishedFields` are not natively apart of the source model.
       * They are computed from the file summaries upon serialization.
       */
      unpublishedDimensions: this.unpublishedDimensions(),
      unpublishedFields: this.unpublishedFields(),
    };
  }

  unpublishedFields(): $ReadOnlyArray<ApiUnpublishedField> {
    const fileSummaries = this.fileSummaries();
    if (!fileSummaries) return [];

    return fileSummaries
      .map(fileSummary => fileSummary.getUnpublishedFields(this.sourceId()))
      .filter(Boolean)
      .toArray()
      .flat();
  }

  unpublishedDimensions(): $ReadOnlyArray<ApiUnpublishedDimension> {
    const fileSummaries = this.fileSummaries();
    if (!fileSummaries) return [];
    return fileSummaries
      .map(fileSummary => fileSummary.getUnpublishedDimensions(this.sourceId()))
      .filter(Boolean)
      .toArray()
      .flat();
  }

  id(): number {
    return this.get('id');
  }

  dataprepFlow(): DataprepFlow | void {
    return this.get('dataprepFlow');
  }

  errorState(): boolean {
    return this.get('errorState');
  }

  filePreview(): List<Object> | void {
    return this.get('filePreview');
  }

  fileSummaries(): List<FileSummary> | void {
    return this.get('fileSummaries');
  }

  lastModified(): Date {
    return this.get('lastModified');
  }

  pipelineDatasource(): PipelineDatasource {
    return this.get('pipelineDatasource');
  }

  sourceId(): string {
    return this.get('sourceId');
  }

  sourceRange(): Object {
    return this.get('sourceRange');
  }

  unpublishedDimensionsCount(): number {
    return this.get('unpublishedDimensionsCount');
  }

  uri(): string {
    return this.get('uri') || '';
  }

  integrationType(): string {
    if (this.get('dataprepFlow')) {
      return DATAPREP_TYPE;
    }
    return CSV_TYPE;
  }

  isLoaded(): boolean {
    let isLoaded = true;
    const dataprepFlow = this.dataprepFlow();
    if (dataprepFlow) {
      isLoaded = false;
      const latestDataprepJob = dataprepFlow.latestDataprepJob();
      if (latestDataprepJob) {
        isLoaded = !RUNNING_DATAPREP_STATUSES.has(latestDataprepJob.status());
      }
    }

    return isLoaded;
  }

  isQueued(lastPipelineRuntime: Date | void): boolean {
    // A source is queued if the pipeline has not run since it's been modified. For dataprep
    // sources, also check if there's a running dataprep job.
    const pipelineQueued = lastPipelineRuntime
      ? isBefore(lastPipelineRuntime, this.lastModified())
      : true;

    const dataprepFlow = this.dataprepFlow();
    if (dataprepFlow) {
      const latestDataprepJob = dataprepFlow.latestDataprepJob();
      if (latestDataprepJob) {
        return (
          pipelineQueued ||
          RUNNING_DATAPREP_STATUSES.has(latestDataprepJob.status())
        );
      }
    }

    return pipelineQueued;
  }

  setId<T>(value: number): T {
    return ((this.set('id', value): any): T);
  }

  setDataprepFlow<T>(value: DataprepFlow): T {
    return ((this.set('dataprepFlow', value): any): T);
  }

  setFileSummaries<T>(value: List<FileSummary>): T {
    return ((this.set('fileSummaries', value): any): T);
  }

  setLastModified<T>(value: Date): T {
    return ((this.set('lastModified', value): any): T);
  }

  setPipelineDatasource<T>(value: PipelineDatasource): T {
    return ((this.set('pipelineDatasource', value): any): T);
  }

  setSourceId<T>(value: string): T {
    return ((this.set('sourceId', value): any): T);
  }

  setSourceRange<T>(value: Object): T {
    return ((this.set('sourceRange', value): any): T);
  }

  setUnpublishedDimensionsCount<T>(value: number): T {
    return ((this.set('unpublishedDimensionsCount', value): any): T);
  }

  setUri<T>(value: string): T {
    return ((this.set('uri', value): any): T);
  }
}
