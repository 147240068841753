// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function Link(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="48"
      viewBox="0 0 49 48"
      width="49"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#313234" height="48" rx="24" width="48" x="0.5" />
      <path
        d="M29.5 19H25.5V21H29.5C31.15 21 32.5 22.35 32.5 24C32.5 25.65 31.15 27 29.5 27H25.5V29H29.5C32.26 29 34.5 26.76 34.5 24C34.5 21.24 32.26 19 29.5 19Z"
        fill="white"
      />
      <path
        d="M23.5 27H19.5C17.85 27 16.5 25.65 16.5 24C16.5 22.35 17.85 21 19.5 21H23.5V19H19.5C16.74 19 14.5 21.24 14.5 24C14.5 26.76 16.74 29 19.5 29H23.5V27Z"
        fill="white"
      />
      <path d="M20.5 23H28.5V25H20.5V23Z" fill="white" />
    </svg>
  );
}
