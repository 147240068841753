// @flow
import * as React from 'react';

import * as Zen from 'lib/Zen';
import BaseModal from 'components/ui/BaseModal';
import CohortCalculationSummaryService from 'services/AdvancedQueryApp/CohortCalculationSummaryService';
import CohortCreationPanel from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel';
import CohortSidePanel from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel';
import CohortSummaryBar from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSummaryBar';
import I18N from 'lib/I18N';
import IndicatorSelector from 'components/AdvancedQueryApp/QueryFormPanel/QueryBuilder/IndicatorSelectionBlock/IndicatorSelector';
import { cancelPromise } from 'util/promiseUtil';
import type Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';
import type CohortCalculation from 'models/core/wip/Calculation/CohortCalculation';
import type Dimension from 'models/core/wip/Dimension';
import type { CohortCalculationSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  calculation: CohortCalculation,
  calculationId: string,
  dimensions: $ReadOnlyArray<Dimension>,
  dimensionValueMap: $PropertyType<
    React.ElementConfig<typeof CohortCreationPanel>,
    'dimensionValueMap',
  >,
  fieldHierarchyRoot: $PropertyType<
    React.ElementConfig<typeof IndicatorSelector>,
    'hierarchyRoot',
  >,
  onCalculationChange: CohortCalculation => void,
  onCloseModal: () => void,
  show: boolean,
  trackItemSelected: $PropertyType<
    React.ElementConfig<typeof CohortCreationPanel>,
    'updateFieldHierarchyOnItemSelect',
  >,
};

// Cohort group labels range from A to Z. Cohort groups receive a label based on
// their position on the page. If the group is removed, then all sibling groups
// will have their label decremented.
export function buildCohortGroupLabel(labelIdx: number): string {
  // I swear to god if someone selects more than 26 cohort groups on a page, I
  // will flip this table over. Handling it here just in case.
  const letterRepeat = Math.floor(labelIdx / 26 + 1);
  return String.fromCharCode((labelIdx % 26) + 65).repeat(letterRepeat);
}

function CohortCreationModal({
  calculation,
  calculationId,
  dimensionValueMap,
  dimensions,
  fieldHierarchyRoot,
  onCalculationChange,
  onCloseModal,
  show = false,
  trackItemSelected,
}: Props): React.Element<typeof BaseModal> {
  // Store all updates the user makes to the calculation in the state. Only
  // update the props calculation when the user explicitly clicks save. This is
  // useful to avoid kicking off new queries when the cohort creation is stil in
  // progress.
  const [currentCalculation, setCurrentCalculation] =
    React.useState<CohortCalculation>(calculation);
  const [calculationSummary, setCalculationSummary] =
    React.useState<CohortCalculationSummary | void>();

  const onCohortsChange = React.useCallback(
    (cohorts: Zen.Array<Cohort>) =>
      setCurrentCalculation(currentCalculation.cohorts(cohorts)),
    [currentCalculation],
  );

  const onDimensionIdChange = React.useCallback(
    (dimensionId: string) =>
      setCurrentCalculation(currentCalculation.dimension(dimensionId)),
    [currentCalculation],
  );

  const onCancelClick = React.useCallback(() => {
    // Reset the current calculation to the input calculation.
    setCurrentCalculation(calculation);
    onCloseModal();
  }, [calculation, onCloseModal]);

  const onSaveClick = React.useCallback(() => {
    onCalculationChange(currentCalculation);
    onCloseModal();
  }, [currentCalculation, onCalculationChange, onCloseModal]);

  const groupLabelsPerCohort = React.useMemo(() => {
    let cohortGroupCount = 0;
    const result = [];

    currentCalculation.cohorts().forEach(cohort => {
      const groupLabels = [];
      cohort.cohortGroups().forEach(() => {
        const groupLabel = buildCohortGroupLabel(cohortGroupCount);
        groupLabels.push(groupLabel);
        cohortGroupCount += 1;
      });
      result.push(groupLabels);
    });
    return result;
  }, [currentCalculation]);

  // If the incoming calculation changes, reset the state. This should only be
  // possible while the modal is closed.
  React.useEffect(() => {
    if (!show) {
      setCurrentCalculation(calculation);
      setCalculationSummary(undefined);
    }
  }, [calculation, show]);

  React.useEffect(() => {
    let request;

    // Only update the calculationSummary if the modal is currently open.
    if (show) {
      setCalculationSummary(undefined);
      request = CohortCalculationSummaryService.fetchSummary(
        calculationId,
        currentCalculation,
      ).then(setCalculationSummary);
    }

    return () => request && cancelPromise(request);
  }, [currentCalculation, calculationId, show]);

  const cohortsSummary =
    calculationSummary !== undefined ? calculationSummary.cohorts : undefined;

  return (
    <BaseModal
      className="cohort-creation-modal"
      closeButtonText={I18N.textById('Cancel')}
      disablePrimaryButton={calculation === currentCalculation}
      onPrimaryAction={onSaveClick}
      onRequestClose={onCancelClick}
      primaryButtonText={I18N.text('Save and close')}
      shouldCloseOnOverlayClick={false}
      show={show}
      title={I18N.text('Create cohort calculation')}
      width="95%"
    >
      <CohortSummaryBar
        cohortCalculationSummary={calculationSummary}
        cohorts={currentCalculation.cohorts()}
        dimensionId={currentCalculation.dimension()}
        dimensions={dimensions}
        groupLabelsPerCohort={groupLabelsPerCohort}
        onDimensionIdChange={onDimensionIdChange}
      />
      <div className="cohort-creation-modal__main-section">
        <CohortSidePanel
          cohorts={currentCalculation.cohorts()}
          cohortsSummary={cohortsSummary}
          groupLabelsPerCohort={groupLabelsPerCohort}
        />
        <CohortCreationPanel
          cohorts={currentCalculation.cohorts()}
          dimensionId={currentCalculation.dimension()}
          dimensions={dimensions}
          dimensionValueMap={dimensionValueMap}
          fieldHierarchyRoot={fieldHierarchyRoot}
          groupLabelsPerCohort={groupLabelsPerCohort}
          onCohortsChange={onCohortsChange}
          updateFieldHierarchyOnItemSelect={trackItemSelected}
        />
      </div>
    </BaseModal>
  );
}

export default (React.memo(
  CohortCreationModal,
): React.AbstractComponent<Props>);
