// @flow
import * as Zen from 'lib/Zen';
import AlertCaseType from 'models/CaseManagementApp/AlertCaseType';
import AlertNotification from 'models/AlertsApp/AlertNotification';
import ExternalAlert from 'models/CaseManagementApp/ExternalAlert';
import I18N from 'lib/I18N';
import Moment from 'models/core/wip/DateTime/Moment';
import type CaseStatusDescriptor from 'models/CaseManagementApp/CaseStatusDescriptor';
import type { Deserializable } from 'lib/Zen';

type RequiredValues = {
  /** The alert notification that this case represents */
  alertNotification: AlertNotification,

  /**
   * A string representing the source of the alert. E.g. was it Zenysis or
   * what external system did it come from?
   */
  alertSource: string,

  /** The status of the case */
  status: CaseStatusDescriptor,

  /** The type for alert cases */
  type: AlertCaseType,
};

type DerivedValues = {
  generationDate: Moment,

  /** The display name of this case */
  name: string,
};

type SerializedAlertCaseCoreInfo = {
  alertNotification: Zen.Serialized<AlertNotification>,
  caseTypeURI: string,
  spec: { alertSource: string },
  statusURI: string,
};

type AlertCoreInfoDeserializationConfig = {
  type: AlertCaseType,
};

const STANDARD_DATE_FORMAT = 'YYYY-MM-DD';
const SHORT_DATE_DISPLAY_FORMAT = 'MMM DD';

/**
 * This model represents the core info to provide a limited view of a CaseUnit.
 * This is the type of model we'd load when doing expensive queries (like
 * getAllCases, so that there is less we have to query for).
 */
class AlertCaseCoreInfo
  extends Zen.BaseModel<AlertCaseCoreInfo, RequiredValues, {}, DerivedValues>
  implements
    Deserializable<
      SerializedAlertCaseCoreInfo,
      AlertCoreInfoDeserializationConfig,
    >
{
  static derivedConfig: Zen.DerivedConfig<AlertCaseCoreInfo, DerivedValues> = {
    generationDate: [
      Zen.hasChanged('alertNotification'),
      coreInfo =>
        Moment.create(
          coreInfo.alertNotification().generationDate(),
          STANDARD_DATE_FORMAT,
        ),
    ],
    name: [
      Zen.hasChanged('alertNotification'),
      coreInfo => {
        const alert = coreInfo.alertNotification();
        const { dimensionValue, title } = alert.modelValues();
        return `${dimensionValue}: ${title}`;
      },
    ],
  };

  static deserialize(
    serializedAlertCaseCoreInfo: SerializedAlertCaseCoreInfo,
    alertCoreInfoDeserializationConfig: AlertCoreInfoDeserializationConfig,
  ): Zen.Model<AlertCaseCoreInfo> {
    const { alertNotification, spec, statusURI } = serializedAlertCaseCoreInfo;
    const { type } = alertCoreInfoDeserializationConfig;
    return AlertCaseCoreInfo.create({
      type,
      alertNotification: AlertNotification.deserialize(alertNotification),
      alertSource: spec.alertSource,
      status: type.statusDescriptors().forceGet(statusURI),
    });
  }

  /**
   * Sort a ZenArray of AlertCaseCoreInfo models by the startDate of their
   * alert notification's queryInterval. This does *not* sort by generation
   * date.
   */
  static sortAlertsByStartDate(
    alerts: Zen.Array<Zen.Model<AlertCaseCoreInfo>>,
  ): Zen.Array<Zen.Model<AlertCaseCoreInfo>> {
    return alerts.sort((coreInfo1, coreInfo2) => {
      const startDate1 = coreInfo1.alertNotification().queryStartDate();
      const startDate2 = coreInfo2.alertNotification().queryStartDate();
      return startDate1.diff(startDate2);
    });
  }

  isAlertFromToday(): boolean {
    return this._.generationDate().isSame(Moment.create(), 'day');
  }

  /**
   * Get a string explaining why this alert was triggered. The string is of
   * the form: `${title} on ${date}`
   * Or, if this is an alert that collects data from more than one day:
   *   `${title} for date period ${startDate} to ${endDate}`
   *
   * For example:
   *   'Todos os Sarampo > 0 on April 23'
   */
  getAlertExplanation(): string {
    const alert = this._.alertNotification();
    const dateStr = alert.getReadableQueryInterval(
      SHORT_DATE_DISPLAY_FORMAT,
      I18N.textById('to'),
    );

    if (alert.isSingleDatePeriod()) {
      return I18N.text('%(title)s on %(date)s', {
        date: dateStr,
        title: alert.title(),
      });
    }
    return I18N.text('%(title)s for date period %(date)s', {
      date: dateStr,
      title: alert.title(),
    });
  }

  // when merging an external alert with a AlertCaseCoreInfo, we need to take
  // the external alert's Source, and maybe take its status as well.
  // TODO(pablo): add better documentation here. or refactor. this is confusing.
  mergeExternalAlert(
    externalAlert: ExternalAlert,
    lastInternalUpdateDate: ?Moment, // the last time the user updated this case
  ): Zen.Model<AlertCaseCoreInfo> {
    const type = this._.type();
    const { activities, alertSource, status } = externalAlert.modelValues();

    // NOTE(abby): this is dependent on the case status descriptors having the
    // same label as the external alert statuses

    // should we merge in the external status? this is tricky.
    // If `alwaysUseZenysisAlertStatus` is true, then we only merge in the
    // external status if NO action has been taken internally yet.
    // Otherwise, we need to do some more complex logic: take the external
    // alert's status if the user has not changed the status of this case at
    // all, OR if we did, merge the status if the external alert has more
    // recent activity
    const currentStatus = this._.status();
    let shouldMergeStatus = false;
    if (type.alwaysUseZenysisAlertStatus() && !lastInternalUpdateDate) {
      shouldMergeStatus = true;
    } else if (currentStatus === type.defaultStatus()) {
      shouldMergeStatus = true;
    } else if (!activities.isEmpty() && lastInternalUpdateDate) {
      const lastExtActivity = activities.last();
      const lastExternalDate = Moment.create(lastExtActivity.timestamp);
      if (lastExternalDate.isAfter(lastInternalUpdateDate)) {
        shouldMergeStatus = true;
      }
    }

    return this.modelValues({
      alertSource,
      status: shouldMergeStatus ? status : currentStatus,
    });
  }
}

export default ((AlertCaseCoreInfo: $Cast): Class<
  Zen.Model<AlertCaseCoreInfo>,
>);
