// @flow
import invariant from 'invariant';
import type Promise from 'bluebird';

import APIService, { API_VERSION } from 'services/APIService';
import CachedMapService from 'services/wip/CachedMapService';
// No way to avoid this circular dependency unfortunately.
// eslint-disable-next-line import/no-cycle
import LinkedCategory from 'models/core/wip/LinkedCategory';
import { convertIDToURI, convertURIToID } from 'services/wip/util';
import type { APIVersion, HTTPService } from 'services/APIService';
import type { Cache, RejectFn, ResolveFn } from 'services/wip/CachedMapService';
import type { URI, URIConverter } from 'services/types/api';

// Deserialize a raw LinkedCategory object. The parent category is created by
// recursively building all a category's parents.
function _buildCategoryTreeHelper(
  rawCategoryMapping,
  categoryId,
  collectedCategories = {},
) {
  // If this category has no ID, we're done.
  if (!categoryId) {
    return undefined;
  }

  // If we have already created this category earlier, return it now.
  if (collectedCategories[categoryId]) {
    return collectedCategories[categoryId];
  }

  const curRawCategory = rawCategoryMapping[categoryId];
  const parentCategoryId =
    (curRawCategory.parentId && curRawCategory.parentId) || undefined;

  if (parentCategoryId === categoryId) {
    return collectedCategories[categoryId];
  }
  // Create the linked parent category.
  const parentCategory = _buildCategoryTreeHelper(
    rawCategoryMapping,
    parentCategoryId,
    collectedCategories,
  );

  // Deserialize the raw category now that we have the full parent object.
  const category = LinkedCategory.create({
    ...curRawCategory,
    parent: parentCategory,
  });

  // Memoize our work as we go.
  // eslint-disable-next-line no-param-reassign
  collectedCategories[categoryId] = category;
  return category;
}

// Build a mapping from category ID to a full LinkedCategory model.
function buildCategoryTree(rawCategoryMapping) {
  const output = {};
  Object.keys(rawCategoryMapping).forEach(categoryId => {
    if (!output[categoryId]) {
      output[categoryId] = _buildCategoryTreeHelper(
        rawCategoryMapping,
        categoryId,
        output,
      );
    }
  });
  return output;
}

/**
 * The CategoryService is used to fetch the different Categories that
 * exist from the server. Right now the service only works with the
 * LinkedCategory model.
 */
class CategoryService
  extends CachedMapService<LinkedCategory>
  implements URIConverter
{
  apiVersion: APIVersion = API_VERSION.V2;
  _httpService: HTTPService;
  _parentIdIndex: { [string]: $ReadOnlyArray<LinkedCategory> } | void;
  _subscribers: Array<() => void>;
  endpoint: string = 'query/dimension_categories';

  constructor(httpService: HTTPService) {
    super();
    this._httpService = httpService;
    this._parentIdIndex = undefined;
    this._subscribers = [];
  }

  buildParentIdCache() {
    // TODO: deduplicate this implementation with `FieldService` through
    // a common ancestor for hierarchical models
    invariant(this._mappingCache, 'Called without main cache populated');
    const parentIdIndex = {};
    Object.keys(this._mappingCache).forEach(id => {
      invariant(this._mappingCache, 'Called without main cache populated');
      const category = this._mappingCache[id];
      invariant(category, 'Can not be here');
      const parentId = category.parentId();
      if (parentId !== undefined) {
        if (!parentIdIndex[parentId]) {
          parentIdIndex[parentId] = [];
        }
        parentIdIndex[parentId].push(category);
      }
    });
    this._parentIdIndex = parentIdIndex;
  }

  buildCache(
    resolve: ResolveFn<LinkedCategory>,
    reject: RejectFn,
  ): Promise<Cache<LinkedCategory>> {
    return this._httpService
      .get(this.apiVersion, this.endpoint)
      .then(rawCategoryList => {
        // Build mapping from category ID to serialized category object.
        const rawCategoryMapping = {};
        rawCategoryList.forEach(rawCategory => {
          rawCategoryMapping[rawCategory.id] = rawCategory;
        });
        // Build full mapping from category ID to deserialized LinkedCategory
        // model.
        resolve(buildCategoryTree(rawCategoryMapping));
      })
      .catch(reject);
  }

  getChildrenById(parentId: string): $ReadOnlyArray<LinkedCategory> {
    if (!this._mappingCache) {
      throw this.fetchMapping();
    }
    if (!this._parentIdIndex) {
      this.buildParentIdCache();
    }
    invariant(this._parentIdIndex, 'Can not be here');
    return this._parentIdIndex[parentId] || [];
  }

  convertURIToID(uri: URI): string {
    return convertURIToID(uri, this.apiVersion, this.endpoint);
  }

  convertIDToURI(id: string): URI {
    return convertIDToURI(id, this.apiVersion, this.endpoint);
  }

  subscribe(listener: () => void): void {
    this._subscribers.push(listener);
  }

  notifyAll(): void {
    this._subscribers.forEach(subscriber => subscriber());
  }

  updateInternal(categories: $ReadOnlyArray<LinkedCategory>): void {
    const { _mappingCache } = this;
    invariant(_mappingCache, 'Cache must be populated first');
    categories.forEach(category => {
      const parentId = category.parentId();
      _mappingCache[category.id()] =
        _mappingCache[category.id()] || parentId === undefined
          ? category
          : category.parent(_mappingCache[parentId]);
    });
    this._setMappingAndResolve(() => undefined, _mappingCache);
    this.buildParentIdCache();
    this.notifyAll();
  }

  update(category: LinkedCategory): Promise<void> {
    return this._httpService
      .patch(
        API_VERSION.V2,
        `${this.endpoint}/${category.id()}`,
        category.serializeForUpdate(),
      )
      .then(() => {
        if (this._mappingCache && this._parentIdIndex) {
          this.updateInternal([category]);
        }
      });
  }

  create(category: LinkedCategory): Promise<void> {
    return this._httpService
      .post(API_VERSION.V2, `${this.endpoint}/${category.id()}`, {
        ...category.serializeForUpdate(),
      })
      .then(() => {
        if (this._mappingCache && this._parentIdIndex) {
          this.updateInternal([category]);
        }
      });
  }

  _deleteFromCache(category: LinkedCategory): void {
    const { _mappingCache } = this;
    invariant(_mappingCache, 'Cache must be populated first');
    this.getChildrenById(category.id()).forEach(this._deleteFromCache);
    delete _mappingCache[category.id()];
    this._setMappingAndResolve(() => undefined, _mappingCache);
  }

  delete(category: LinkedCategory): Promise<void> {
    return this._httpService
      .delete(API_VERSION.V2, `${this.endpoint}/${category.id()}`)
      .then(() => {
        if (this._mappingCache) {
          this._deleteFromCache(category);
          this.buildParentIdCache();
          this.notifyAll();
        }
      });
  }
}

const CategoryServiceImpl: CategoryService = new CategoryService(APIService);
export default CategoryServiceImpl;

export const CategoryServiceClass = CategoryService;
