// @flow
import * as React from 'react';
import classNames from 'classnames';
import invariant from 'invariant';
import type Promise from 'bluebird';

import DirectoryService from 'services/DirectoryService';
import Dropdown from 'components/ui/Dropdown';
import { API_VERSION } from 'services/APIService';
import { convertIDToURI } from 'services/wip/util';
import type { Locale } from 'components/Navbar/MoreLinks';

export type DropdownOptionEventHandler = (
  e: SyntheticEvent<HTMLElement>,
) => void;

export const MOBILE_REGEX: RegExp =
  /android|fennec|iemobile|iphone|opera (?:mini|mobi)/i;

export const MOBILE_OPTIMIZED_PATHS_REGEX: RegExp =
  /\/(?:query|dashboard|user|unauthorized|login|zen|overview)(?:\/|$)/i;

// Set up translations for this module
const LOCALE_LABELS = {
  am: 'አማርኛ ተጠቀም',
  br: 'Usar Português (BR)',
  en: 'Use English (US)',
  fr: 'Utiliser Français',
  pt: 'Usar Português',
  vn: 'Sử dụng Tiếng Việt',
};
const LOCALE_SHORT_LABELS = {
  am: 'አማርኛ',
  br: 'Português (BR)',
  en: 'English (US)',
  fr: 'Français',
  pt: 'Português',
  vn: 'Tiếng Việt',
};

/**
 * @param {string} deploymentName The name of the whose acronym we want to find
 * @returns {string} The acronym of the deployment. Returns the same word if
 * the deployment name has a single word e.g. NACOSA and an acronym e.g EHDAP
 * for Ethiopia
 */
export function extractAcronym(deploymentName: string): string {
  const wordsInName = deploymentName.trim().split(/\s+/);
  const hasSingleWord = wordsInName.length === 1;

  if (hasSingleWord) {
    return deploymentName;
  }

  // combine first letters of each word
  const acronym = wordsInName
    .map(word => word.charAt(0))
    .join('')
    .toUpperCase();

  return acronym;
}

// NOTE(stephen): This feels kinda hacky. Really wanted to use a normal
// component for this, but Dropdown has a very very strict requirement on its
// children being Option's.
export function asDropdownOption(
  onClick: DropdownOptionEventHandler,
  text: string,
  iconClassName: string = '',
  children: React.Node = null,
): React.Element<Class<Dropdown.Option<DropdownOptionEventHandler>>> {
  return (
    <Dropdown.Option key={text || iconClassName} value={onClick}>
      {iconClassName && (
        <span className="navbar-dropdown-menu__icon">
          <i className={iconClassName} />
        </span>
      )}
      {text}
      {children}
    </Dropdown.Option>
  );
}

export function asButton(
  onClick: EventHandler,
  text: string,
  isActive: boolean = false,
  children: React.Node = null,
  testId: string = '',
  buttonClassName: string = '',
  icon: React.Node | void = undefined, // TODO(nina): Correctly type this
): React.Node {
  const className = classNames('navbar-item', buttonClassName, {
    'navbar-item--active': isActive,
  });

  return (
    <button
      key={text}
      className={className}
      data-testid={testId}
      onClick={onClick}
      type="button"
    >
      {icon || null}
      {text}
      {children}
    </button>
  );
}

export function isMobileBrowser(): boolean {
  const userAgent =
    window.navigator.userAgent || window.navigator.vendor || window.opera;
  return MOBILE_REGEX.test(userAgent);
}

export function isUnoptimizedForMobile(url: string): boolean {
  return !MOBILE_OPTIMIZED_PATHS_REGEX.test(url);
}

export function isUserInGroup(groupName: string): Promise<boolean> {
  const currentUserUri = convertIDToURI(
    window.__JSON_FROM_BACKEND.user.id,
    API_VERSION.V2,
    'user',
  );
  return DirectoryService.getIsUserInGroup(currentUserUri, groupName);
}

export function addLocaleLabel(
  locales: $ReadOnlyArray<Locale>,
): $ReadOnlyArray<Locale> {
  const labeledLocales = [];
  // Add language selection label to each locale object
  if (locales) {
    locales.forEach(locale => {
      const localeCopy = locale;
      localeCopy.label = LOCALE_LABELS[locale.id];
      localeCopy.shortLabel = LOCALE_SHORT_LABELS[locale.id];
      labeledLocales.push(localeCopy);
    });
  }
  return labeledLocales;
}

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = React.useState<boolean>(
    window.matchMedia(query),
  );

  React.useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    listener();
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
}

export function useMaxWidthBreakpoint(cssVar: string): boolean {
  invariant(document.documentElement, 'Can not find root element');
  const breakpoint = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--${cssVar}`);
  return useMediaQuery(`(max-width: ${breakpoint})`);
}

export function IsMobileWrapper({
  children,
}: {
  children: boolean => React.Node,
}): React.Node {
  const matches = useMaxWidthBreakpoint('max-mobile-width-landscape');
  return children(matches);
}
