// @flow
import * as React from 'react';

import CohortCreationContext from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortCreationContext';
import CustomizableFilterTag from 'components/common/QueryBuilder/CustomizableFilterTag';
import type { QueryFilterItem } from 'models/core/wip/QueryFilterItem/types';

type Props = {
  filter: QueryFilterItem,
  onFilterItemChange: QueryFilterItem => void,
  onFilterItemRemove: () => void,
};

export default function DimensionValueFilterOption({
  filter,
  onFilterItemChange,
  onFilterItemRemove,
}: Props): React.Node {
  // This should never happen.
  if (filter.tag !== 'DIMENSION_VALUE_FILTER_ITEM') {
    throw new Error('Should only receive DimensionValueFilterItem');
  }

  const [showCustomizationModule, setShowCustomizationModule] =
    React.useState<boolean>(filter.get('dimensionValues').isEmpty());
  const { dimensionValueMap } = React.useContext(CohortCreationContext);

  return (
    <div className="filter-option-item">
      <CustomizableFilterTag
        className="filter-option-item__tag"
        dimensionValueMap={dimensionValueMap}
        item={filter}
        onApplyClick={onFilterItemChange}
        onRemoveTagClick={onFilterItemRemove}
        onRequestCloseCustomizationModule={() =>
          setShowCustomizationModule(false)
        }
        onTagClick={() => setShowCustomizationModule(true)}
        showCustomizationModule={showCustomizationModule}
        showDragHandle={false}
      />
    </div>
  );
}
