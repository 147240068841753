// @flow
import { Record } from 'immutable';
import type { RecordFactory } from 'immutable';

export type SerializedDataprepJob = {
  id: number,
  jobId: number,
  status: string,
};

type DataprepJobProps = {
  id: number,
  jobId: number,
  status: string,
};

const DataprepJobRecord: RecordFactory<DataprepJobProps> = Record({
  id: undefined,
  jobId: undefined,
  status: undefined,
});

export default class DataprepJob extends DataprepJobRecord {
  static deserialize(data: SerializedDataprepJob): DataprepJob {
    const { id, jobId, status } = data;
    return new DataprepJob({ id, jobId, status });
  }

  id(): number {
    return this.get('id');
  }

  jobId(): number {
    return this.get('jobId');
  }

  status(): string {
    return this.get('status');
  }
}
