// @flow
import * as React from 'react';
import { List } from 'immutable';

import DataprepFlow from 'models/DataUploadApp/DataprepFlow';
import FileSummary from 'models/DataUploadApp/FileSummary';
import I18N from 'lib/I18N';
import PipelineDatasource from 'models/DataUploadApp/PipelineDatasource';
import SelfServeSource from 'models/DataUploadApp/SelfServeSource';
import SelfServeSourceService from 'services/DataUploadApp/SelfServeSourceService';
import Toaster from 'components/ui/Toaster';
import { CSV_TYPE, DATAPREP_TYPE } from 'models/DataUploadApp/types';
import type { DataUploadModalState } from 'components/DataUploadApp/AddDataModal/useDataUploadModalContext';

// Creates or updates the self serve source object and all related objects.

export default function useManageSelfServeSource(
  initialSelfServeSource: SelfServeSource | void,
  dataUploadState: DataUploadModalState,
  reloadSelfServeSources: () => void,
): () => void {
  return React.useCallback(() => {
    const {
      allowMultipleFiles,
      dataprepExpectedColumns,
      fileSummaries,
      recipeId,
      sourceId,
      sourceName,
      sourceType,
    } = dataUploadState;
    const insertNewSource = !initialSelfServeSource;
    const dataprepFlow =
      sourceType === DATAPREP_TYPE && recipeId !== undefined
        ? DataprepFlow.deserialize({
            recipeId,
            appendable: allowMultipleFiles ?? false,
            expectedColumns: dataprepExpectedColumns ?? [],
          })
        : undefined;

    const selfServeSource = new SelfServeSource({
      dataprepFlow,
      sourceId,
      fileSummaries: List(
        fileSummaries.values().map(fileSummary => {
          return new FileSummary({
            columnMapping: fileSummary.columnMapping,
            filePath: fileSummary.filePath,
            id: fileSummary.fileSummaryId,
            userFileName: fileSummary.userFileName,
          });
        }),
      ),
      id: initialSelfServeSource?.id(),
      pipelineDatasource: new PipelineDatasource({
        id: sourceId,
        name: sourceName,
      }),
    });

    const onError = error => {
      Toaster.error(
        insertNewSource
          ? I18N.text('Error creating new source')
          : I18N.text('Error updating source'),
      );
      window.Rollbar.error(error);
    };

    const onCompletedCSV = () => {
      const successText = insertNewSource
        ? I18N.text('Succesfully created new source')
        : I18N.text('Succesfully updated source');
      Toaster.success(successText);
      const analyticsMsg = insertNewSource
        ? 'Added new self serve source'
        : 'Updated self serve source';
      analytics.track(analyticsMsg, { source: sourceId });
    };

    const onCompletedDataprep = () => {
      const successText = insertNewSource
        ? I18N.text('Succesfully created new dataprep source')
        : I18N.text(
            'File(s) uploaded successfully. Dataprep job has been kicked off and output will be integrated',
          );
      Toaster.success(successText);
      const analyticsMsg = insertNewSource
        ? 'Added new data upload dataprep source'
        : 'Updated data upload dataprep source';
      analytics.track(analyticsMsg, {
        source: sourceId,
      });
    };

    const onSuccess =
      sourceType === CSV_TYPE ? onCompletedCSV : onCompletedDataprep;

    // TODO: handle auto updates with the updates list
    // Using patch directly here because I do not have a good way to
    // render auto updates
    // When handling auto-updates, needs to return both the new source for create
    // as well as the new dataprep job for dataprep creation and updates
    const action = initialSelfServeSource
      ? SelfServeSourceService.patch(
          initialSelfServeSource.uri(),
          selfServeSource.serialize(),
        )
      : SelfServeSourceService.create(selfServeSource);
    action.then(onSuccess).catch(onError);
    reloadSelfServeSources();
  }, [dataUploadState, initialSelfServeSource, reloadSelfServeSources]);
}
