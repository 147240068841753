// @flow
import * as Zen from 'lib/Zen';
import type { Serializable } from 'lib/Zen';

export type CaseMetadataType = 'STRING' | 'PHONE_NUMBER' | 'NUMBER' | 'DATE';

type Values = {
  /** (optional) The section label this metadata row should be grouped under */
  dossierSection: string | void,

  /** If a value is empty, what should we display instead? */
  emptyDisplayValue: string,

  /**
   * If the value is empty, should we still include the row?
   * NOTE: empty string will be counted as empty
   */
  isDisplayedEmpty: boolean,

  /** Is a user allowed to edit this? */
  isEditable: boolean,

  /** The human readable label for this piece of metadata */
  label: string,

  /** The data type this metadata represents */
  type: CaseMetadataType,

  /** The URI of this case metadata descriptor */
  uri: string,
};

type SerializedCaseMetadataDescriptor = {
  $uri: string,
  dossierSection: string | null,
  emptyDisplayValue: string,
  isDisplayedEmpty: boolean,
  isEditable: boolean,
  label: string,
  type: CaseMetadataType,
};

/**
 * CaseMetadataDescriptor is a representation of metadata a case can contain.
 */
class CaseMetadataDescriptor
  extends Zen.BaseModel<CaseMetadataDescriptor, Values>
  implements Serializable<SerializedCaseMetadataDescriptor>
{
  static deserialize(
    serializedCaseMetadataDescriptor: SerializedCaseMetadataDescriptor,
  ): Zen.Model<CaseMetadataDescriptor> {
    const {
      $uri,
      dossierSection,
      emptyDisplayValue,
      isDisplayedEmpty,
      isEditable,
      label,
      type,
    } = serializedCaseMetadataDescriptor;
    return CaseMetadataDescriptor.create({
      emptyDisplayValue,
      isDisplayedEmpty,
      isEditable,
      label,
      type,
      dossierSection: dossierSection || undefined,
      uri: $uri,
    });
  }

  serialize(): SerializedCaseMetadataDescriptor {
    const { dossierSection, uri, ...caseMetadataDescriptor } =
      this.modelValues();
    return {
      $uri: uri,
      dossierSection: dossierSection || null,
      ...caseMetadataDescriptor,
    };
  }
}

export default ((CaseMetadataDescriptor: $Cast): Class<
  Zen.Model<CaseMetadataDescriptor>,
>);
