// @flow
import * as Zen from 'lib/Zen';
import NumericValueCohortFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilter';
import type { NumericValueCohortFilterWrapper } from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';
import type { Serializable } from 'lib/Zen';

type Values = {
  value: number,
};

type SerializedAtMostFilter = {
  type: 'AT_MOST',
  value: number,
};

class AtMostFilter
  extends Zen.BaseModel<AtMostFilter, Values>
  implements
    Serializable<SerializedAtMostFilter>,
    NumericValueCohortFilterWrapper
{
  static tag: 'AT_MOST' = 'AT_MOST';
  tag: 'AT_MOST' = 'AT_MOST';

  static deserialize(
    serializedAtMostFilter: SerializedAtMostFilter,
  ): Zen.Model<AtMostFilter> {
    const { value } = serializedAtMostFilter;

    return AtMostFilter.create({ value });
  }

  filter(): NumericValueCohortFilter {
    return NumericValueCohortFilter.create({
      upperBound: this._.value(),
      upperBoundStrict: false,
    });
  }

  serialize(): SerializedAtMostFilter {
    return { type: this.tag, value: this._.value() };
  }
}

export default ((AtMostFilter: $Cast): Class<Zen.Model<AtMostFilter>>);
