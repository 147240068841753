// @flow
import { List } from 'immutable';

import APIService, { API_VERSION } from 'services/APIService';
import PotionService from 'services/PotionService';
import SelfServeSource from 'models/DataUploadApp/SelfServeSource';
import createDataProvider from 'services/PotionService/hooks';
import type { HTTPService } from 'services/APIService';
import type { PotionItemStateProviderType } from 'services/PotionService/hooks';
import type {
  SerializedInputDataprepFlow,
  SerializedOutputDataprepFlow,
} from 'models/DataUploadApp/DataprepFlow';
import type {
  SerializedInputFileSummary,
  SerializedOutputFileSummary,
} from 'models/DataUploadApp/FileSummary';
import type {
  SerializedInputPipelineDatasource,
  SerializedOutputPipelineDatasource,
} from 'models/DataUploadApp/PipelineDatasource';
import type { SourceDateRange } from 'models/DataUploadApp/types';

export type SerializedInputSelfServeSource = {
  $uri: string,
  dataprepFlow: SerializedInputDataprepFlow | null,
  errorState: boolean,
  filePreview?: List<Object>,
  fileSummaries: List<SerializedInputFileSummary>,
  id: number,
  lastModified: string,
  pipelineDatasource: SerializedInputPipelineDatasource,
  sourceId: string,
  sourceRange: SourceDateRange,
  unpublishedDimensionsCount: number,
};

export type SerializedOutputSelfServeSource = {
  dataprepFlow: SerializedOutputDataprepFlow | null,
  dataUploadFileSummaries: $ReadOnlyArray<SerializedOutputFileSummary>,
  id?: number,
  pipelineDatasource: SerializedOutputPipelineDatasource,
  sourceId?: string,
  sourceRange?: Object,
  unpublishedDimensions?: $ReadOnlyArray<any>,
  unpublishedDimensionsCount?: number,
  unpublishedFields?: $ReadOnlyArray<any>,
};

class SelfServeSourceService extends PotionService<
  SelfServeSource,
  SerializedInputSelfServeSource,
  SerializedOutputSelfServeSource,
> {
  constructor(httpService: HTTPService, path: string) {
    const deserialize = (
      values: SerializedInputSelfServeSource,
    ): SelfServeSource => {
      return SelfServeSource.deserialize(values);
    };
    const serialize = (
      item: SelfServeSource,
    ): SerializedOutputSelfServeSource => {
      return item.serialize();
    };

    super(httpService, path, serialize, deserialize);
  }

  getSourceIds(): Promise<Set<string>> {
    return this._httpService
      .get(API_VERSION.V2, `${this._path}/source_ids`)
      .then((values: Array<string>) => {
        return new Set(values);
      });
  }
}

const service = (new SelfServeSourceService(
  APIService,
  '/self_serve_source',
): SelfServeSourceService);
export default service;

const {
  PotionItemStateProvider: SelfServeSourcesProvider,
  usePotionItemStateContext: useSelfServeSourceStateContext,
}: PotionItemStateProviderType<
  SelfServeSource,
  SerializedInputSelfServeSource,
  SelfServeSourceService,
> = createDataProvider(service, { sort: { $uri: false } });
export { useSelfServeSourceStateContext, SelfServeSourcesProvider };
