// @flow
import * as Zen from 'lib/Zen';
// No way to avoid this circular dependency unfortunately.
// eslint-disable-next-line import/no-cycle
import CategoryService from 'services/wip/CategoryService';
import { uniqueId } from 'util/util';
import type { Customizable } from 'types/interfaces/Customizable';
import type { JSONRef } from 'services/types/api';
import type { VisibilityStatus } from 'models/core/DataCatalog/constants';

type RequiredValues = {
  id: string,
  name: string,
};

type DefaultValues = {
  +description: string,
  +isVisible: boolean,
  +ordering: number | void,

  // eslint-disable-next-line no-use-before-define
  +parent: Zen.Model<LinkedCategory> | void,
  +parentId: string | void,
};

type DerivedValues = {
  visibilityStatus: VisibilityStatus,
};

type SerializedLinkedCategoryForUpdate = {
  isVisible: boolean,
  name: string,
  parentId: string | void,
};

type SerializedLinkedCategory = JSONRef;

/**
 * The LinkedCategory model represents a simple mapping from an ID to a display
 * name. It is a simple key/value mapping (with an optional description).
 *
 * It also has an optional parent node that can be used to represent
 * defined hierarchical relationships between categories. When using a
 * LinkedCategory, you should store a reference to the *most granular* category
 * that represents your data. By storing the child node of the LinkedCategory
 * tree, you can traverse upwards using the `parent` property to find the levels
 * this category applies to.
 */
class LinkedCategory
  extends Zen.BaseModel<
    LinkedCategory,
    RequiredValues,
    DefaultValues,
    DerivedValues,
  >
  implements Customizable<LinkedCategory>
{
  tag: 'LINKED_CATEGORY' = 'LINKED_CATEGORY';
  static defaultValues: DefaultValues = {
    description: '',
    isVisible: true,
    ordering: undefined,
    parent: undefined,
    parentId: undefined,
  };

  static derivedConfig: Zen.DerivedConfig<LinkedCategory, DerivedValues> = {
    visibilityStatus: [
      Zen.hasChanged<LinkedCategory>('isVisible'),
      category => (category.isVisible() ? 'VISIBLE' : 'HIDDEN'),
    ],
  };

  static deserializeAsync(
    values: SerializedLinkedCategory,
  ): Promise<Zen.Model<LinkedCategory>> {
    return CategoryService.forceGet(
      CategoryService.convertURIToID(values.$ref),
    );
  }

  static UNSAFE_deserialize(
    values: SerializedLinkedCategory,
  ): Zen.Model<LinkedCategory> {
    return CategoryService.UNSAFE_forceGet(
      CategoryService.convertURIToID(values.$ref),
    );
  }

  customize(): Zen.Model<LinkedCategory> {
    const { description, id, isVisible, name, ordering, parent, parentId } =
      this.modelValues();
    return LinkedCategory.create({
      description,
      isVisible,
      name,
      ordering,
      parent,
      parentId,
      id: `${id}__${uniqueId()}`,
    });
  }

  serialize(): SerializedLinkedCategory {
    return {
      $ref: CategoryService.convertIDToURI(this._.id()),
    };
  }

  serializeForUpdate(): SerializedLinkedCategoryForUpdate {
    return {
      isVisible: this._.isVisible(),
      name: this._.name(),
      parentId: this._.parentId(),
    };
  }
}

export default ((LinkedCategory: $Cast): Class<Zen.Model<LinkedCategory>>);
