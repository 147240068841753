// @flow
import invariant from 'invariant';

import * as Zen from 'lib/Zen';
import type AlertCaseType from 'models/CaseManagementApp/AlertCaseType';
import type CaseStatusDescriptor from 'models/CaseManagementApp/CaseStatusDescriptor';
import type { Serializable } from 'lib/Zen';

/**
 * An external alert system might log actions that users have taken on it.
 * We need to log those actions as events in our Alert Case.
 */
export type ExternalAlertActivity = {
  action: string,
  comments: string,
  timestamp: string,
  user: string,
};

type Values = {
  /**
   * An array of alert activities outlining the actions external parties may
   * have taken on this alert
   */
  activities: Zen.Array<ExternalAlertActivity>,

  /**
   * Alerts can be triggered for a date period (e.g. if it is an alert that
   * aggregates data for a whole week). This is the end date of that period
   */
  alertEndDate: string,

  /** The source of this alert (e.g. 'EWARS') */
  alertSource: string,

  /** See notes on `alertEndDate`. This is the start date of the alert period */
  alertStartDate: string,

  /** The dimension that this alert is for. E.g. FacilityName */
  dimensionName: string,

  /** The specific dimension value this alert is for. E.g. 'CS Munhava' */
  dimensionValue: string,

  /** The indicator this alert is based off of */
  fieldId: string,

  /**
   * The date the alert was triggered. This is NOT the same as the alert period.
   * E.g. an alert may have been triggered today, but it might be alerting us on
   * data from yesterday.
   */
  generationDate: string,

  /**
   * The alert case's status.
   */
  status: CaseStatusDescriptor,
};

/**
 * This is the serialized JSON representation of an external alert as it is
 * stored in Druid (in the `externalAlertDataDimension` dimension that we
 * specify as part of the alert config).
 */
type SerializedExternalAlert = {
  activities: $ReadOnlyArray<ExternalAlertActivity>,
  alertEndDate: string,
  alertSource: string,
  alertStartDate: string,
  fieldId: string,
  generationDate: string,
  stage: string,
};

type ExternalAlertDeserializationConfig = {
  alertCaseType: AlertCaseType,
  dimensionName: string,
  dimensionValue: string,
};

/**
 * We support merging our existing alerts with external alerts. E.g. in
 * MZ we merge in an EWARS alert with our Zenysis-generated alerts. There are
 * some conditions that this external alert must meet:
 * 1. They must be queryable through druid (so write an integration to get
 *   those external alerts through the pipeline)
 * 2. All their relevant information must be found in a single JSON
 *   (just dump all that JSON into a dimension)
 * 3. They must contain information in the same format as our `ExternalAlert`
 *   type. (adjust your integration accordingly to return this same data)
 */
class ExternalAlert
  extends Zen.BaseModel<ExternalAlert, Values>
  implements
    Serializable<SerializedExternalAlert, ExternalAlertDeserializationConfig>
{
  static deserialize(
    serializedExternalAlert: SerializedExternalAlert,
    externalAlertDeserializationConfig: ExternalAlertDeserializationConfig,
  ): Zen.Model<ExternalAlert> {
    const {
      activities,
      alertEndDate,
      alertSource,
      alertStartDate,
      fieldId,
      generationDate,
      stage,
    } = serializedExternalAlert;
    const { alertCaseType, dimensionName, dimensionValue } =
      externalAlertDeserializationConfig;
    const statusType = alertCaseType
      .statusDescriptors()
      .values()
      .find(status => status.label() === stage);
    invariant(
      statusType !== undefined,
      `External Alert stage '${stage}' is invalid`,
    );

    return ExternalAlert.create({
      alertEndDate,
      alertSource,
      alertStartDate,
      dimensionName,
      dimensionValue,
      fieldId,
      generationDate,
      activities: Zen.Array.create(activities),
      status: statusType,
    });
  }

  serialize(): SerializedExternalAlert {
    const {
      activities,
      alertEndDate,
      alertSource,
      alertStartDate,
      fieldId,
      generationDate,
      status,
    } = this.modelValues();
    return {
      alertEndDate,
      alertSource,
      alertStartDate,
      fieldId,
      generationDate,
      activities: activities.arrayView(),
      stage: status.label(),
    };
  }
}

export default ((ExternalAlert: $Cast): Class<Zen.Model<ExternalAlert>>);
