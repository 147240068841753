// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function ChevronRight(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.226 11.228 7.317 0.319c-0.426 -0.426 -1.117 -0.426 -1.543 0 -0.426 0.426 -0.426 1.117 0 1.543l10.138 10.137 -10.138 10.138c-0.426 0.426 -0.426 1.117 0 1.543A1.087 1.087 0 0 0 6.545 24a1.087 1.087 0 0 0 0.771 -0.32L18.226 12.771a1.091 1.091 0 0 0 0 -1.543"
        fill="currentColor"
      />
    </svg>
  );
}
