// @flow
import * as React from 'react';

import BaseModal from 'components/ui/BaseModal';
import I18N from 'lib/I18N';
import Intents from 'components/ui/Intents';
import MappingPage from 'components/DataUploadApp/AddDataModal/MappingPage';
import PreviewPage from 'components/DataUploadApp/AddDataModal/PreviewPage';
import ProgressBar from 'components/ui/ProgressBar';
import ProgressModal from 'components/ui/ProgressModal';
import SelfServeSource from 'models/DataUploadApp/SelfServeSource';
import SelfServeSourceService from 'services/DataUploadApp/SelfServeSourceService';
import UploadPage from 'components/DataUploadApp/AddDataModal/UploadPage';
import useBoolean from 'lib/hooks/useBoolean';
import useDataUploadModalContext, {
  DataUploadModalContext,
  DataUploadModalDispatch,
} from 'components/DataUploadApp/AddDataModal/useDataUploadModalContext';
import useManageSelfServeSource from 'components/DataUploadApp/AddDataModal/useManageSelfServeSource';
import { DATAPREP_TYPE } from 'models/DataUploadApp/types';
import type Field from 'models/core/wip/Field';
import type HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import type LinkedCategory from 'models/core/wip/LinkedCategory';
import type { DataUploadModalState } from 'components/DataUploadApp/AddDataModal/useDataUploadModalContext';
import type { GroupingItem } from 'models/core/wip/GroupingItem/types';

type Props = {
  dimensionHierarchyRoot: HierarchyItem<LinkedCategory | GroupingItem>,
  existingDataUploadSources: $ReadOnlySet<string>,
  fieldHierarchyRoot: HierarchyItem<LinkedCategory | Field>,
  initialSelfServeSourceId?: number | void,
  onCloseModal: (DataUploadModalState | void) => void,
  reloadSelfServeSources: () => void,
};

export default function AddDataModal({
  dimensionHierarchyRoot,
  existingDataUploadSources,
  fieldHierarchyRoot,
  initialSelfServeSourceId,
  onCloseModal,
  reloadSelfServeSources,
}: Props): React.Node {
  const [dataUploadState, dataUploadDispatch] = useDataUploadModalContext();
  const [loading, startLoading, endLoading] = useBoolean(true);
  const [initialSelfServeSource, setInitialSelfServeSource] =
    React.useState<SelfServeSource | void>(undefined);

  React.useEffect(() => {
    if (initialSelfServeSourceId) {
      startLoading();
      SelfServeSourceService.getById(initialSelfServeSourceId).then(source => {
        setInitialSelfServeSource(source);
        dataUploadDispatch({
          initialSelfServeSource: source,
          type: 'INITIALIZE',
        });
        endLoading();
      });
    } else {
      endLoading();
    }
  }, [dataUploadDispatch, endLoading, initialSelfServeSourceId, startLoading]);

  const [disableUploadButton, setDisableUploadButton] =
    React.useState<boolean>(true);
  const [disableCompleteButton, setDisableCompleteButton] =
    React.useState<boolean>(true);

  const handleSelfServeSource = useManageSelfServeSource(
    initialSelfServeSource,
    dataUploadState,
    reloadSelfServeSources,
  );

  const onSubmit = () => {
    if (!dataUploadState.stateChanged) {
      return;
    }

    handleSelfServeSource();
  };

  const existingSource = initialSelfServeSourceId !== undefined;
  const title = existingSource
    ? I18N.text('Update data')
    : I18N.text('Add data');

  const [
    confirmationModalVisible,
    showConfirmationModal,
    hideConfirmationModal,
  ] = useBoolean(false);
  const onRequestClose = (e: SyntheticEvent<>, modalSubmitted: boolean) => {
    if (modalSubmitted) {
      onCloseModal();
    } else if (dataUploadState.stateChanged) {
      showConfirmationModal();
    } else {
      onCloseModal();
    }
  };

  const pages =
    dataUploadState.sourceType === DATAPREP_TYPE
      ? [
          <ProgressModal.Page
            key="Upload"
            disableMainButton={disableUploadButton}
            mainButtonText={I18N.textById('Complete setup')}
            name={I18N.textById('Upload')}
            onMainButtonClick={onSubmit}
          >
            <UploadPage
              existingDataUploadSources={existingDataUploadSources}
              existingSource={existingSource}
              setDisableUploadButton={setDisableUploadButton}
            />
          </ProgressModal.Page>,
        ]
      : [
          <ProgressModal.Page
            key="Upload"
            disableMainButton={disableUploadButton}
            mainButtonText={I18N.text('Mapping')}
            name={I18N.text('Upload')}
          >
            <UploadPage
              existingDataUploadSources={existingDataUploadSources}
              existingSource={existingSource}
              setDisableUploadButton={setDisableUploadButton}
            />
          </ProgressModal.Page>,
          <ProgressModal.Page
            key="Mapping"
            className="data-upload-modal__mapping-page"
            mainButtonText={I18N.text('Review')}
            name={I18N.textById('Mapping')}
          >
            <MappingPage
              dimensionHierarchyRoot={dimensionHierarchyRoot}
              fieldHierarchyRoot={fieldHierarchyRoot}
              setDisableCompleteButton={setDisableCompleteButton}
            />
          </ProgressModal.Page>,
          <ProgressModal.Page
            key="Review"
            disableMainButton={disableCompleteButton}
            mainButtonText={I18N.text('Complete setup')}
            name={I18N.textById('Review')}
            onMainButtonClick={onSubmit}
          >
            <PreviewPage showErrorMessage={disableCompleteButton} />
          </ProgressModal.Page>,
        ];

  if (loading && initialSelfServeSourceId) {
    return (
      <BaseModal
        height={780}
        shouldCloseOnOverlayClick={false}
        show
        showCloseButton={false}
        showPrimaryButton={false}
        showXButton={false}
        title={title}
        width={1096}
      >
        <ProgressBar />
      </BaseModal>
    );
  }

  return (
    <>
      <DataUploadModalContext.Provider value={dataUploadState}>
        <DataUploadModalDispatch.Provider value={dataUploadDispatch}>
          <ProgressModal
            childWrapperClassName="data-upload-modal__body"
            className="data-upload-modal"
            height={780}
            onRequestClose={onRequestClose}
            show
            title={title}
            width={1096}
          >
            {pages}
          </ProgressModal>
        </DataUploadModalDispatch.Provider>
      </DataUploadModalContext.Provider>
      <BaseModal
        onPrimaryAction={hideConfirmationModal}
        onSecondaryAction={() => onCloseModal(dataUploadState)}
        primaryButtonOutline
        primaryButtonText={I18N.text('Continue Working')}
        secondaryButtonIntent={Intents.DANGER}
        secondaryButtonText={I18N.text('Cancel Setup')}
        shouldCloseOnOverlayClick={false}
        show={confirmationModalVisible}
        showCloseButton={false}
        showSecondaryButton
        showXButton={false}
        title={I18N.text('Cancel Setup?')}
        width="450px"
      >
        <p>
          <I18N id="modalExitConfirmation">
            Any changes you made will be lost once you exit.
          </I18N>
        </p>
      </BaseModal>
    </>
  );
}
