// @flow
import * as React from 'react';

import AtLeastFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/AtLeastFilter';
import AtMostFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/AtMostFilter';
import BetweenFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/BetweenFilter';
import Dropdown from 'components/ui/Dropdown';
import EqualToFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/EqualToFilter';
// TODO(david, pablo): Generalize this in the core button component so that we
// aren't importing a date picker component
import FullButton from 'components/ui/DatePicker/internal/FullButton';
import GreaterThanFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/GreaterThanFilter';
import Group from 'components/ui/Group';
import Heading from 'components/ui/Heading';
import I18N from 'lib/I18N';
import InputText from 'components/ui/InputText';
import LessThanFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/LessThanFilter';
import type { NumericValueCohortFilterItem } from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';

const DROPDOWN_OPTIONS = [
  <Dropdown.Option key={AtLeastFilter.tag} value={AtLeastFilter.tag}>
    {I18N.text('At least...')}
  </Dropdown.Option>,
  <Dropdown.Option key={AtMostFilter.tag} value={AtMostFilter.tag}>
    {I18N.text('At most...')}
  </Dropdown.Option>,
  <Dropdown.Option key={BetweenFilter.tag} value={BetweenFilter.tag}>
    {I18N.text('Between and including...')}
  </Dropdown.Option>,
  <Dropdown.Option key={EqualToFilter.tag} value={EqualToFilter.tag}>
    {I18N.text('Equal to...')}
  </Dropdown.Option>,
  <Dropdown.Option key={GreaterThanFilter.tag} value={GreaterThanFilter.tag}>
    {I18N.text('Greater than...')}
  </Dropdown.Option>,
  <Dropdown.Option key={LessThanFilter.tag} value={LessThanFilter.tag}>
    {I18N.text('Less than...')}
  </Dropdown.Option>,
];

type Props = {
  initialFilter: NumericValueCohortFilterItem,
  onFilterChange: NumericValueCohortFilterItem => void,
  onRequestClose: () => void,
};

const DEFAULT_FILTER_CREATOR_MAP = {
  [AtLeastFilter.tag]: () => AtLeastFilter.create({ value: 1 }),
  [AtMostFilter.tag]: () => AtMostFilter.create({ value: 10 }),
  [BetweenFilter.tag]: () =>
    BetweenFilter.create({
      maxValue: 10,
      minValue: 1,
    }),
  [EqualToFilter.tag]: () => EqualToFilter.create({ value: 1 }),
  [GreaterThanFilter.tag]: () => GreaterThanFilter.create({ value: 1 }),
  [LessThanFilter.tag]: () => LessThanFilter.create({ value: 10 }),
};

export default function NumericFilterCustomizationModule({
  initialFilter,
  onFilterChange,
  onRequestClose,
}: Props): React.Node {
  const [filter, setFilter] =
    React.useState<NumericValueCohortFilterItem>(initialFilter);

  const onApplyClick = () => {
    onFilterChange(filter);
    onRequestClose();
  };

  const onFilterTypeChanged = newFilterType => {
    if (newFilterType !== filter.tag) {
      const filterCreator = DEFAULT_FILTER_CREATOR_MAP[newFilterType];
      setFilter(filterCreator());
    }
  };

  const renderFilterCustomizationSection = () => {
    if (filter.tag === BetweenFilter.tag) {
      return (
        <Group.Horizontal flex spacing="m">
          <InputText
            onChange={newValue => setFilter(filter.minValue(Number(newValue)))}
            type="number"
            value={String(filter.minValue())}
          />
          <div className="numeric-filter-customization-module__and-pill">
            {I18N.textById('and')}
          </div>
          <InputText
            onChange={newValue => setFilter(filter.maxValue(Number(newValue)))}
            type="number"
            value={String(filter.maxValue())}
          />
        </Group.Horizontal>
      );
    }

    return (
      <InputText
        onChange={newValue => setFilter(filter.set('value', Number(newValue)))}
        type="number"
        value={String(filter.get('value'))}
      />
    );
  };

  return (
    <div className="numeric-filter-customization-module">
      <Group.Vertical padding="l" spacing="m">
        <Dropdown
          buttonWidth="100%"
          onSelectionChange={onFilterTypeChanged}
          value={filter.tag}
        >
          {DROPDOWN_OPTIONS}
        </Dropdown>
        {renderFilterCustomizationSection()}
      </Group.Vertical>
      <FullButton onClick={onApplyClick}>
        <Heading.Small whiteText>{I18N.textById('Apply')}</Heading.Small>
      </FullButton>
    </div>
  );
}
