// @flow
import { Record } from 'immutable';
import type { RecordFactory } from 'immutable';

import DataprepJob from 'models/DataUploadApp/DataprepJob';
import type { SerializedDataprepJob } from 'models/DataUploadApp/DataprepJob';

export type SerializedOutputDataprepFlow = {
  appendable: boolean,
  expectedColumns: $ReadOnlyArray<string>,
  recipeId: number,
};

export type SerializedInputDataprepFlow = {
  ...SerializedOutputDataprepFlow,
  latestDataprepJob?: SerializedDataprepJob | null,
};

type DataprepFlowProps = {
  appendable: boolean,
  expectedColumns: $ReadOnlyArray<string>,
  latestDataprepJob: DataprepJob | void,
  recipeId: number,
};

const DataprepFlowRecord: RecordFactory<DataprepFlowProps> = Record({
  appendable: undefined,
  expectedColumns: undefined,
  latestDataprepJob: undefined,
  recipeId: undefined,
});

export default class DataprepFlow extends DataprepFlowRecord {
  static deserialize(data: SerializedInputDataprepFlow): DataprepFlow {
    const { appendable, expectedColumns, latestDataprepJob, recipeId } = data;

    return new DataprepFlow({
      appendable,
      expectedColumns,
      recipeId,
      latestDataprepJob: latestDataprepJob
        ? DataprepJob.deserialize(latestDataprepJob)
        : undefined,
    });
  }

  serialize(): SerializedOutputDataprepFlow {
    const { appendable, expectedColumns, recipeId } = this.toObject();
    return { appendable, expectedColumns, recipeId };
  }

  appendable(): boolean {
    return this.get('appendable');
  }

  expectedColumns(): $ReadOnlyArray<string> {
    return this.get('expectedColumns');
  }

  latestDataprepJob(): DataprepJob | void {
    return this.get('latestDataprepJob');
  }

  recipeId(): number {
    return this.get('recipeId');
  }

  setAppendable<T>(value: boolean): T {
    return ((this.set('appendable', value): any): T);
  }

  setExpectedColumns<T>(value: $ReadOnlyArray<string>): T {
    return ((this.set('expectedColumns', value): any): T);
  }

  setRecipeId<T>(value: number): T {
    return ((this.set('recipeId', value): any): T);
  }
}
