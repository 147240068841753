// @flow
import * as Zen from 'lib/Zen';
import type { Serializable } from 'lib/Zen';

type Values = {
  /** Is this status for a new case? Only applies to alert cases */
  isNew: boolean | void,

  /** Is this status for an open case? Only applies to alert cases */
  isOpen: boolean | void,

  /** The human readable label for this status */
  label: string,

  /** The URI of this case status */
  uri: string,
};

type SerializedCaseStatusDescriptor = {
  $uri: string,
  isNew: boolean | void,
  isOpen: boolean | void,
  label: string,
};

/**
 * CaseStatusDescriptor is a representation of the status a case can have.
 */
class CaseStatusDescriptor
  extends Zen.BaseModel<CaseStatusDescriptor, Values>
  implements Serializable<SerializedCaseStatusDescriptor>
{
  static deserialize(
    serializedCaseStatusDescriptor: SerializedCaseStatusDescriptor,
  ): Zen.Model<CaseStatusDescriptor> {
    const { $uri, isNew, isOpen, label } = serializedCaseStatusDescriptor;
    return CaseStatusDescriptor.create({
      isNew,
      isOpen,
      label,
      uri: $uri,
    });
  }

  serialize(): SerializedCaseStatusDescriptor {
    const { uri, ...caseStatusDescriptor } = this.modelValues();
    return { $uri: uri, ...caseStatusDescriptor };
  }
}

export default ((CaseStatusDescriptor: $Cast): Class<
  Zen.Model<CaseStatusDescriptor>,
>);
