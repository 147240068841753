// @flow

import { COLUMN_TYPE } from 'models/DataUploadApp/registry';
import type { SerializedInputColumnSpec } from 'models/DataUploadApp/ColumnSpec';

export type ColumnType = $Keys<typeof COLUMN_TYPE>;

export const DATAPREP_TYPE = 'DATAPREP';
export const CSV_TYPE = 'CSV';
export type DataUploadSourceType = typeof DATAPREP_TYPE | typeof CSV_TYPE;

export type FilePreviewRow = { +[string]: string, ... };

export type FilePreview = $ReadOnlyArray<FilePreviewRow>;

export type DataFileUploadResponse = {
  columnMapping: $ReadOnlyArray<SerializedInputColumnSpec>,
  filePath: string,
  filePreview: FilePreview,
  lastModified: Date,
  sourceId: string,
};

export type PipelineRunMetadata = {
  lastPipelineRuntime: string,
  nextPipelineRuntime: string,
};

export type ExistingDataFileResponse = {
  lastModified: Date,
  userFileName: string,
};

// NOTE(abby): For now, this response only validates that all the required headers
// are present in the correct order. In the future, it might do other validations,
// such as type checks.
export type DataprepValidationResponse = {
  extraHeaders: $ReadOnlyArray<string>,
  filePath: string,
  missingHeaders: $ReadOnlyArray<string>,
  orderCorrect: boolean,
};

export type SourceDateRange = {
  endDate: string | null,
  startDate: string | null,
};

export type ApiUnpublishedField = {
  id: string | null,
  name: string,
  shortName: string,
};

export type ApiUnpublishedDimension = {
  authorizable: boolean,
  dimensionCode: string,
  filterable: boolean,
  nameTranslations: { [string]: string },
  published: boolean,
};
