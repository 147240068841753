// @flow
import * as React from 'react';
import invariant from 'invariant';

import Drawer from 'components/ui/Drawer';
import Group from 'components/ui/Group';
import I18N from 'lib/I18N';
import Icon from 'components/ui/Icon';
import InfoTooltip from 'components/ui/InfoTooltip';
import RadioGroup from 'components/ui/RadioGroup';
import { addLocaleLabel } from 'components/Navbar/util';
import { autobind } from 'decorators';
import { onLinkClicked } from 'util/util';

const INITIAL_PAGE_ID = 'settings';

type Props = {
  buildTag: string | void,
  dataUpdate: {
    titleName: React.Node,
    tooltipText: string,
    value: string | void,
  },
  isAuthenticated: boolean,
  username: string | void,
};

type State = {
  activePageId: string,
  openDrawer: boolean,
};

export default class MobileNavbar extends React.PureComponent<Props, State> {
  state: State = {
    activePageId: INITIAL_PAGE_ID,
    openDrawer: false,
  };

  @autobind
  navigateToPage(pageId: string) {
    this.setState({ activePageId: pageId });
  }

  @autobind
  onRequestClose() {
    this.setState(prevState => ({
      activePageId: INITIAL_PAGE_ID,
      openDrawer: !prevState.openDrawer,
    }));
  }

  @autobind
  onHamburgerClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    this.setState(prevState => ({
      openDrawer: !prevState.openDrawer,
    }));
  }

  maybeRenderDrawer(): React.Node {
    const { locales } = window.__JSON_FROM_BACKEND;
    const { logoutUrl } = window.__JSON_FROM_BACKEND.user;
    const { showLocalePicker } = window.__JSON_FROM_BACKEND.ui;
    const { activePageId, openDrawer } = this.state;

    const labeledLocales = addLocaleLabel(locales);
    // Try to find the current locale or fallback to the default locale
    const currentLocale =
      labeledLocales.find(
        locale => locale.id === window.__JSON_FROM_BACKEND.locale,
      ) ||
      labeledLocales.find(
        locale => locale.id === window.__JSON_FROM_BACKEND.ui.defaultLocale,
      );
    invariant(currentLocale, 'Locales could not be loaded');

    const languageRow = showLocalePicker ? (
      <div
        className="navbar-mobile-drawer__button-row"
        onClick={() => this.navigateToPage('language')}
        role="button"
      >
        {I18N.text('Language')}
        <span className="navbar-mobile-drawer__language-button">
          {currentLocale.shortLabel} <Icon type="menu-right" />
        </span>
      </div>
    ) : (
      <div className="navbar-mobile-drawer__button-row">
        {I18N.textById('Language')}
        <span className="navbar-mobile-drawer__language-button">
          {currentLocale.shortLabel}
        </span>
      </div>
    );

    const pages = [
      <Drawer.Page id="settings" title={I18N.textById('Settings')}>
        <Group.Vertical itemClassName="navbar-mobile-drawer__row">
          {this.renderSummaryInfo()}
          {languageRow}
          {this.props.isAuthenticated && (
            <div
              className="navbar-mobile-drawer__button-row navbar-mobile-drawer__sign-out-button"
              onClick={() =>
                onLinkClicked(logoutUrl, {}, 'User logged out', {
                  username: this.props.username,
                })
              }
              role="button"
            >
              {I18N.textById('Sign out')}
            </div>
          )}
        </Group.Vertical>
      </Drawer.Page>,
      <Drawer.Page id="language" title={I18N.textById('Language')}>
        <RadioGroup
          direction="vertical"
          onChange={localeId => {
            const selectedLocale = locales.find(
              locale => locale.id === localeId,
            );
            if (selectedLocale) {
              onLinkClicked(
                selectedLocale.url,
                {},
                `Locale ${selectedLocale.id} navigation link clicked`,
              );
            }
          }}
          value={currentLocale.id}
        >
          {labeledLocales.map(locale => (
            <RadioGroup.Item
              key={locale.id}
              className="mobile-drawer-radio-option"
              value={locale.id}
            >
              {locale.shortLabel}
            </RadioGroup.Item>
          ))}
        </RadioGroup>
      </Drawer.Page>,
    ];

    return (
      <Drawer
        activePageId={activePageId}
        fullScreen
        onNavigateToPage={this.navigateToPage}
        onRequestClose={this.onRequestClose}
        pages={pages}
        show={openDrawer}
      />
    );
  }

  renderDropdownTitleItem(
    titleName: React.Node,
    value: string,
    tooltipText?: string,
  ): React.Node {
    const info = (
      <Group.Vertical className="navbar-mobile-drawer__info-row" spacing="xxs">
        <Group.Item className="navbar-mobile-drawer__info-row-name">
          {titleName}
        </Group.Item>
        <Group.Item className="navbar-mobile-drawer__info-row-value">
          {value}
        </Group.Item>
      </Group.Vertical>
    );

    if (tooltipText) {
      return (
        <Group.Horizontal
          alignItems="center"
          firstItemFlexValue={1}
          flex
          spacing="xs"
        >
          {info}
          <InfoTooltip
            iconClassName="navbar-mobile-drawer__info-row-icon"
            text={tooltipText}
          />
        </Group.Horizontal>
      );
    }
    return info;
  }

  renderSummaryInfo(): React.Node {
    const { buildTag, dataUpdate, username } = this.props;

    const userStatus = username
      ? this.renderDropdownTitleItem(I18N.textById('Logged in as'), username)
      : null;
    const lastDataUpdate = dataUpdate.value
      ? this.renderDropdownTitleItem(
          dataUpdate.titleName,
          dataUpdate.value,
          dataUpdate.tooltipText,
        )
      : null;
    const versionInfo = buildTag
      ? this.renderDropdownTitleItem(I18N.textById('Build version'), buildTag)
      : null;

    return (
      <Group.Vertical spacing="xs">
        {userStatus}
        {lastDataUpdate}
        {versionInfo}
      </Group.Vertical>
    );
  }

  render(): React.Node {
    return (
      <div className="navbar-items">
        <button
          className="navbar-item"
          onClick={this.onHamburgerClick}
          type="button"
        >
          <Icon type="option-horizontal" />
        </button>
        {this.maybeRenderDrawer()}
      </div>
    );
  }
}
