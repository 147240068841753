// @flow
import * as React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

import ActionCell from 'components/DataUploadApp/SourceTable/ActionCell';
import AuthorizationService from 'services/AuthorizationService';
import I18N from 'lib/I18N';
import SelfServeSource from 'models/DataUploadApp/SelfServeSource';
import Table from 'components/ui/Table';
import { DATAPREP_TYPE } from 'models/DataUploadApp/types';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  FAILED_DATAPREP_STATUSES,
} from 'components/DataUploadApp/constants';
import {
  SITE_PERMISSIONS,
  RESOURCE_TYPES,
} from 'services/AuthorizationService/registry';
import { cancelPromise } from 'util/promiseUtil';
import { localizeUrl, useInfiniteScrolling } from 'util/util';
import { useSelfServeSourceStateContext } from 'services/DataUploadApp/SelfServeSourceService';
import type { TableHeader } from 'components/ui/Table';

export const HEADERS: $ReadOnlyArray<TableHeader<SelfServeSource>> = [
  {
    displayContent: I18N.textById('Datasource'),
    id: 'datasource',
    sortFn: Table.Sort.string(source => source.pipelineDatasource().name()),
  },
  {
    displayContent: I18N.text('Integration type'),
    id: 'integrationType',
    sortFn: Table.Sort.string(source => source.integrationType()),
  },
  {
    displayContent: I18N.text('Last updated'),
    id: 'lastUpdated',
    sortFn: Table.Sort.date(source => source.lastModified()),
  },
  { displayContent: I18N.text('Time range of data'), id: 'timeRange' },
  { displayContent: I18N.text('New item count'), id: 'newItemCount' },
  { displayContent: I18N.text('Manage'), id: 'manage' },
].map(header => ({
  ...header,
  headerClassName: 'data-status-table__header-cell',
}));

type Props = {
  buildOnOpenModal: SelfServeSource => () => void,
  isSelfServeAdmin: boolean,
  lastPipelineRuntime: Date | void,
};

export default function SourceTable({
  buildOnOpenModal,
  isSelfServeAdmin,
  lastPipelineRuntime,
}: Props): React.Node {
  const {
    data: selfServeItems,
    hasNext,
    loadNext,
  } = useSelfServeSourceStateContext();
  useInfiniteScrolling(hasNext, loadNext);

  const [canViewCatalogSetup, setCanViewCatalogSetup] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const promise = AuthorizationService.isAuthorized(
      SITE_PERMISSIONS.CAN_VIEW_FIELD_SETUP,
      RESOURCE_TYPES.SITE,
    ).then(isAuthorized => {
      setCanViewCatalogSetup(isAuthorized);
    });
    return () => cancelPromise(promise);
  }, []);

  const getDateRangeCell = (source: SelfServeSource): React.Node => {
    const sourceDateRange = source.sourceRange();
    const noInfo = source.isQueued(lastPipelineRuntime) ? (
      <I18N>Date range information will appear on next pipeline run</I18N>
    ) : (
      <I18N>No date range information</I18N>
    );

    const { endDate, startDate } = sourceDateRange;
    if (!startDate || !endDate) {
      return noInfo;
    }
    return (
      <React.Fragment>
        {format(new Date(startDate), DATE_FORMAT)} -
        {format(new Date(endDate), DATE_FORMAT)}
      </React.Fragment>
    );
  };

  const getNewIndicatorCount = (source): React.Node => {
    const newIndicatorCount =
      source.pipelineDatasource().unpublishedFields() || 0;
    const newIndicatorsText = I18N.text(
      {
        one: '1 new indicator',
        other: '%(count)s new indicators',
        zero: 'No new indicators',
      },
      'unpublished-indicators-count',
      { count: newIndicatorCount },
    );

    if (newIndicatorCount > 0 && canViewCatalogSetup) {
      return (
        <a href={localizeUrl('/catalog-setup?tab=indicators')}>
          {newIndicatorsText}
        </a>
      );
    }
    if (newIndicatorCount > 0) {
      return newIndicatorsText;
    }
    return null;
  };

  const getNewDimensionCount = (source): React.Node => {
    const newDimensionCount = source.unpublishedDimensionsCount();
    const newDimensionsText = I18N.text(
      {
        one: '1 new dimension',
        other: '%(count)s new dimensions',
        zero: 'No new dimensions',
      },
      'unpublished-dimensions-count',
      { count: newDimensionCount },
    );

    // Use a div here to force the dimensions to be on a new line.
    if (newDimensionCount > 0 && canViewCatalogSetup) {
      return (
        <div>
          <a href={localizeUrl('/catalog-setup?tab=dimensions')}>
            {newDimensionsText}
          </a>
        </div>
      );
    }
    if (newDimensionCount > 0) {
      return <div>{newDimensionsText}</div>;
    }
    return null;
  };

  // If the source is no longer queued and there's no data in Druid, then the
  // update failed. Or if there's a bad database state. For Dataprep sources,
  // also check if the status of the Dataprep job failed.
  const didUpdateFail = (source): boolean => {
    const isQueued = source.isQueued(lastPipelineRuntime);
    const sourceDateRange = source.sourceRange();
    const updateFailed =
      !isQueued &&
      (source.errorState() ||
        !sourceDateRange.startDate ||
        !sourceDateRange.endDate);

    const dataprepFlow = source.dataprepFlow();
    if (dataprepFlow) {
      const latestDataprepJob = dataprepFlow.latestDataprepJob();
      if (latestDataprepJob) {
        return (
          updateFailed ||
          FAILED_DATAPREP_STATUSES.has(latestDataprepJob.status())
        );
      }
    }

    return updateFailed;
  };

  const renderActionCell = source => {
    const isQueued = source.isQueued(lastPipelineRuntime);
    const updateFailed = didUpdateFail(source);
    // NOTE(abby): updateFailed and queuedStatus will never both be true at the same time.
    const actionCellClassName = classNames('data-status-table__cell', {
      'data-status-table__queued-cell': isQueued,
      'data-status-table__update-data-cell': !isQueued && !updateFailed,
      'data-status-table__update-failed-cell': updateFailed,
    });

    return (
      <Table.Cell className={actionCellClassName}>
        <ActionCell
          buildOnOpenModal={buildOnOpenModal}
          isSelfServeAdmin={isSelfServeAdmin}
          queuedStatus={isQueued}
          source={source}
          updateFailed={updateFailed}
        />
      </Table.Cell>
    );
  };

  const renderRow = source => {
    return (
      <Table.Row className="data-status-table__row" id={source.sourceId()}>
        <Table.Cell className="data-status-table__cell data-status-table__datasource-cell">
          {source.pipelineDatasource().name()}
        </Table.Cell>
        <Table.Cell className="data-status-table__cell">
          {source.integrationType() === DATAPREP_TYPE
            ? I18N.text('Dataprep')
            : I18N.text('CSV')}
        </Table.Cell>
        <Table.Cell className="data-status-table__cell">
          {format(source.lastModified(), DATE_TIME_FORMAT)}
        </Table.Cell>
        <Table.Cell className="data-status-table__cell">
          {getDateRangeCell(source)}
        </Table.Cell>
        <Table.Cell className="data-status-table__cell">
          {getNewIndicatorCount(source)}
          {getNewDimensionCount(source)}
        </Table.Cell>
        {renderActionCell(source)}
      </Table.Row>
    );
  };

  return (
    <Table
      className="data-status-table"
      data={selfServeItems.toArray()}
      headers={HEADERS}
      initialColumnToSort="datasource"
      isHoverable={false}
      noDataText={I18N.text(
        'There are no data sources at this time.',
        'noSources',
      )}
      noResultsClassName="data-status-table__no-data-cell"
      renderRow={renderRow}
    />
  );
}
