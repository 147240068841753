// @flow
import * as React from 'react';

import CohortDiagramNumber from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortDiagramNumber';
import CohortGroupDiagramSetOperationPill from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortGroupDiagram/CohortGroupDiagramSetOperationPill';
import type CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import type { CohortGroupSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  cohortGroup: CohortGroup,
  cohortGroupSummary: CohortGroupSummary | void,
};

/** See README.md file for documentation */
export default function OppositeOperatorsDiagram({
  cohortGroup,
  cohortGroupSummary,
}: Props): React.Node {
  const numAdditionalSegments = cohortGroup.additionalSegments().size();
  const centerRowOfAdditionalSegments = 2 + numAdditionalSegments;

  const groupValue =
    cohortGroupSummary !== undefined ? cohortGroupSummary.value : undefined;

  const additionalSegmentsValue =
    cohortGroupSummary !== undefined
      ? cohortGroupSummary.additionalSegmentsSummary
      : undefined;

  const groupValuePill = (
    <CohortDiagramNumber
      className="ca-cohort-group-diagram__summary-value"
      fontSize={12}
      style={{ gridColumn: '6', gridRow: `1 / 3` }}
      value={groupValue}
    />
  );

  const renderAdditionalSegmentsValuePill = () => (
    <CohortDiagramNumber
      className="ca-cohort-group-diagram__summary-value"
      fontSize={12}
      style={{
        gridColumn: '6',
        gridRow: `${centerRowOfAdditionalSegments} / ${
          centerRowOfAdditionalSegments + 2
        }`,
      }}
      value={additionalSegmentsValue}
    />
  );

  const renderInnerSetOperationPill = () => (
    <CohortGroupDiagramSetOperationPill
      operations={[
        { isGroupOperation: true, operator: cohortGroup.innerOperation() },
      ]}
      style={{
        gridColumn: '4 / 6',
        gridRow: `${centerRowOfAdditionalSegments} / ${
          centerRowOfAdditionalSegments + 2
        }`,
        margin: 'auto',
      }}
    />
  );

  const renderOuterSetOperationPill = () => (
    <CohortGroupDiagramSetOperationPill
      operations={[
        { isGroupOperation: false, operator: cohortGroup.outerOperation() },
      ]}
      style={{ gridColumn: '4 / 6', gridRow: '1 / 3', margin: 'auto' }}
    />
  );

  const maybeRenderAdditionalSegmentOperationBorderlessPill = (
    additionalSegmentIndex: number,
  ) => {
    if (additionalSegmentIndex === 0) {
      return null;
    }

    const startRow = 2 + additionalSegmentIndex * 2;
    const endRow = startRow + 2;
    return (
      <CohortGroupDiagramSetOperationPill
        borderless
        operations={[
          { isGroupOperation: false, operator: cohortGroup.innerOperation() },
        ]}
        style={{
          gridColumn: '3',
          gridRow: `${startRow} / ${endRow}`,
          margin: 'auto',
        }}
      />
    );
  };

  return (
    <React.Fragment>
      <div
        className="ca-cohort-group-diagram__horizontal-line"
        style={{ gridColumn: '3 / 7', gridRow: 1 }}
      />
      {numAdditionalSegments > 0 && (
        <React.Fragment>
          {renderInnerSetOperationPill()}
          {cohortGroup.additionalSegments().map((segment, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={idx}>
              <div
                className="ca-cohort-group-diagram__horizontal-line"
                style={{ gridColumn: 3, gridRow: 3 + idx * 2 }}
              />
              {maybeRenderAdditionalSegmentOperationBorderlessPill(idx)}
            </React.Fragment>
          ))}
          <div
            className="ca-cohort-group-diagram__vertical-line"
            style={{
              gridColumn: 4,
              gridRow: `4 / ${4 + (numAdditionalSegments - 1) * 2}`,
            }}
          />
          <div
            className="ca-cohort-group-diagram__horizontal-line"
            style={{ gridColumn: 4, gridRow: centerRowOfAdditionalSegments }}
          />
          <div
            className="ca-cohort-group-diagram__vertical-line"
            style={{
              gridColumn: 5,
              gridRow: `2 / ${centerRowOfAdditionalSegments + 1}`,
            }}
          />
          {renderOuterSetOperationPill()}
          {renderAdditionalSegmentsValuePill()}
        </React.Fragment>
      )}
      {groupValuePill}
    </React.Fragment>
  );
}
