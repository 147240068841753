// @flow
import type Promise from 'bluebird';

import * as Zen from 'lib/Zen';
import Query from 'components/visualizations/common/Query/Query';
import unsetGroupTotalSetting from 'models/visualizations/common/unsetGroupTotalSetting';
import { API_VERSION } from 'services/APIService';
import { SORT_DESCENDING } from 'components/QueryResult/graphUtil';
import type LineGraphQueryResultData from 'models/visualizations/LineGraph/LineGraphQueryResultData';
import type QueryResultSpec from 'models/core/QueryResultSpec';
import type QuerySelections from 'models/core/wip/QuerySelections';
import type { QueryEngine } from 'models/core/QueryResultState/interfaces/QueryEngine';

const ENDPOINT = 'query/line_graph';

class LineGraphQueryEngine
  implements QueryEngine<Zen.Serialized<LineGraphQueryResultData>>
{
  run(
    querySelections: QuerySelections,
    queryResultSpec?: QueryResultSpec,
  ): Promise<Zen.Serialized<LineGraphQueryResultData>> {
    // The LineGraph visualization cannot handle dimension and granularity
    // subtotals. Disable that setting before we query.
    const groups = unsetGroupTotalSetting(querySelections.groups());
    let endpointSuffix = '';
    if (queryResultSpec) {
      const controls = queryResultSpec.getVisualizationControls('TIME');
      const isDescending = controls.sortOrder() === SORT_DESCENDING;
      const resultLimit = controls.resultLimit();
      if (resultLimit > 0) {
        endpointSuffix = `?result_limit=${
          isDescending ? -resultLimit : resultLimit
        }`;
      }
    }
    return Query.create(
      ENDPOINT + endpointSuffix,
      querySelections.groups(groups).serializeForQuery(),
      API_VERSION.V2,
    ).run();
  }
}

export default (new LineGraphQueryEngine(): LineGraphQueryEngine);
