// @flow
import * as React from 'react';

import * as Zen from 'lib/Zen';
import AddItemButton from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/common/AddItemButton';
import Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';
import CohortCreationContext from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortCreationContext';
import CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import CohortGroupBlock from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortGroupBlock';
import CohortGroupOperationRow from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortGroupOperationRow';
import Group from 'components/ui/Group';
import HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import I18N from 'lib/I18N';
import getCohortFilterHierarchy from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/getCohortFilterHierarchy';
import { autobind, memoizeOne } from 'decorators';
import type Dimension from 'models/core/wip/Dimension';
import type DimensionValue from 'models/core/wip/Dimension/DimensionValue';
import type Field from 'models/core/wip/Field';
import type LinkedCategory from 'models/core/wip/LinkedCategory';
import type { CohortFilterCategory } from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/getCohortFilterHierarchy';

type DimensionValueMap = {
  +[dimensionId: string]: $ReadOnlyArray<DimensionValue>,
  ...
};

type Props = {
  cohorts: Zen.Array<Cohort>,
  dimensionId: string,
  dimensions: $ReadOnlyArray<Dimension>,
  dimensionValueMap: DimensionValueMap | void,
  fieldHierarchyRoot: HierarchyItem<LinkedCategory | Field> | void,
  groupLabelsPerCohort: $ReadOnlyArray<$ReadOnlyArray<string>>,
  onCohortsChange: (Zen.Array<Cohort>) => void,
  updateFieldHierarchyOnItemSelect: (HierarchyItem<Field>) => void,
};

type State = {
  dimensionValueMap: DimensionValueMap,
  fieldHierarchyRoot: HierarchyItem<LinkedCategory | Field> | void,
  filterHierarchyRoot: HierarchyItem<
    LinkedCategory | Dimension | CohortFilterCategory,
  > | void,
};

export default class CohortCreationPanel extends React.PureComponent<
  Props,
  State,
> {
  state: State = {
    dimensionValueMap: {},
    fieldHierarchyRoot: undefined,
    filterHierarchyRoot: undefined,
  };

  componentDidMount() {
    const { dimensionValueMap, dimensions, fieldHierarchyRoot } = this.props;
    this.setState({
      dimensionValueMap,
      fieldHierarchyRoot,
      filterHierarchyRoot: getCohortFilterHierarchy(dimensions),
    });
  }

  @memoizeOne
  buildContext(
    fieldHierarchyRoot: HierarchyItem<LinkedCategory | Field> | void,
    filterHierarchyRoot: HierarchyItem<
      LinkedCategory | Dimension | CohortFilterCategory,
    > | void,
    dimensionValueMap: DimensionValueMap,
  ): $ContextType<typeof CohortCreationContext> {
    return {
      dimensionValueMap,
      fieldHierarchyLoaded: fieldHierarchyRoot !== undefined,
      fieldHierarchyRoot:
        fieldHierarchyRoot ||
        HierarchyItem.createRoot<LinkedCategory | Field>(),
      filterHierarchyLoaded: fieldHierarchyRoot !== undefined,
      filterHierarchyRoot:
        filterHierarchyRoot ||
        HierarchyItem.createRoot<LinkedCategory | Dimension>(),
      updateFieldHierarchyOnItemSelect:
        this.props.updateFieldHierarchyOnItemSelect,
    };
  }

  getContext(): $ContextType<typeof CohortCreationContext> {
    const { dimensionValueMap, fieldHierarchyRoot, filterHierarchyRoot } =
      this.state;
    return this.buildContext(
      fieldHierarchyRoot,
      filterHierarchyRoot,
      dimensionValueMap,
    );
  }

  onCohortChange(newCohort: Cohort, currentCohort: Cohort) {
    const { cohorts, onCohortsChange } = this.props;
    const idx = cohorts.indexOf(currentCohort);
    // TODO(stephen): Handle edge case where current cohort is somehow missing
    // from the array.
    if (idx !== -1) {
      if (newCohort.cohortGroups().isEmpty()) {
        onCohortsChange(cohorts.delete(idx));
      } else {
        onCohortsChange(cohorts.set(idx, newCohort));
      }
    }
  }

  @autobind
  onAddCohortGroupClick() {
    const { cohorts, onCohortsChange } = this.props;
    const lastIdx = cohorts.size() - 1;
    const lastCohort = cohorts.get(lastIdx);
    onCohortsChange(
      cohorts.set(
        lastIdx,
        lastCohort.deepUpdate().cohortGroups().push(CohortGroup.create({})),
      ),
    );
  }

  @autobind
  onCohortGroupOperationToggle(
    ungroup: boolean,
    cohortIdx: number,
    groupIdx: number,
  ) {
    const { cohorts, onCohortsChange } = this.props;

    // It should not be possible to operate on the first cohort group within the
    // first cohort.
    if (cohortIdx === 0 && groupIdx === 0) {
      return;
    }

    const currentCohort = cohorts.get(cohortIdx);
    const currentCohortGroups = currentCohort.cohortGroups();

    // It should only be possible to group the first group of a cohort. All
    // other groups within the cohort are already linked.
    if (!ungroup) {
      if (groupIdx !== 0) {
        return;
      }

      // Add all groups from this cohort to the end of the previous cohort.
      const prevCohortIdx = cohortIdx - 1;
      const cohortToMergeInto = cohorts.get(prevCohortIdx);
      const newCohorts = cohorts
        .set(
          prevCohortIdx,
          cohortToMergeInto
            .deepUpdate()
            .cohortGroups()
            .concat(currentCohortGroups),
        )
        .delete(cohortIdx);
      onCohortsChange(newCohorts);
      return;
    }

    // Need to ungroup the remaining groups from this cohort (starting with the
    // groupIdx) and add them to the next cohort in the list. If no subsequent
    // cohort exists, create one.
    const cohortGroupsToRemain = currentCohortGroups.slice(0, groupIdx);
    const cohortGroupsToMove = currentCohortGroups.slice(groupIdx);
    const nextCohortIdx = cohortIdx + 1;
    let newCohorts = cohorts;
    if (nextCohortIdx >= cohorts.size()) {
      newCohorts = newCohorts.push(
        Cohort.create({ cohortGroups: cohortGroupsToMove }),
      );
    } else {
      const nextCohort = cohorts
        .get(nextCohortIdx)
        .cohortGroups(
          cohortGroupsToMove.concat(cohorts.get(nextCohortIdx).cohortGroups()),
        );
      newCohorts = newCohorts.set(nextCohortIdx, nextCohort);
    }
    onCohortsChange(
      newCohorts.set(
        cohortIdx,
        currentCohort.cohortGroups(cohortGroupsToRemain),
      ),
    );
  }

  renderCohorts(): React.Node {
    const { cohorts, dimensionId, groupLabelsPerCohort } = this.props;
    const isOnlyOneCohortGroup =
      cohorts.size() === 1 && cohorts.get(0).cohortGroups().size() === 1;

    const cohortBlocks = cohorts.mapValues((cohort, cohortIdx) => {
      const cohortGroups = cohort.cohortGroups();
      const groupBlocks = cohortGroups.mapValues((cohortGroup, groupIdx) => {
        const groupLabel = groupLabelsPerCohort[cohortIdx][groupIdx];
        return (
          <React.Fragment key={groupLabel}>
            {groupIdx !== 0 && (
              <CohortGroupOperationRow
                cohortGroupIdx={groupIdx}
                cohortGroupOperation="INTERSECT"
                cohortIdx={cohortIdx}
                onCohortGroupOperationToggle={this.onCohortGroupOperationToggle}
              />
            )}
            <CohortGroupBlock
              cohortGroup={cohortGroup}
              dimensionId={dimensionId}
              label={groupLabel}
              onCohortGroupChange={newCohortGroup =>
                this.onCohortChange(
                  cohort.cohortGroups(
                    cohortGroups.set(groupIdx, newCohortGroup),
                  ),
                  cohort,
                )
              }
              onRemoveCohortGroup={() =>
                this.onCohortChange(
                  cohort.cohortGroups(cohortGroups.delete(groupIdx)),
                  cohort,
                )
              }
              removable={!isOnlyOneCohortGroup}
            />
          </React.Fragment>
        );
      });
      return (
        <React.Fragment key={`cohort--${cohortIdx}`}>
          {cohortIdx !== 0 && (
            <CohortGroupOperationRow
              cohortGroupIdx={0}
              cohortGroupOperation="UNION"
              cohortIdx={cohortIdx}
              onCohortGroupOperationToggle={this.onCohortGroupOperationToggle}
            />
          )}
          <div className="cohort-creation-panel__cohort-block">
            {groupBlocks}
          </div>
        </React.Fragment>
      );
    });

    return (
      <div className="cohort-creation-panel__cohorts-block">{cohortBlocks}</div>
    );
  }

  render(): React.Node {
    return (
      <Group.Vertical className="cohort-creation-panel" flex spacing="m">
        <CohortCreationContext.Provider value={this.getContext()}>
          {this.renderCohorts()}
        </CohortCreationContext.Provider>
        <AddItemButton
          onClick={this.onAddCohortGroupClick}
          text={I18N.text('Add new group to cohort')}
        />
      </Group.Vertical>
    );
  }
}
