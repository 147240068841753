// @flow
import Promise from 'bluebird';
import invariant from 'invariant';
import { Record } from 'immutable';
import type { RecordFactory } from 'immutable';

import I18N from 'lib/I18N';
import { COLUMN_TYPE } from 'models/DataUploadApp/registry';
import { FullDimensionService } from 'services/wip/DimensionService';
import { getFullDimensionName } from 'models/core/wip/Dimension';
import { slugify } from 'util/stringUtil';
import type Dimension from 'models/core/wip/Dimension';
import type Field from 'models/core/wip/Field/';
import type GroupingDimension from 'models/core/wip/GroupingItem/GroupingDimension';
import type { ColumnType } from 'models/DataUploadApp/types';

// NOTE(abby): If anymore logic that is different between the column types gets added to this
// file, it should probably just be split into different files.

type Datatype = 'number' | 'string' | 'datetime';

const DATE_CANONICAL_NAME = I18N.textById('Date');

// NOTE(abby): These functions are only called when the ColumnSpec is updated
// Get the canonical name for a dimension column
function getDimensionCanonicalName(
  name: string,
  dimension: Dimension | void,
): string {
  // If a match exists (and thus dimension is not undefined) then use that dimension's name,
  // else use the column name
  if (!dimension) {
    return name;
  }
  return dimension.name();
}

// Get the canonical name for a field column
function getFieldCanonicalName(name: string, field?: Field | void): string {
  // If a match exists (and thus field is not undefined) then use that field's name, else use
  // the column name
  if (!field) {
    return name;
  }
  return field.canonicalName();
}

function getSlugifiedFieldId(sourceId: string, name: string): string {
  return `${sourceId}_${slugify(name, '_', false)}`;
}

function getDimensionCode(name: string): string {
  return `${slugify(name, '', false, 'TITLE')}`;
}

type ColumnSpecProps = {
  /** The canonical name in the platform for the column */
  // HACK(abby): This value is derived in that it is completely dependent on the columnType,
  // name, and match values. However, as changing the column type or match calls a custom
  // function, just set this value there as this value may require a field lookup that would
  // have already been done in those functions.
  canonicalName: string,

  /** Type of the column when integrated */
  columnType: ColumnType,

  /** Type of the data */
  datatype: Datatype,

  /** Whether the column should be included in the integration */
  ignoreColumn: boolean,

  /** Whether this column will be created as a new field or dimension when the
   * source is saved. It's not applicable for the date. */
  isNewColumn: boolean,

  /** Canonical dimension or field match */
  match: string | void,

  /** Column name in the uploaded file */
  name: string,
};

export type SerializedOutputColumnSpec = {
  columnType: ColumnType,
  datatype: Datatype,
  ignoreColumn: boolean,
  match: string | null,
  name: string,
};

export type SerializedInputColumnSpec =
  | {
      ...SerializedOutputColumnSpec,
      columnType: 'DATE',
    }
  | {
      ...SerializedOutputColumnSpec,
      canonicalName: string,
      columnType: 'FIELD',
    }
  | {
      ...SerializedOutputColumnSpec,
      columnType: 'DIMENSION',
      nameTranslations: { [string]: string },
      published: boolean,
    };

const ColumnSpecRecord: RecordFactory<ColumnSpecProps> = Record({
  canonicalName: undefined,
  columnType: undefined,
  datatype: undefined,
  ignoreColumn: undefined,
  isNewColumn: undefined,
  match: undefined,
  name: undefined,
});

/**
 * ColumnSpec contains information about a column from an input CSV.
 */
export default class ColumnSpec extends ColumnSpecRecord {
  static deserialize(
    serializedColumnSpec: SerializedInputColumnSpec,
  ): ColumnSpec {
    const { columnType, datatype, ignoreColumn, match, name } =
      serializedColumnSpec;
    const columnSpec = {
      columnType,
      datatype,
      ignoreColumn,
      name,
      match: match || undefined,
    };

    switch (serializedColumnSpec.columnType) {
      case COLUMN_TYPE.FIELD: {
        const { canonicalName } = serializedColumnSpec;
        return new ColumnSpec({
          ...columnSpec,
          canonicalName: match ? canonicalName : name,
          // All fields default to being new fields
          isNewColumn: !match,
        });
      }
      case COLUMN_TYPE.DATE:
        return new ColumnSpec({
          ...columnSpec,
          canonicalName: DATE_CANONICAL_NAME,
          isNewColumn: false,
        });
      case COLUMN_TYPE.DIMENSION: {
        const { nameTranslations, published } = serializedColumnSpec;
        const canonicalName = match
          ? getFullDimensionName(match, nameTranslations)
          : name;
        return new ColumnSpec({
          ...columnSpec,
          canonicalName,
          // Dimensions do not default to being new dimensions, but handle that a dimension
          // may not have been published yet
          isNewColumn: !!match && !published,
        });
      }
      default:
        (serializedColumnSpec.type: empty);
        throw new Error(
          `[ColumnSpec Deserialization] Invalid column type '${columnType}'.`,
        );
    }
  }

  toDimensionType(): Promise<ColumnSpec> {
    const columnSpec = this.toObject();
    const { name } = columnSpec;
    return FullDimensionService.get(name).then(dimension => {
      const match = dimension === undefined ? undefined : name;
      return new ColumnSpec({
        ...columnSpec,
        match,
        canonicalName: getDimensionCanonicalName(name, dimension),
        columnType: COLUMN_TYPE.DIMENSION,
        isNewColumn: false,
      });
    });
  }

  toDateType(): ColumnSpec {
    const columnSpec = this.toObject();
    return new ColumnSpec({
      ...columnSpec,
      canonicalName: DATE_CANONICAL_NAME,
      columnType: COLUMN_TYPE.DATE,
      isNewColumn: false,
      match: undefined,
    });
  }

  toFieldType(): ColumnSpec {
    const columnSpec = this.toObject();
    const { name } = columnSpec;
    return new ColumnSpec({
      ...columnSpec,
      canonicalName: getFieldCanonicalName(name),
      columnType: COLUMN_TYPE.FIELD,
      isNewColumn: true,
      match: undefined,
    });
  }

  updateDimensionMatch(newMatch: GroupingDimension): ColumnSpec {
    invariant(
      this.columnType() === COLUMN_TYPE.DIMENSION,
      `This function is only for type dimension, not type ${this.columnType()}`,
    );

    const columnSpec = this.toObject();
    return new ColumnSpec({
      ...columnSpec,
      canonicalName: newMatch.name(),
      match: newMatch.id(),
    });
  }

  updateFieldMatch(field: Field): ColumnSpec {
    invariant(
      this.columnType() === COLUMN_TYPE.FIELD,
      `This function is only for type field, not type ${this.columnType()}`,
    );

    const columnSpec = this.toObject();
    return new ColumnSpec({
      ...columnSpec,
      canonicalName: field.canonicalName(),
      match: field.id(),
    });
  }

  // The invalid cards do not include cards that are ignored, since they do not
  // have issues that the user needs to address before submitting
  isInvalid(): boolean {
    return !this.ignoreColumn() && this.error();
  }

  serialize(sourceId: string): SerializedOutputColumnSpec {
    const { columnType, datatype, ignoreColumn, isNewColumn, match, name } =
      this.toObject();
    // When serializing new columns, the name should be used as the match
    let newMatch = match || null;
    if (columnType === COLUMN_TYPE.FIELD && isNewColumn) {
      newMatch = getSlugifiedFieldId(sourceId, name);
    } else if (columnType === COLUMN_TYPE.DIMENSION && isNewColumn) {
      newMatch = getDimensionCode(name);
    }
    return { columnType, datatype, ignoreColumn, name, match: newMatch };
  }

  error(): boolean {
    switch (this.columnType()) {
      case COLUMN_TYPE.DATE:
        return this.datatype() !== 'datetime';
      case COLUMN_TYPE.FIELD:
        return (
          this.datatype() !== 'number' || (!this.isNewColumn() && !this.match())
        );
      case COLUMN_TYPE.DIMENSION:
        return !this.isNewColumn() && !this.match();
      default:
        return true;
    }
  }

  canonicalName(): string {
    return this.get('canonicalName');
  }

  columnType(): ColumnType {
    return this.get('columnType');
  }

  datatype(): Datatype {
    return this.get('datatype');
  }

  ignoreColumn(): boolean {
    return this.get('ignoreColumn');
  }

  isNewColumn(): boolean {
    return this.get('isNewColumn');
  }

  match(): string | void {
    return this.get('match');
  }

  name(): string {
    return this.get('name');
  }

  setCanonicalName<T>(canonicalName: string): T {
    return ((this.set('canonicalName', canonicalName): any): T);
  }

  setColumnType<T>(columnType: ColumnType): T {
    return ((this.set('columnType', columnType): any): T);
  }

  setDatatype<T>(datatype: Datatype): T {
    return ((this.set('datatype', datatype): any): T);
  }

  setIgnoreColumn<T>(ignoreColumn: boolean): T {
    return ((this.set('ignoreColumn', ignoreColumn): any): T);
  }

  setIsNewColumn<T>(isNewColumn: boolean): T {
    return ((this.set('isNewColumn', isNewColumn): any): T);
  }

  setMatch<T>(match: string | void): T {
    return ((this.set('match', match): any): T);
  }

  setName<T>(name: string): T {
    return ((this.set('name', name): any): T);
  }
}
