// @flow
import invariant from 'invariant';
import { List } from 'immutable';

import autobind from 'decorators/autobind';
import { API_VERSION } from 'services/APIService';
import type { HTTPService } from 'services/APIService';

type SortOrder<T> = $ReadOnly<{|
  [key: $Keys<T>]: boolean,
|}>;

type SortOrderType<T> = $Shape<SortOrder<T>>;

type PaginationSpec = {
  page?: number,
  perPage?: number,
};

type Filter<T> = $ReadOnly<{|
  [key: $Keys<T>]: string,
|}>;

type FilterType<T> = $Shape<Filter<T>>;

export type NotFilterableGetType<S> = {
  sort?: SortOrderType<S>,
  ...PaginationSpec,
};

type Get<S> = {
  filter?: FilterType<S>,
  ...NotFilterableGetType<S>,
};

export type UpdatesList<M> = $ReadOnlyArray<[M | void, M | void]>;

export type GetType<S> = $Shape<Get<S>>;

type AnyGetParams = { [key: string]: string };

function toUrlEncodedString(obj: AnyGetParams): string {
  return Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
}

type Deserialize<M, S> = (data: S) => M;
type Serialize<M, S> = (model: M) => S;

type Task = (?mixed) => void;
type Locks<M> = Set<M>;
type TaskTuple<M> = [Task, Locks<M> | void];

export default class PotionService<M, IS, OS> {
  _httpService: HTTPService;
  _onItemUpdate: (oldItem: M | void, newItem: M | void) => void;
  _path: string;
  _queue: Array<TaskTuple<M>>;
  _urlQueue: Map<string, Array<Task>>;
  _runningTasks: Set<TaskTuple<M>>;
  serialize: Serialize<M, OS>;
  deserialize: Deserialize<M, IS>;

  constructor(
    httpService: HTTPService,
    path: string,
    serialize: Serialize<M, OS>,
    deserialize: Deserialize<M, IS>,
  ) {
    this._httpService = httpService;
    this._path = path;
    this._queue = [];
    this._runningTasks = new Set();
    this.serialize = serialize;
    this.deserialize = deserialize;
    this._onItemUpdate = () => {};
  }

  @autobind
  constructIdUri(id: number | string): string {
    return `${this._path}/${id}`;
  }

  setItemUpdateCallback(
    cb: (oldItem: M | void, newItem: M | void) => void,
  ): void {
    this._onItemUpdate = cb;
  }

  @autobind
  get(params: GetType<IS> = {}): Promise<[List<M>, number, boolean]> {
    /* Get model instances from the API without notifying the listener */
    const getParams = this._buildGetParams<IS>(params);
    return this._doGet(this._path, getParams).then(
      ([objects, count, hasNext]) => {
        invariant(count !== undefined, 'No count was returned');
        return [List(objects.map(this.deserialize)), count, hasNext];
      },
    );
  }

  @autobind
  getById(id: number): Promise<M> {
    return this._enqueue(() =>
      this._httpService
        .get(API_VERSION.V2, this.constructIdUri(id))
        .then(this.deserialize),
    );
  }

  @autobind
  _doGet<T>(
    path: string,
    getParams: AnyGetParams,
  ): Promise<[T, number, boolean]> {
    /* Actually perform a GET operation. No other calls should refer to
     * `this._httpService.exGet` directly */
    return this._enqueue(() =>
      this._httpService
        .extendedGet(
          API_VERSION.V2,
          `${this.computeUri(path)}?${toUrlEncodedString(getParams)}`,
        )
        .then(([results, count, hasNext]) => {
          invariant(count !== undefined, "Server didn't return items counter");
          invariant(hasNext !== undefined, "Server didn't return Link header");
          return [results, count, hasNext];
        }),
    );
  }

  _buildGetParams<T>({
    filter,
    page,
    perPage,
    sort,
  }: GetType<T> = {}): AnyGetParams {
    const getParams = {};
    if (sort) {
      getParams.sort = JSON.stringify(sort);
    }
    if (page) {
      getParams.page = page.toString();
    }
    if (perPage) {
      getParams.per_page = perPage.toString();
    }
    if (filter) {
      getParams.where = JSON.stringify(filter);
    }
    return getParams;
  }

  @autobind
  mixedGet(
    path: string,
    params: GetType<IS>,
  ): Promise<[mixed, number, boolean]> {
    /* for all fetches that don't follow the schema.
     * Obviously, doesn't notify the listener. */
    const getParams = this._buildGetParams(params);
    return this._doGet(path, getParams);
  }

  @autobind
  fetchMore(params: GetType<IS> = {}): Promise<[List<M>, number, boolean]> {
    /* fetches objects from the remote end and notifies listener about them
     * Returns a tuple of retreived objects, their number and if there are more
     * to fetch on the next page */
    return this.get(params).then(([objects, count, hasNext]) => {
      invariant(count !== undefined, 'No count was returned');
      objects.forEach(item => this._onItemUpdate(undefined, item));
      return [objects, count, hasNext];
    });
  }

  @autobind
  create(item: M): Promise<void> {
    // TODO: implement a way to handle updates
    return this.post(this._path, this.serialize(item));
  }

  @autobind
  // don't like this `uri` parameter but how to define a base model that has it?
  update(uri: string, oldItem: M, newItem: M): Promise<void> {
    return this.patch(this.computeUri(uri), this.serialize(newItem), [
      [oldItem, newItem],
    ]);
  }

  @autobind
  delete(uri: string, oldItem: M): Promise<void> {
    return this._enqueue(
      () => this._httpService.delete(API_VERSION.V2, this.computeUri(uri)),
      [[oldItem, undefined]],
    );
  }

  computeUri(maybeRelativeUri: string): string {
    return maybeRelativeUri.length && maybeRelativeUri[0] === '/'
      ? maybeRelativeUri
      : `${this._path}/${maybeRelativeUri}`;
  }

  @autobind
  _lockTest(taskTuple: TaskTuple<M>, locks: Locks<M>): boolean {
    if (taskTuple[1] === undefined) return true;
    const intersection = new Set([...taskTuple[1]].filter(x => locks.has(x)));
    // intersection means there are conflicting locks
    return !!intersection.size;
  }

  @autobind
  _noLockTest(taskTuple: TaskTuple<M>, locks: Locks<M>): boolean {
    return !this._lockTest(taskTuple, locks);
  }

  @autobind
  _runNext() {
    const taskTuple = this._queue.find(currentT => {
      const allLocks: Locks<M> = [...this._queue].reduce((acc, t) => {
        return currentT === t || t[1] === undefined
          ? acc
          : new Set([...acc, ...t[1]]);
      }, new Set());
      return (
        !this._runningTasks.has(currentT) &&
        ((currentT[1] === undefined && !this._runningTasks.size) ||
          this._noLockTest(currentT, allLocks))
      );
    });
    if (!taskTuple) return;

    const [task] = taskTuple;
    if (task) {
      this._runningTasks.add(taskTuple);
      task();
    }
  }

  _enqueue<R>(task: () => Promise<R>, updates: ?UpdatesList<M>): Promise<R> {
    const locks = updates
      ? updates.reduce((acc, [newItem, oldItem]) => {
          if (oldItem) acc.add(oldItem);
          if (newItem) acc.add(newItem);
          return acc;
        }, new Set())
      : undefined;
    (updates || []).forEach(([oldItem, newItem]) => {
      this._onItemUpdate(oldItem, newItem);
    });
    return new Promise((resolve, reject) => {
      const dequeue = () => {
        const runningTask = [...this._runningTasks].find(t => t[1] === locks);
        if (runningTask) {
          this._runningTasks.delete(runningTask);
        }
        const position = this._queue.findIndex(t => t[1] === locks);
        invariant(position >= 0, 'Can not be here');
        this._queue.splice(position, 1);
        this._runNext();
      };
      this._queue.push([
        (error?: mixed) => {
          const errorHandler = _error => {
            const position = this._queue.findIndex(t => t[1] === locks);
            invariant(position >= 0, 'Can not be here');
            const _queue = [...this._queue].splice(position + 1).reverse();
            const toReject = locks
              ? _queue.filter(
                  t => t[1] !== undefined && this._lockTest(t, locks),
                )
              : [];
            toReject.forEach(([_task]) => _task(new Error()));

            (updates || []).forEach(([oldItem, newItem]) => {
              this._onItemUpdate(newItem, oldItem);
            });
            reject(_error);
            this._runNext();
          };
          if (error) {
            errorHandler(error);
            dequeue();
            return;
          }
          task().then(resolve).catch(errorHandler).then(dequeue);
        },
        locks,
      ]);
      this._runNext();
    });
  }

  patch<T>(
    uri: string,
    data: mixed,
    updates: ?UpdatesList<M>,
    getParams?: GetType<T>,
  ): Promise<void> {
    const fullUri = getParams
      ? `${uri}?${toUrlEncodedString(this._buildGetParams<T>(getParams))}`
      : uri;
    return this._enqueue(
      () =>
        this._httpService.patch(API_VERSION.V2, this.computeUri(fullUri), data),
      updates,
    );
  }

  post<T>(
    uri: string,
    data: mixed,
    updates: ?UpdatesList<M>,
    getParams?: GetType<T>,
  ): Promise<void> {
    const fullUri = getParams
      ? `${uri}?${toUrlEncodedString(this._buildGetParams<T>(getParams))}`
      : uri;
    return this._enqueue(
      () =>
        this._httpService.post(API_VERSION.V2, this.computeUri(fullUri), data),
      updates,
    );
  }
}
