// @flow
import * as React from 'react';
import Promise from 'bluebird';

import APIService, { API_VERSION } from 'services/APIService';
import { convertURIToID } from 'services/wip/util';
import { suspendFetcher } from 'util/util';

export type PipelineDatasources = { [string]: string };

export function getPipelineDatasources(): Promise<PipelineDatasources> {
  const apiVersion = API_VERSION.V2;
  const endpoint = 'query/pipeline-datasources';
  return APIService.get(apiVersion, `${endpoint}?per_page=10000`).then(
    rawPipelineDatasources => {
      const mapping = {};
      rawPipelineDatasources.forEach(pipelineDatasource => {
        const id = convertURIToID(
          pipelineDatasource.$uri,
          apiVersion,
          endpoint,
        );
        mapping[id] = pipelineDatasource.name;
      });
      return mapping;
    },
  );
}

export default function useDatasourceLookup(): string => string {
  const datasourceMapping = React.useMemo(
    () => suspendFetcher(getPipelineDatasources),
    [],
  );
  return (source: string) => datasourceMapping[source] || source;
}
