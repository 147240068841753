// @flow
import React, { useContext, useState, createContext, type Node } from 'react';
import invariant from 'invariant';
import { List } from 'immutable';

import PotionService from 'services/PotionService';
import { anoop, noop, suspendFetcher } from 'util/util';
import type { GetType } from 'services/PotionService';

type CbType<M> = (M | void, M | void) => void;

export type PotionContextType<M, IS, S> = {
  count: number,
  data: List<M>,
  hasNext: boolean,
  loadNext: () => Promise<void>,
  page: number,
  reload: () => void,
  service: S,
  setParams: (GetType<IS>) => Promise<void>,
  subscribe: (CbType<M>) => () => void,
};

type PotionProviderArgs = { children: Node };

export type PotionItemStateProviderType<M, IS, S> = {
  PotionItemStateProvider: PotionProviderArgs => React$Node,
  usePotionItemStateContext: () => PotionContextType<M, IS, S>,
};

const createPotionItemStateContext = <M, IS, S>(
  service: S,
): React$Context<PotionContextType<M, IS, S>> =>
  createContext<PotionContextType<M, IS, S>>({
    service,
    count: 0,
    data: List(),
    hasNext: false,
    loadNext: anoop,
    page: 1,
    reload: noop,
    setParams: anoop,
    subscribe: () => noop,
  });

export default function createPotionItemStateProvider<
  M,
  IS,
  S: PotionService<M, IS, $AllowAny>,
>(
  service: S,
  defaultParams: GetType<IS> | void,
): PotionItemStateProviderType<M, IS, S> {
  const PotionItemStateContext = createPotionItemStateContext<M, IS, S>(
    service,
  );
  const initialLoader = () => service.fetchMore(defaultParams || {});
  return {
    PotionItemStateProvider: ({ children }): React$Node => {
      const [firstFetch, firstCount, firstHasNext] = React.useMemo(() => {
        return suspendFetcher(initialLoader);
      }, []);
      const [data, setData] = useState<List<M>>(firstFetch);
      const [page, setPage] = useState<number>(1);
      const [count, setCount] = useState<number>(firstCount);
      const [hasNext, setHasNext] = useState<boolean>(firstHasNext);
      const [params, setParams] = useState();
      const [subscribers, setSubscribers] = useState([]);

      const subscribe = React.useCallback(
        (callback: (M | void, M | void) => void) => {
          setSubscribers(prev => [...prev, callback]);
          return () =>
            setSubscribers(prev => prev.filter(cb => cb !== callback));
        },
        [],
      );

      service.setItemUpdateCallback(
        React.useCallback(
          (oldItem: M | void, newItem: M | void) => {
            if (oldItem === undefined && newItem !== undefined) {
              /* TODO: this currently breaks sorting when item gets removed and
               * re-added to the list (or a completely new item is added).
               * The specific usage to sort the items accordingly so far, although
               * some way could be introduced to make it better because currently
               * there's no way to make sure client and server sortings will
               * yield the same result. */
              setData(prevItems => prevItems.push(newItem));
              setCount(prev => prev + 1);
            } else if (oldItem !== undefined && newItem === undefined) {
              setData(prevItems =>
                prevItems.delete(prevItems.indexOf(oldItem)),
              );
              setCount(prev => prev - 1);
            } else if (oldItem !== undefined && newItem !== undefined) {
              setData(prevItems =>
                prevItems.set(prevItems.indexOf(oldItem), newItem),
              );
            } else {
              invariant(false, 'Should not be here');
            }
            subscribers.forEach(cb => cb(oldItem, newItem));
          },
          [subscribers],
        ),
      );

      const loadNext = React.useCallback((): Promise<void> => {
        const newPage = page + 1;
        return service
          .fetchMore(
            params
              ? { ...defaultParams, ...params, page: newPage }
              : { ...defaultParams, page: newPage },
          )
          .then(result => {
            const [, newCount, _hasNext] = result;
            setHasNext(_hasNext);
            setCount(newCount);
            setPage(newPage);
          });
      }, [page, params]);

      const _setParams = (newParams: GetType<IS>): Promise<void> => {
        setParams(newParams);
        setPage(1);
        return service
          .get({ ...defaultParams, ...newParams })
          .then(([result, newCount, _hasNext]) => {
            setData(result);
            setHasNext(_hasNext);
            setCount(newCount);
          });
      };

      const reload = (): void => {
        _setParams(params || {});
      };

      const value = {
        count,
        data,
        hasNext,
        loadNext,
        page,
        reload,
        service,
        subscribe,
        setParams: _setParams,
      };
      return (
        <PotionItemStateContext.Provider value={value}>
          {children}
        </PotionItemStateContext.Provider>
      );
    },
    usePotionItemStateContext: () => useContext(PotionItemStateContext),
  };
}
