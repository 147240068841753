// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function ChevronLeft(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      height="24"
      version="1.1"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.088 12 18.226 1.862c0.426 -0.426 0.426 -1.117 0 -1.543s-1.117 -0.426 -1.543 0L5.774 11.229a1.09 1.09 0 0 0 -0.32 0.771 1.091 1.091 0 0 0 0.32 0.771l10.909 10.909c0.213 0.213 0.492 0.32 0.771 0.32s0.558 -0.106 0.771 -0.32c0.426 -0.426 0.426 -1.117 0 -1.543z"
        fill="currentColor"
      />
    </svg>
  );
}
