// @flow
import * as React from 'react';
import classNames from 'classnames';

import * as Zen from 'lib/Zen';
import AuthorizationService from 'services/AuthorizationService';
import ConfigurationService, {
  CONFIGURATION_KEY,
} from 'services/ConfigurationService';
import CreateDashboardModal from 'components/common/CreateDashboardModal';
import DashboardService from 'services/DashboardBuilderApp/DashboardService';
import DashboardsFlyout from 'components/Navbar/DashboardsFlyout';
import DirectoryService from 'services/DirectoryService';
import Dropdown from 'components/ui/Dropdown';
import HelpButton from 'components/Navbar/HelpButton';
import HypertextLink from 'components/ui/HypertextLink';
import I18N from 'lib/I18N';
import InfoTooltip from 'components/ui/InfoTooltip';
import NavigationDropdown from 'components/Navbar/NavigationDropdown';
import Popover from 'components/ui/Popover';
import getCaseManagementEnabledStatus from 'services/CaseManagementService/getCaseManagementEnabledStatus';
import {
  ALERTS_PERMISSIONS,
  DASHBOARD_PERMISSIONS,
  RESOURCE_TYPES,
  SITE_PERMISSIONS,
} from 'services/AuthorizationService/registry';
import {
  asButton,
  asDropdownOption,
  addLocaleLabel,
} from 'components/Navbar/util';
import { autobind } from 'decorators';
import { localizeUrl, onLinkClicked } from 'util/util';
import type DashboardMeta from 'models/core/Dashboard/DashboardMeta';

const isPlatformSuspended = () => window.__JSON_FROM_BACKEND.ui.suspended;

type Props = {
  buildTag: string | void,
  dataUpdate: {
    titleName: React.Node,
    tooltipText: string,
    value: string | void,
  },
  isAuthenticated: boolean,
  moreOptionsCount: number,
  username: string | void,
  visibleName: string,
};

type State = {
  areDashboardsLoading: boolean,
  canCreateDashboards: boolean,
  canUploadData: boolean,
  canViewAlertsPage: boolean,
  canViewCaseManagement: boolean,
  canViewCatalogSetup: boolean,
  canViewDataCatalog: boolean,
  canViewDataQuality: boolean,
  canViewEntityMatching: boolean,
  canViewQueryForm: boolean,

  // the slug for the case management homepage dashboard (if there is one)
  caseManagementDashboardSlug: string | void,
  dashboards: Zen.Array<DashboardMeta>,
  isAdmin: boolean,
  isCaseManagementEnabled: boolean,
  isCrispEnabled: boolean,
  showCreateDashboardModal: boolean,
  showDashboardsFlyout: boolean,
};

// Mapping from state variable to the permission and resourceType that need to
// be checked with the authorization API.
const STATE_TO_AUTH = {
  canCreateDashboards: {
    permission: DASHBOARD_PERMISSIONS.CREATE,
    resourceType: RESOURCE_TYPES.DASHBOARD,
  },
  canUploadData: {
    permission: SITE_PERMISSIONS.CAN_UPLOAD_DATA,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewAlertsPage: {
    permission: ALERTS_PERMISSIONS.CREATE,
    resourceType: RESOURCE_TYPES.ALERT,
  },
  canViewCaseManagement: {
    permission: SITE_PERMISSIONS.VIEW_CASE_MANAGEMENT,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewCatalogSetup: {
    permission: SITE_PERMISSIONS.CAN_VIEW_FIELD_SETUP,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewDataCatalog: {
    permission: SITE_PERMISSIONS.CAN_VIEW_DATA_CATALOG,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewDataQuality: {
    permission: SITE_PERMISSIONS.VIEW_DATA_QUALITY,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewEntityMatching: {
    permission: SITE_PERMISSIONS.CAN_VIEW_ENTITY_MATCHING,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewQueryForm: {
    permission: SITE_PERMISSIONS.VIEW_QUERY_FORM,
    resourceType: RESOURCE_TYPES.SITE,
  },
  isAdmin: {
    permission: SITE_PERMISSIONS.VIEW_ADMIN_PAGE,
    resourceType: RESOURCE_TYPES.SITE,
  },
};

const ALERTS_URL = '/alerts';
const CASE_MANAGEMENT_URL = '/case-management';
const DATA_QUALITY_URL = '/data-quality';

function onSelection(
  value: (SyntheticEvent<HTMLElement>) => void,
  e: SyntheticEvent<HTMLElement>,
) {
  // The value stored is an onClick event we want to use.
  value(e);
}

export default class DesktopNavbar extends React.PureComponent<Props, State> {
  state: State = {
    areDashboardsLoading: true,
    canCreateDashboards: false,
    canUploadData: false,
    canViewAlertsPage: false,
    canViewCaseManagement: false,
    canViewCatalogSetup: false,
    canViewDataCatalog: false,
    canViewDataQuality: false,
    canViewEntityMatching: false,
    canViewQueryForm: false,
    caseManagementDashboardSlug: undefined,
    dashboards: Zen.Array.create(),
    isAdmin: false,
    isCaseManagementEnabled: false,
    isCrispEnabled: false,
    showCreateDashboardModal: false,
    showDashboardsFlyout: false,
  };

  _dashboardsButtonRef: $ElementRefObject<'div'> = React.createRef();

  componentDidMount() {
    this.initializeDashboardsAndPermissions();

    // NOTE(stephen): Do not put any permissions detection code inside this
    // function! Permissions (and configuration) checks require a user to be
    // authenticated. `initializeDashboardsAndPermissions` handles this. Place
    // your permission loading code there.
  }

  @autobind
  openCreateDashboardModal() {
    this.setState({
      showCreateDashboardModal: true,
      showDashboardsFlyout: false,
    });
  }

  @autobind
  closeCreateDashboardModal() {
    this.setState({ showCreateDashboardModal: false });
  }

  @autobind
  openDashboardsFlyout() {
    this.setState({ showDashboardsFlyout: true });
    this.updateDashboardList();
  }

  @autobind
  closeDashboardsFlyout() {
    this.setState({ showDashboardsFlyout: false });
  }

  initializeDashboardsAndPermissions() {
    // If the user is authenticated or public access is enabled, we can
    // initialize the dashboards and user permissions.
    const { isAuthenticated } = this.props;

    if (isAuthenticated && !isPlatformSuspended()) {
      this.updateDashboardList();
      AuthorizationService.isAuthorizedMulti(
        Object.keys(STATE_TO_AUTH).map(k => STATE_TO_AUTH[k]),
      ).then(authorizations => {
        // NOTE(stephen): n^2 loop is ok here because the number of
        // authorization checks we issue is small.
        const newState = {};
        Object.keys(STATE_TO_AUTH).forEach(stateKey => {
          const { permission, resourceType } = STATE_TO_AUTH[stateKey];
          authorizations.some(authorizationResponse => {
            if (
              authorizationResponse.permission === permission &&
              authorizationResponse.resourceType === resourceType
            ) {
              newState[stateKey] = authorizationResponse.authorized;
              return true;
            }
            return false;
          });
        });
        this.setState(newState);
      });

      // We cannot check configuration values when the user is not logged in,
      // even if the site has public accessibility.
      // TODO(stephen): God this is a mess. Find someone to help clean it up.
      if (isAuthenticated) {
        getCaseManagementEnabledStatus().then(isEnabled => {
          this.setState({ isCaseManagementEnabled: isEnabled });
        });

        ConfigurationService.getConfiguration(
          CONFIGURATION_KEY.CASE_MANAGEMENT_HOME_PAGE_DASHBOARD,
        ).then(homepage => {
          this.setState({
            caseManagementDashboardSlug: homepage.value(),
          });
        });

        ConfigurationService.getConfiguration(
          CONFIGURATION_KEY.CRISP_ENABLED,
        ).then(setting => {
          this.setState({ isCrispEnabled: setting.value() });
        });
      }
    }
  }

  updateDashboardList() {
    this.setState({ areDashboardsLoading: true });
    DashboardService.getDashboards().then(dashboards => {
      this.setState({
        areDashboardsLoading: false,
        dashboards: Zen.Array.create(dashboards),
      });
    });
  }

  @autobind
  onUpdateDashboardIsFavorite(dashboard: DashboardMeta, isFavorite: boolean) {
    const newDashboard = dashboard.isFavorite(isFavorite);

    this.setState(
      prevState => {
        const index = prevState.dashboards.findIndex(
          currDashboard => currDashboard.slug() === dashboard.slug(),
        );
        const newDashboards = prevState.dashboards.set(index, newDashboard);

        return { dashboards: newDashboards };
      },
      () => DashboardService.markDashboardAsFavorite(dashboard, isFavorite),
    );
  }

  @autobind
  maybeRenderAnalyzeLink(
    isDropdownOption?: boolean = false,
    showDropdownIcon?: boolean = true,
  ): React.Node {
    const { isAuthenticated } = this.props;
    const { canViewQueryForm } = this.state;
    if (!isAuthenticated || !canViewQueryForm) {
      return null;
    }

    const url = localizeUrl('/advanced-query');
    const isActive = window.location.pathname.includes(url);
    const iconClassName = showDropdownIcon ? 'glyphicon glyphicon-search' : '';

    if (isDropdownOption) {
      return asDropdownOption(
        e => onLinkClicked(url, e),
        I18N.text('Analyze'),
        iconClassName,
      );
    }

    return (
      <HypertextLink
        key="analyze"
        onClick={e => onLinkClicked(url, e)}
        url={url}
      >
        {asButton(() => undefined, I18N.textById('Analyze'), isActive)}
      </HypertextLink>
    );
  }

  maybeRenderCreateDashboardModal(): React.Node {
    if (this.state.showCreateDashboardModal) {
      return (
        <CreateDashboardModal
          onRequestClose={this.closeCreateDashboardModal}
          show={this.state.showCreateDashboardModal}
        />
      );
    }

    return null;
  }

  @autobind
  maybeRenderDashboardsFlyoutButton(): React.Node {
    const {
      areDashboardsLoading,
      canCreateDashboards,
      caseManagementDashboardSlug,
      dashboards,
      showDashboardsFlyout,
    } = this.state;

    if (this.props.isAuthenticated && !isPlatformSuspended()) {
      const locationPath = window.location.pathname;
      const isCaseManagementDashboard =
        caseManagementDashboardSlug &&
        locationPath.includes(caseManagementDashboardSlug);

      const isActive =
        window.location.pathname.includes('/dashboard') &&
        !isCaseManagementDashboard;

      const className = classNames('dashboards-dropdown-button', {
        'navbar-item--active': isActive,
      });

      return (
        <div
          key="dashboards-dropdown"
          ref={this._dashboardsButtonRef}
          className={className}
        >
          {asButton(
            this.openDashboardsFlyout,
            I18N.textById('Dashboards'),
            false,
            null,
            'navbar-dashboards-flyout-button',
          )}
          <Popover
            anchorElt={this._dashboardsButtonRef.current}
            isOpen={showDashboardsFlyout}
            onRequestClose={this.closeDashboardsFlyout}
            windowEdgeThresholds={{
              bottom: 0,
              left: 0,
              right: 0,
              // Prevents the popover overlaying the navbar on small screens.
              top: 60,
            }}
          >
            <DashboardsFlyout
              activeUsername={DirectoryService.getActiveUsername()}
              canCreateDashboards={canCreateDashboards}
              dashboards={dashboards}
              dashboardsLoaded={!areDashboardsLoading}
              onNewDashboardClick={this.openCreateDashboardModal}
              onUpdateDashboardIsFavorite={this.onUpdateDashboardIsFavorite}
            />
          </Popover>
        </div>
      );
    }
    return null;
  }

  @autobind
  maybeRenderCaseManagementLink(
    isDropdownOption?: boolean = false,
    showDropdownIcon?: boolean = true,
  ): React.Node {
    const { isAuthenticated } = this.props;
    const {
      canViewCaseManagement,
      caseManagementDashboardSlug,
      isCaseManagementEnabled,
    } = this.state;
    const { caseManagementAppOptions } = window.__JSON_FROM_BACKEND;
    if (
      isAuthenticated &&
      canViewCaseManagement &&
      isCaseManagementEnabled &&
      caseManagementAppOptions.showInNavbar
    ) {
      const url = localizeUrl(CASE_MANAGEMENT_URL);
      const locationPath = window.location.pathname;
      const isActive =
        locationPath.includes(url) ||
        (caseManagementDashboardSlug &&
          locationPath.includes(`dashboard/${caseManagementDashboardSlug}`));
      const iconClassName = showDropdownIcon
        ? 'glyphicon glyphicon-folder-open'
        : '';

      if (isDropdownOption) {
        return asDropdownOption(
          e => onLinkClicked(url, e),
          // TODO(pablo): the CMA title should be handled through a config,
          // not hardcoded in the JSON_FROM_BACKEND
          caseManagementAppOptions.navbarTitle,
          iconClassName,
        );
      }

      return (
        <HypertextLink
          key="case-management"
          onClick={e => onLinkClicked(url, e)}
          url={url}
        >
          {asButton(
            () => undefined,
            caseManagementAppOptions.navbarTitle,
            isActive,
          )}
        </HypertextLink>
      );
    }
    return null;
  }

  @autobind
  maybeRenderAlertsLink(
    isDropdownOption?: boolean = false,
    showDropdownIcon?: boolean = true,
  ): React.Node {
    if (!this.state.canViewAlertsPage) {
      return null;
    }

    const { alertsEnabled } = window.__JSON_FROM_BACKEND;
    if (!alertsEnabled) {
      return null;
    }

    const url = localizeUrl(ALERTS_URL);
    const isActive = window.location.pathname.includes(url);
    const iconClassName = showDropdownIcon ? 'glyphicon glyphicon-search' : '';

    if (isDropdownOption) {
      return asDropdownOption(
        e => onLinkClicked(url, e),
        I18N.textById('Alerts'),
        iconClassName,
      );
    }

    return (
      <HypertextLink
        key="alerts"
        onClick={e => onLinkClicked(url, e)}
        url={url}
      >
        {asButton(() => undefined, I18N.textById('Alerts'), isActive)}
      </HypertextLink>
    );
  }

  maybeRenderMoreOptionsDropdown(
    children: $ReadOnlyArray<?React.Element<
      Class<Dropdown.Option<(SyntheticEvent<HTMLElement>) => void>>,
    >>,
  ): React.Node {
    const showMoreOptionsDropdown =
      this.props.moreOptionsCount > 0 && children.length > 0;

    if (!showMoreOptionsDropdown || !this.props.isAuthenticated) {
      return null;
    }

    return (
      <Dropdown
        buttonClassName="navbar-item"
        caretType={Dropdown.CaretTypes.MENU}
        defaultDisplayContent={I18N.text('More')}
        displayCurrentSelection={false}
        hideCaret={false}
        menuAlignment={Dropdown.Alignments.RIGHT}
        menuClassName="navbar-dropdown-menu navbar-more-links__menu"
        onSelectionChange={onSelection}
        value={undefined}
      >
        {children}
      </Dropdown>
    );
  }

  @autobind
  maybeRenderDataQualityLink(isDropdownOption?: boolean = false): React.Node {
    if (
      !this.state.canViewDataQuality ||
      !this.props.isAuthenticated ||
      !window.__JSON_FROM_BACKEND.ui.enableDataQualityLab
    ) {
      return null;
    }

    const url = localizeUrl(DATA_QUALITY_URL);
    const isActive = window.location.pathname.includes(url);

    if (isDropdownOption) {
      return asDropdownOption(
        e => onLinkClicked(localizeUrl(DATA_QUALITY_URL), e),
        I18N.text('Data Quality'),
        '',
        null,
      );
    }

    return (
      <HypertextLink
        key="data-quality"
        onClick={e => onLinkClicked(url, e)}
        url={url}
      >
        {asButton(() => undefined, I18N.textById('Data Quality'), isActive)}
      </HypertextLink>
    );
  }

  renderDropdownTitleItem(
    titleName: React.Node,
    value: string,
    tooltipText?: string,
  ): React.Node {
    return (
      <div className="navbar-dropdown-summary__item">
        <div className="navbar-dropdown-summary__title-name">
          {titleName}
          {tooltipText ? <InfoTooltip text={tooltipText} /> : null}
        </div>
        <div className="navbar-dropdown-summary__title-value">{value}</div>
      </div>
    );
  }

  renderDropdownSummaryTitle(): React.Element<typeof Dropdown.Option> {
    const { buildTag, dataUpdate, username } = this.props;

    const userStatus = username
      ? this.renderDropdownTitleItem(I18N.text('Logged in as'), username)
      : null;
    const lastDataUpdate = dataUpdate.value
      ? this.renderDropdownTitleItem(
          dataUpdate.titleName,
          dataUpdate.value,
          dataUpdate.tooltipText,
        )
      : null;
    const versionInfo = buildTag
      ? this.renderDropdownTitleItem(I18N.text('Build version'), buildTag)
      : null;

    return (
      <Dropdown.Option
        key="summary"
        className="navbar-dropdown-summary__title"
        disableSearch
        value="__unused__"
        wrapperClassName="navbar-dropdown-summary"
      >
        {userStatus}
        {lastDataUpdate}
        {versionInfo}
      </Dropdown.Option>
    );
  }

  renderNavigationDropdown(): React.Node {
    const { isAuthenticated, visibleName } = this.props;
    const { locales, ui } = window.__JSON_FROM_BACKEND;

    return (
      <NavigationDropdown
        isAdmin={this.state.isAdmin}
        isAuthenticated={isAuthenticated}
        locales={addLocaleLabel(locales)}
        showCatalogSetup={this.state.canViewCatalogSetup}
        showDataCatalog={this.state.canViewDataCatalog}
        showDataUpload={this.state.canUploadData}
        showEntityMatching={this.state.canViewEntityMatching}
        showLocales={ui.showLocalePicker}
        visibleName={visibleName}
      >
        {this.renderDropdownSummaryTitle()}
      </NavigationDropdown>
    );
  }

  render(): React.Node {
    const { moreOptionsCount } = this.props;
    const { isCrispEnabled } = this.state;
    let leftAlignedLinks = [
      this.maybeRenderAnalyzeLink,
      this.maybeRenderDashboardsFlyoutButton,
      this.maybeRenderDataQualityLink,
      this.maybeRenderCaseManagementLink,
      this.maybeRenderAlertsLink,
    ];

    // filter out render functions that are null for a deployment
    leftAlignedLinks = leftAlignedLinks.filter(f => f());
    let moreDropdownLinks = [];

    if (moreOptionsCount > 0) {
      // ensure that a minimum of 2 links are displayed before the more dropdown
      const leftAlignedLinksCount = Math.max(
        2,
        leftAlignedLinks.length - moreOptionsCount,
      );

      // only add items to more dropdown if more than 1 item exists
      if (leftAlignedLinks.length - leftAlignedLinksCount > 1) {
        moreDropdownLinks = leftAlignedLinks.slice(leftAlignedLinksCount);
        leftAlignedLinks = leftAlignedLinks.slice(0, leftAlignedLinksCount);
      }
    }

    return (
      <React.Fragment>
        <div className="navbar-items">
          <div className="navbar-items__left">
            {leftAlignedLinks.map(renderLink => renderLink())}
            {this.maybeRenderMoreOptionsDropdown(
              // $FlowFixMe[extra-arg] - this is not a good pattern
              // $FlowFixMe[incompatible-call] - this is not a good pattern
              // $FlowFixMe[incompatible-exact] - this is not a good pattern
              moreDropdownLinks.map(renderLink => renderLink(true, false)),
            )}
          </div>
          <div className="navbar-items__right">
            {isCrispEnabled && <HelpButton />}
            {this.renderNavigationDropdown()}
          </div>
        </div>
        {this.maybeRenderCreateDashboardModal()}
      </React.Fragment>
    );
  }
}
