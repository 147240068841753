// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import invariant from 'invariant';

import ConfigurationService, {
  CONFIGURATION_KEY,
} from 'services/ConfigurationService';
import DesktopNavbar from 'components/Navbar/DesktopNavbar';
import I18N from 'lib/I18N';
import MobileNavbar from 'components/Navbar/MobileNavbar';
import Moment from 'models/core/wip/DateTime/Moment';
import { NAVBAR_ID } from 'components/Navbar/constants';
import { VENDOR_SCRIPTS } from 'vendor/registry';
import { autobind } from 'decorators';
import { getEthiopianDateLabel } from 'util/dateUtil';
import {
  isMobileBrowser,
  isUnoptimizedForMobile,
  extractAcronym,
  IsMobileWrapper,
} from 'components/Navbar/util';
import { localizeUrl, onLinkClicked } from 'util/util';

const isPlatformSuspended = () => window.__JSON_FROM_BACKEND.ui.suspended;

type DefaultProps = {
  flagClass: string,
  lastDataUpdate: ?string,
  logoPath: string,
  visibleName: string,
};

type Props = {
  ...DefaultProps,
  fullPlatformName: string,
  isAuthenticated: boolean,
  username: string,
};

type State = {
  isValidationMode: boolean,
  moreOptionsCount: number,
  showAcronym: boolean,
};

// The width to switch from full deployment name to an acronym
const SHOW_ACRONYM_WIDTH = 320; // 320 small mobile screens

// The width to consider putting the last 2 items in the more dropdown
const HIDE_TWO_WIDTH = 1050;

// The width to consider putting the last 3 items in the more dropdown
const HIDE_THREE_WIDTH = 800;

// NOTE(stephen): We know that the document body will always be nonnull.
// Cast it to a non-null type so that Flow is happy.
const DOCUMENT_BODY = ((document.body: $Cast): HTMLBodyElement);

function showAcronym() {
  return DOCUMENT_BODY.clientWidth < SHOW_ACRONYM_WIDTH;
}

function getMoreOptionsCount() {
  const deviceWidth = DOCUMENT_BODY.clientWidth;

  if (deviceWidth <= HIDE_THREE_WIDTH) {
    return 3;
  }

  if (deviceWidth <= HIDE_TWO_WIDTH) {
    return 2;
  }

  return 0;
}

export default class Navbar extends React.PureComponent<Props, State> {
  static defaultProps: DefaultProps = {
    flagClass: '',
    lastDataUpdate: null,
    logoPath: '',
    visibleName: '',
  };

  static renderToDOM(elementId?: string = 'header') {
    const elt: ?HTMLElement = document.getElementById(elementId);
    invariant(elt, `Element ID does not exist: ${elementId}`);

    const { ui, user } = window.__JSON_FROM_BACKEND;
    const { firstName, isAuthenticated, lastName, username } = user;
    const visibleName = firstName || lastName;
    const { flagClass, fullPlatformName, lastDataUpdate, logoPath } = ui;
    ReactDOM.render(
      <Navbar
        flagClass={flagClass}
        fullPlatformName={fullPlatformName}
        isAuthenticated={isAuthenticated}
        lastDataUpdate={lastDataUpdate}
        logoPath={logoPath}
        username={username}
        visibleName={visibleName}
      />,
      elt,
    );
  }

  state: State = {
    isValidationMode: false,
    moreOptionsCount: getMoreOptionsCount(),
    showAcronym: showAcronym(),
  };

  componentDidMount() {
    if (this.props.isAuthenticated && !isPlatformSuspended()) {
      ConfigurationService.getConfiguration(
        CONFIGURATION_KEY.VALIDATION_MODE,
      ).then(setting => {
        this.setState({ isValidationMode: setting.value() });
        if (setting.value()) {
          window.toastr.warning(
            I18N.text(
              'The site is in validation mode. Exit validation mode in the Admin App.',
            ),
          );
        }
      });
    }

    this.maybeShowMobileOptimizationDisclaimer();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('offline', this.handleOffline);
    window.addEventListener('online', this.handleOnline);
    // Load toastr after the component mounts so that we do not delay the
    // rendering of the navbar.
    // TODO(stephen): It'd be nice to get rid of this preload dependency
    // altogether, but I have a feeling that other uses of toastr around the
    // site will break because they don't check if it is loaded.
    VENDOR_SCRIPTS.toastr.load();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('offline', this.handleOffline);
    window.removeEventListener('online', this.handleOnline);
  }

  maybeShowMobileOptimizationDisclaimer() {
    const path = window.location.pathname;

    if (isMobileBrowser() && isUnoptimizedForMobile(path)) {
      window.toastr.warning(
        I18N.text(
          'This page is not optimized for use on mobile.',
          'notMobileOptimized',
        ),
      );
    }
  }

  @autobind
  handleResize() {
    this.setState({
      moreOptionsCount: getMoreOptionsCount(),
      showAcronym: showAcronym(),
    });
  }

  handleOffline() {
    window.toastr.clear();

    // prevent toast from closing based on timeouts
    window.toastr.options.timeOut = 0;
    window.toastr.options.extendedTimeOut = 0;
    window.toastr.options.closeButton = true;
    window.toastr.error(
      I18N.text(
        'There is no Internet connection, please try reconnecting.',
        'offlineError',
      ),
    );
  }

  handleOnline() {
    // restore the defaults. See toastr source code for defaults clarity
    // https://github.com/CodeSeven/toastr/blob/master/toastr.js
    window.toastr.options.timeOut = 5000;
    window.toastr.options.extendedTimeOut = 1000;
    window.toastr.options.closeButton = false;
    window.toastr.clear();
  }

  _formatLastDataUpdate(lastDataUpdate: string): string {
    let timestamp = lastDataUpdate;
    if (!timestamp.endsWith('Z')) {
      timestamp += 'Z';
    }

    if (window.__JSON_FROM_BACKEND.enableEtDateSelection) {
      const time = new Date(timestamp).toLocaleString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${getEthiopianDateLabel(new Moment(timestamp))} ${time}`;
    }

    return new Date(timestamp).toLocaleString(undefined, {
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }

  onHomeClicked(e: SyntheticMouseEvent<HTMLDivElement>) {
    onLinkClicked(localizeUrl('/overview'), e);
  }

  renderTitleContainer(): React.Node {
    const { flagClass, fullPlatformName, logoPath } = this.props;
    let platformName = fullPlatformName;

    if (this.state.showAcronym) {
      platformName = extractAcronym(fullPlatformName);
    }

    const logo = logoPath ? (
      <img alt="logo" src={logoPath} />
    ) : (
      <i className={`flag ${flagClass}`} />
    );

    // HACK(nina): $GatesMalariaDemoHack - Hide platform name from nav bar
    const platformTitle =
      window.__JSON_FROM_BACKEND.deploymentName ===
      'gates_malaria' ? undefined : (
        <span className="navbar-title-container__title">{platformName}</span>
      );

    return (
      <button
        className="navbar-title-container"
        onClick={this.onHomeClicked}
        type="button"
      >
        <span className="navbar-title-container__logo">{logo}</span>
        {platformTitle}
      </button>
    );
  }

  render(): React.Node {
    // HACK(nina): $GatesMalariaDemoHack - change navbar style
    const className = classNames('navbar', 'hide-in-screenshot', {
      'navbar-gates-malaria':
        window.__JSON_FROM_BACKEND.deploymentName === 'gates_malaria',
    });

    const { isAuthenticated, lastDataUpdate, username, visibleName } =
      this.props;
    const { moreOptionsCount } = this.state;
    const { isAdmin } = window.__JSON_FROM_BACKEND.user;
    const { buildTag } = window.__JSON_FROM_BACKEND;

    const formattedBuildTag = isAdmin && buildTag ? buildTag : undefined;
    const refreshTitle = this.state.isValidationMode ? (
      <span className="navbar-dropdown-summary__warning-title">
        {I18N.text('Validation mode')}
      </span>
    ) : (
      I18N.text('Last data refresh')
    );
    const dataUpdate = {
      titleName: refreshTitle,
      tooltipText: this.state.isValidationMode
        ? I18N.text(
            "Validation mode is used when new data is being QA'd on staging. The data may not be the latest version and may have bugs. You can exit validation mode in the Admin App Site Configuration tab.",
          )
        : I18N.text(
            'Last data refresh refers to when the data pipeline started to run. This is typically when data starts being fetched from source systems. Certain data sources may be scheduled to fetch data on a certain day and time and would not align to this last data refresh time. Please contact an Administrator with any questions.',
          ),
      value: lastDataUpdate
        ? this._formatLastDataUpdate(lastDataUpdate)
        : undefined,
    };
    const formattedUsername = isAuthenticated ? username : undefined;

    return (
      <div className={className} id={NAVBAR_ID}>
        {this.renderTitleContainer()}
        <IsMobileWrapper>
          {isMobile =>
            isMobile ? (
              <MobileNavbar
                buildTag={formattedBuildTag}
                dataUpdate={dataUpdate}
                isAuthenticated={isAuthenticated}
                username={formattedUsername}
              />
            ) : (
              <DesktopNavbar
                buildTag={formattedBuildTag}
                dataUpdate={dataUpdate}
                isAuthenticated={isAuthenticated}
                moreOptionsCount={moreOptionsCount}
                username={formattedUsername}
                visibleName={visibleName}
              />
            )
          }
        </IsMobileWrapper>
      </div>
    );
  }
}
