// @flow

import React, { useState } from 'react';

import Group from 'components/ui/Group';
import Icon from 'components/ui/Icon';
import InputText from 'components/ui/InputText';
import LabelWrapper from 'components/ui/LabelWrapper';

type FormInputProps = {
  disabled?: boolean,
  error?: boolean,
  errorMsg?: string,
  id: string,
  labelText: string,
  onChange: (value: string) => void,
  type: 'text' | 'password' | 'email' | 'number',
  value: string,
};

const FormInput = ({
  disabled = false,
  error = false,
  errorMsg,
  id,
  labelText,
  onChange,
  type,
  value,
}: FormInputProps): React$Node => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const inputType = type === 'password' && isPasswordVisible ? 'text' : type;

  return (
    <Group.Vertical
      className={`${error || errorMsg ? 'has-error' : ''}`}
      firstItemStyle={{ marginBottom: 0 }}
    >
      <LabelWrapper htmlFor={id} label={labelText}>
        <div className="form-input__wrapper">
          <InputText
            className="form-control"
            disabled={disabled}
            id={id}
            onChange={onChange}
            type={inputType}
            value={value}
          />
          {type === 'password' && (
            <Icon
              className="form-input__toggle-password-icon"
              onClick={togglePasswordVisibility}
              type={isPasswordVisible ? 'eye-open' : 'eye-close'} // Toggle between 'eye-open' and 'eye-close' icons
            />
          )}
        </div>
      </LabelWrapper>
      {errorMsg && <div className="auth-validation">{errorMsg}</div>}
    </Group.Vertical>
  );
};

export default FormInput;
