// @flow
import * as React from 'react';
import classNames from 'classnames';

import normalizeARIAName from 'components/ui/util/normalizeARIAName';
import type { StyleObject } from 'types/jsCore';

type Props = {
  /**
   * The accessibility name for this link. If none is specified, we will
   * use the link contents if it is a string or number.
   */
  ariaName?: string,

  children: React.Node,

  /** Optional additional class name to add to the link */
  className?: string,

  /** Highlight text in blue like a traditional hyperlink */
  highlight?: boolean,

  /**
   * Gets called when the link is clicked.
   * @param {SyntheticEvent.button} event The click event
   */
  onClick?: (event: SyntheticMouseEvent<HTMLButtonElement>) => void,

  /** Optional styling for link */
  style?: StyleObject,

  underline?: boolean,

  /** The URL that this link should navigate to */
  url: string,
};

/** A basic wrapper for hypertext links. */
export default function HypertextLink({
  ariaName,
  children,
  className = '',
  highlight = false,
  onClick,
  style,
  underline = false,
  url,
}: Props): React.Element<'a'> {
  const ariaNameToUse =
    ariaName ||
    (typeof children === 'string' || typeof children === 'number'
      ? String(children)
      : undefined);

  const allClassNames = classNames('zen-hypertext-link', className, {
    'u-highlighted-text': highlight,
    'u-underline': underline,
  });

  return (
    <a
      aria-label={normalizeARIAName(ariaNameToUse)}
      className={allClassNames}
      href={url}
      onClick={onClick}
      style={style}
    >
      {children}
    </a>
  );
}
