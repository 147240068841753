// @flow
import * as React from 'react';
import MurmurHash3 from 'imurmurhash';
import 'url-search-params-polyfill';

import * as Zen from 'lib/Zen';
import I18N from 'lib/I18N';
import type { QueryFilterItem } from 'models/core/wip/QueryFilterItem/types';

export function noop() {}

export function anoop(): Promise<void> {
  return new Promise(resolve => resolve());
}

export function maybeOpenNewTab(url: string, metaKey: boolean) {
  if (metaKey) {
    window.open(url, '_blank');
  } else {
    document.location.assign(url);
  }
}
type JSONSerializable =
  | string
  | number
  | boolean
  | null
  | void
  | Array<JSONSerializable>
  | { [key: string]: JSONSerializable };

type AsyncFunction<A, T> = (...args: A) => Promise<T>;

const functionCache: WeakMap<
  AsyncFunction<any, any>,
  Map<string, any>,
> = new WeakMap();

function updateCache(
  fetchingFunction: AsyncFunction<any, any>,
  cacheKey: string,
  value: mixed,
): void {
  const argsCache = functionCache.get(fetchingFunction) || new Map();
  argsCache.set(cacheKey, value);
  functionCache.set(fetchingFunction, argsCache);
}

/**
 * Invalidates the cache for the given fetching function.
 *
 * @param {fetchingFunction} fetchingFunction
 */
function invalidateCache(
  fetchingFunction: AsyncFunction<any, any>,
  cacheKey: string,
): void {
  const argsCache = functionCache.get(fetchingFunction);
  if (argsCache === undefined) return;
  argsCache.delete(cacheKey);
}

/**
 * This wrapper makes it possible for an API call to use React Suspense to handle
 * loading states.
 *
 * Note (enock): For now, we do not allow `args` to contain any objects. This is because
 * the same object may be passed in different order and its cacheKey will be different (undesired).
 * In case this is needed, contact Enock or Sergey to figure out a way to make this work cleanly.
 *
 * @param {*} fetchingFunction - The function that fetches data.
 * @param  {...JSONSerializable[]} args - A variable number of arguments that are JSON serializable.
 * @returns {*} The data returned from the fetching function.
 */
export function suspendFetcher<A: Array<JSONSerializable>, T>(
  fetchingFunction: AsyncFunction<A, T>,
  ...args: A
): T {
  const cacheKey = JSON.stringify(args);
  const argsCache = functionCache.get(fetchingFunction);
  const cached = argsCache && argsCache.get(cacheKey);
  if (!argsCache || !argsCache.has(cacheKey)) {
    const promise = fetchingFunction(...args).then(
      data => {
        updateCache(fetchingFunction, cacheKey, data);
      },
      error => {
        updateCache(fetchingFunction, cacheKey, error);
      },
    );

    updateCache(fetchingFunction, cacheKey, promise);

    throw promise;
  } else if (cached && (cached.then || cached instanceof Error)) {
    throw cached;
  }

  invalidateCache(fetchingFunction, cacheKey);

  // Disabling this flow error because this function should be able to return
  // undefined and null types, which should be valid <T> types.
  // $FlowIssue[incompatible-return]
  return cached;
}

/**
 * Function to extract a date filter value from a QueryFilterItem array.
 * Note(enock): Casting to `any` here because the CustomDateFilterValueItem is
 * not compatible with some other types in the QueryFilterItemMap.
 */
export function getDateFilterValue(
  itemsZenArray: Zen.Array<QueryFilterItem>,
): string {
  const dateFilter = (itemsZenArray.first(): any);
  const dateFilterValue = dateFilter
    ? dateFilter.displayValue()
    : I18N.text('No date filter');
  return `<date-filter>${dateFilterValue}</date-filter>`;
}

export const DATE_FILTER_REGEX: RegExp = /{Date}/g;

export const DATE_FILTER_REPLACE_REGEX: RegExp =
  /<date-filter>(.*?)\/date-filter>/g;

export const DATE_FORMAT = 'YYYY-MM-DD';

export function scrollWindowTo(scrollPx: number, smooth: boolean = false) {
  window.scroll({
    behavior: smooth ? 'smooth' : 'auto',
    top: scrollPx,
  });
}

let idCounter = 0;
// Generates a uniqueId for this browser session, basically in the same
// way that lodash does
export function uniqueId(): string {
  idCounter += 1;
  return idCounter.toString();
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// NOTE(all): func can either be a function or a function
// reference { current: func }
export function debounce<T: (...args: $ReadOnlyArray<empty>) => mixed>(
  func: { current: T } | T,
  wait: number,
  immediate?: boolean = false,
): T {
  let timeout;

  // $FlowIssue[incompatible-return] - this is totally fine
  return function debounced(...args) {
    const callback = typeof func === 'function' ? func : func.current;
    const later = () => {
      timeout = null;
      if (!immediate) callback(...args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) callback(...args);
  };
}

// Generate a UUID4 unique identifier.
export function uuid(): string {
  // Build a 128-bit random number to use as the UUID.
  const arr = new Uint8Array(16);
  window.crypto.getRandomValues(arr);

  // Unpack the random numbers and store as hex strings.
  const pieces = arr.reduce((acc, v, i) => {
    // Unpack an 8-bit number into two 4-bit pieces to convert to hex.
    let top = v >> 4; // eslint-disable-line no-bitwise
    const bottom = v & 0xf; // eslint-disable-line no-bitwise

    // Set the version number. This will set the value at position 13 of the
    // UUID.
    if (i === 6) {
      top = 0x4;
    } else if (i === 8) {
      // Set the variant bits at position 17 of the UUID.
      top = (top & 0x3) | 0x8; // eslint-disable-line no-bitwise
    }

    // Add the UUID's hyphens in the appropriate cadence.
    if (i === 4 || i === 6 || i === 8 || i === 10) {
      acc.push('-');
    }

    acc.push(top.toString(16));
    acc.push(bottom.toString(16));
    return acc;
  }, []);

  return pieces.join('');
}

function unloadHandler(event) {
  // Trigger a confirmation dialog warning about unsaved changes.
  event.preventDefault();

  // NOTE(david): This line shouldn't be needed as the spec only requires
  // event.preventDefault(). We include it as some browsers don't properly
  // implement the spec and IE will use the returnValue for the dialog text.
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  // eslint-disable-next-line no-param-reassign
  event.returnValue = 'Changes you made may not be saved.';
}

export function registerUnloadHandler() {
  if (!window.__JSON_FROM_BACKEND.IS_TEST) {
    window.addEventListener('beforeunload', unloadHandler);
  }
}

export function removeUnloadHandler() {
  window.removeEventListener('beforeunload', unloadHandler);
}

export function getQueryParam(queryParam: string): string | null {
  const params = new URLSearchParams(window.location.search);
  return params.get(queryParam);
}

export function localizeUrl(path: string): string {
  const { locale } = window.__JSON_FROM_BACKEND;
  const { defaultLocale } = window.__JSON_FROM_BACKEND.ui;

  if (locale && locale !== defaultLocale) {
    if (path.startsWith('/')) {
      return `/${locale}${path}`;
    }
    return `/${locale}/${path}`;
  }
  return path;
}

export function onLinkClicked(
  url: string,
  e?:
    | SyntheticMouseEvent<HTMLElement>
    | MouseEvent
    | { metaKey?: boolean, ... } = {},
  analyticsEvent?: string | void = undefined,
  analyticsProperties?: { [string]: mixed, ... } | void = undefined,
  openNewTab?: boolean = false,
): void {
  const openUrl = () => maybeOpenNewTab(url, e.metaKey || openNewTab);
  if (analyticsEvent === undefined) {
    openUrl();
    return;
  }

  analytics.track(analyticsEvent, analyticsProperties, undefined, openUrl);
}

export const IS_ZENYSIS_USER: boolean =
  window.__JSON_FROM_BACKEND.user &&
  window.__JSON_FROM_BACKEND.user.username &&
  window.__JSON_FROM_BACKEND.user.username.match(/^[\w\-.+]+@zenysis.com$/);

// Eng emails as of March 13, 2025
const HASHED_ENG_EMAILS: Set<number> = new Set([
  4003802687, // abby
  3852498370, // enock
  3041174330, // gian
  462761730, // ngoni
  1247507076, // sergey
  1549577164, // stephan
  2993548739, // sybrand
]);
// NOTE: This is not very secure and should only be used together with proper
// authorization checks on the backend.
export const IS_ENG_USER: boolean =
  window.__JSON_FROM_BACKEND.user &&
  window.__JSON_FROM_BACKEND.user.username &&
  HASHED_ENG_EMAILS.has(
    MurmurHash3(window.__JSON_FROM_BACKEND.user.username).result(),
  );

export const IS_HARMONY = window.__JSON_FROM_BACKEND.ui.isHarmony;

/**
 * Trigger a file download. This function supports two approaches:
 *    - The file is provided in a blob with a file name.
 *    - An api endpoint will trigger the file download.
 */
export function downloadFile(
  params: { dataBlob: Blob, fileName: string } | { endpoint: string },
): void {
  // Either create the url for the data blob or else use the endpoint url.
  const url = params.dataBlob
    ? window.URL.createObjectURL(params.dataBlob)
    : params.endpoint;

  // Construct the link element
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  if (params.fileName) {
    a.download = params.fileName;
  }

  // Add the link to the page and click it
  const documentBody = document.body;
  if (documentBody) {
    documentBody.appendChild(a);
    a.click();
    documentBody.removeChild(a);
  }

  // Clean up the object url if it was used.
  if (params.dataBlob) {
    window.URL.revokeObjectURL(url);
  }
}

/**
 * handleAuthRedirect function handles the redirection of the user after login or registration.
 * It gets the 'next' query parameter from the current URL.
 * If 'next' is present and its origin matches the current window's origin, it redirects to the 'next' URL.
 * If 'next' is present but its origin does not match the current window's origin, it redirects to the '/overview' page.
 * If 'next' is not present, it also redirects to the '/overview' page.
 */
export function handleAuthRedirect() {
  const next = getQueryParam('next'); // get the 'next' parameter

  if (next) {
    const nextUrl = new URL(next, window.location.href);
    if (nextUrl.origin === window.location.origin) {
      // Only navigate to the 'next' URL if it's on the same origin
      window.location.href = next;
    } else {
      // If the 'next' URL is not from your domain, redirect to a default location
      window.location.href = localizeUrl('/overview');
    }
  } else {
    window.location.href = localizeUrl('/overview');
  }
}

export function useInfiniteScrolling(
  hasNext: boolean,
  loadNext: () => Promise<void>,
) {
  const [isLoadingNext, setIsLoadingNext] = React.useState<boolean>(false);
  const maybeLoadMoreItems = React.useCallback(() => {
    const scrolledtoBottom =
      document.body !== null &&
      window.innerHeight + window.scrollY + 100 >= document.body.scrollHeight;
    if (scrolledtoBottom && hasNext && !isLoadingNext) {
      setIsLoadingNext(true);
      loadNext().then(() => setIsLoadingNext(false));
    }
  }, [hasNext, isLoadingNext, loadNext]);

  React.useEffect(() => {
    window.addEventListener('scroll', maybeLoadMoreItems);
    return () => window.removeEventListener('scroll', maybeLoadMoreItems);
  }, [maybeLoadMoreItems]);
}
