// @flow
import * as React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import Heading from 'components/ui/Heading';
import IconButton from 'components/ui/IconButton';
import { noop } from 'util/util';

type Props = {
  /** The ID of the active page */
  activePageId?: string,

  /** The class name to apply at the top level of the drawer */
  className?: string,

  /** Whether or not the drawer height fills the full screen. If false, then
   * the height is based on the content size. This only applies to portrait
   * mode on mobile devices as landscape is always full screen. */
  fullScreen?: boolean,

  onNavigateToPage?: (pageId: string) => void,

  /**
   * Event handler for when the drawer needs to close. This can be called in
   * 4 different ways:
   *
   * - when the modal X button is clicked
   * - when the user presses Escape
   * - when the user clicks on the background overlay
   */
  onRequestClose?: (SyntheticEvent<>) => void,

  // eslint-disable-next-line no-use-before-define
  pages: Array<React.Element<typeof Drawer.Page>>,
  show: boolean,
};

type PageProps = {
  children: React.Node,
  className?: string,
  id: string,
  // eslint-disable-next-line react/no-unused-prop-types
  title: string,
};

/**
 * A basic drawer component that can navigate between pages.
 *
 * It renders a header with a back button, a title, and a close button.
 * The body of the drawer displays the content of the active page.
 *
 * The drawer can be closed by clicking the close button, pressing Escape,
 * or clicking on the background overlay.
 */
const Drawer = ({
  activePageId = '',
  className = '',
  fullScreen = false,
  onNavigateToPage,
  onRequestClose = noop,
  pages = [],
  show,
}: Props): React.Node => {
  const handleBackClick = () => {
    if (pages.length > 0 && onNavigateToPage) {
      onNavigateToPage(pages[0].props.id);
    }
  };

  const maybeRenderDrawerHeader = (): React.Node => {
    const currentPage = pages.find(
      (page: React.Element<typeof Drawer.Page>) =>
        page.props.id === activePageId,
    );

    const backBtnClass = classNames('zen-drawer__btn', 'zen-drawer__back-btn', {
      'zen-drawer__back-btn--hidden': activePageId === pages[0].props.id,
      'zen-drawer__back-btn--visible': activePageId !== pages[0].props.id,
    });

    return (
      <div className="zen-drawer__header" data-testid="base-drawer-header">
        <IconButton
          className={backBtnClass}
          onClick={activePageId !== pages[0].props.id ? handleBackClick : noop}
          type="chevron-left"
        />
        <Heading.Small className="zen-drawer__title">
          {currentPage && currentPage.props.title
            ? currentPage.props.title
            : ''}
        </Heading.Small>
        <IconButton
          className="zen-drawer__btn zen-drawer__close-x-btn"
          onClick={onRequestClose}
          type="remove"
        />
      </div>
    );
  };

  const renderDrawerBody = (): React.Node => {
    const currentPage = pages.find(
      (page: React.Element<typeof Drawer.Page>) =>
        page.props.id === activePageId,
    );
    const fullClassName = classNames('zen-drawer__body', {
      'zen-drawer__body--full-screen': fullScreen,
    });
    return <div className={fullClassName}>{currentPage}</div>;
  };

  const fullClassName = classNames('zen-drawer', className, {
    'zen-drawer--full-screen': fullScreen,
  });

  return (
    <Modal
      className={fullClassName}
      contentLabel="Prompt Drawer"
      isOpen={show}
      onRequestClose={onRequestClose}
      overlayClassName="zen-drawer__overlay"
    >
      {maybeRenderDrawerHeader()}
      {renderDrawerBody()}
    </Modal>
  );
};

export default Drawer;

Drawer.Page = ({ children, className = '', id }: PageProps): React.Node => (
  <div className={className} id={id}>
    {children}
  </div>
);

if (document.getElementById('main')) {
  Modal.setAppElement('#main');
} else if (document.getElementById('rsg-root')) {
  Modal.setAppElement('#rsg-root');
} else {
  const drawerRoot = document.createElement('div');
  drawerRoot.setAttribute('id', 'drawer-root');
  if (document.body) {
    document.body.appendChild(drawerRoot);
    Modal.setAppElement('#drawer-root');
  }
}
