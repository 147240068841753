// @flow
import * as React from 'react';

import AddDataModal from 'components/DataUploadApp/AddDataModal';
import DataUploadService from 'services/DataUploadApp/DataUploadService';
import HeaderBlock from 'components/DataUploadApp/HeaderBlock';
import SelfServeSource from 'models/DataUploadApp/SelfServeSource';
import SelfServeSourceService, {
  useSelfServeSourceStateContext,
} from 'services/DataUploadApp/SelfServeSourceService';
import SourceTable from 'components/DataUploadApp/SourceTable';
import useBoolean from 'lib/hooks/useBoolean';
import useFieldHierarchy from 'components/DataCatalogApp/common/hooks/aqt/useFieldHierarchy';
import useGroupingHierarchy from 'components/DataCatalogApp/common/hooks/aqt/useGroupingHierarchy';
import usePipelineTimes from 'components/DataUploadApp/usePipelineTimes';
import { DATAPREP_TYPE } from 'models/DataUploadApp/types';
import type { DataUploadModalState } from 'components/DataUploadApp/AddDataModal/useDataUploadModalContext';

type Props = {
  isSelfServeAdmin: boolean,
};

export default function DataStatusPage({
  isSelfServeAdmin,
}: Props): React.Node {
  const { reload } = useSelfServeSourceStateContext();
  const [modalVisible, showModal, hideModal] = useBoolean(false);
  const [initialSelfServeSourceId, setInitialSelfServeSourceId] =
    React.useState<number | void>(undefined);
  const [existingSources, setExistingSources] = React.useState<Set<string>>(
    new Set(),
  );

  React.useEffect(() => {
    SelfServeSourceService.getSourceIds().then(sourceIds => {
      setExistingSources(sourceIds);
    });
  }, []);

  // Only use the first variable returned because we only need the hierarchy root.
  const fieldHierarchyRoot = useFieldHierarchy()[0];
  const dimensionHierarchyRoot = useGroupingHierarchy(false, true);

  const { lastPipelineRuntime, nextPipelineRuntime } = usePipelineTimes();

  const buildOnOpenModal =
    (source: SelfServeSource | void = undefined) =>
    () => {
      if (source) {
        setInitialSelfServeSourceId(source.id());
      }
      showModal();
    };
  // Opening the modal from the header block creates a new source, so the source is undefined.
  const onOpenModal = buildOnOpenModal();

  // Sources that had files uploaded and then cancelled need to clean those files up.
  const onCloseModal = (dataUploadState: DataUploadModalState | void) => {
    if (dataUploadState) {
      const filesToUpload = dataUploadState.fileSummaries
        .values()
        .filter(fileSummary => !fileSummary.fileSummaryId)
        .map(fileSummary => fileSummary.filePath);

      if (filesToUpload) {
        DataUploadService.cleanFiles(
          dataUploadState.sourceId,
          dataUploadState.sourceType === DATAPREP_TYPE,
          filesToUpload,
        );
      }
    }
    setInitialSelfServeSourceId(undefined);
    hideModal();
  };

  return (
    <div className="data-status-page min-full-page-height">
      <HeaderBlock
        isSelfServeAdmin={isSelfServeAdmin}
        lastPipelineRuntime={lastPipelineRuntime}
        nextPipelineRuntime={nextPipelineRuntime}
        onOpenModal={onOpenModal}
      />
      <SourceTable
        buildOnOpenModal={buildOnOpenModal}
        isSelfServeAdmin={isSelfServeAdmin}
        lastPipelineRuntime={lastPipelineRuntime}
      />
      {modalVisible && (
        <AddDataModal
          dimensionHierarchyRoot={dimensionHierarchyRoot}
          existingDataUploadSources={existingSources}
          fieldHierarchyRoot={fieldHierarchyRoot}
          initialSelfServeSourceId={initialSelfServeSourceId}
          onCloseModal={onCloseModal}
          reloadSelfServeSources={reload}
        />
      )}
    </div>
  );
}
