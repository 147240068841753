// @flow

export const DATE_FORMAT = 'MMM d, yyyy';

export const DATE_TIME_FORMAT = `${DATE_FORMAT} H:mm`;

export const FAILED_DATAPREP_STATUSES: Set<string> = new Set([
  'Failed',
  'Canceled',
]);
export const RUNNING_DATAPREP_STATUSES: Set<string> = new Set([
  'Created',
  'Pending',
  'InProgress',
]);
