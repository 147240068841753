// @flow
import * as Zen from 'lib/Zen';
import { VISIBILITY_STATUS_MAP } from 'models/core/DataCatalog/constants';
import type { Serializable } from 'lib/Zen';
import type { VisibilityStatus } from 'models/core/DataCatalog/constants';

// Model representation that we receive from the backend
export type SerializedFieldCategoryMapping = {
  categoryId: string,
  isVisible: boolean,
};

type RequiredValues = {
  categoryId: string,
  isVisible: boolean,
};

type DerivedValues = {
  visibilityStatus: VisibilityStatus,
};

class FieldCategoryMapping
  extends Zen.BaseModel<FieldCategoryMapping, RequiredValues, {}, DerivedValues>
  implements Serializable<SerializedFieldCategoryMapping>
{
  static derivedConfig: Zen.DerivedConfig<FieldCategoryMapping, DerivedValues> =
    {
      visibilityStatus: [
        Zen.hasChanged<FieldCategoryMapping>('isVisible'),
        fcm =>
          fcm.isVisible()
            ? VISIBILITY_STATUS_MAP.visible
            : VISIBILITY_STATUS_MAP.hidden,
      ],
    };

  static deserialize(
    values: SerializedFieldCategoryMapping,
  ): Zen.Model<FieldCategoryMapping> {
    const { categoryId, isVisible } = values;
    return FieldCategoryMapping.create({ categoryId, isVisible });
  }

  serialize(): SerializedFieldCategoryMapping {
    const { categoryId, isVisible } = this.modelValues();
    return {
      categoryId,
      isVisible,
    };
  }
}

export default ((FieldCategoryMapping: $Cast): Class<
  Zen.Model<FieldCategoryMapping>,
>);
