// @flow
import Promise from 'bluebird';

import * as Zen from 'lib/Zen';
import CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import type { Serializable } from 'lib/Zen';
import type { SerializedCohortGroupForQuery } from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';

type Values = {
  cohortGroups: Zen.Array<CohortGroup>,
};

type SerializedCohort = {
  cohortGroups: $ReadOnlyArray<Zen.Serialized<CohortGroup>>,
};

export type SerializedCohortForQuery = {
  cohortGroups: $ReadOnlyArray<SerializedCohortGroupForQuery>,
};

/**
 * A cohort will calculate the number of unique values that can be found in
 * *every* cohort group stored.
 */
class Cohort
  extends Zen.BaseModel<Cohort, Values>
  implements Serializable<SerializedCohort>
{
  static deserializeAsync(
    values: SerializedCohort,
  ): Promise<Zen.Model<Cohort>> {
    return Promise.all(
      values.cohortGroups.map(CohortGroup.deserializeAsync),
    ).then(([...cohortGroups]) =>
      Cohort.create({
        cohortGroups: Zen.Array.create(cohortGroups),
      }),
    );
  }

  static UNSAFE_deserialize(values: SerializedCohort): Zen.Model<Cohort> {
    return Cohort.create({
      cohortGroups: Zen.Array.create(
        values.cohortGroups.map(CohortGroup.UNSAFE_deserialize),
      ),
    });
  }

  serialize(): SerializedCohort {
    return {
      cohortGroups: this._.cohortGroups().mapValues(c => c.serialize()),
    };
  }

  serializeForQuery(): SerializedCohortForQuery {
    return {
      cohortGroups: this._.cohortGroups().mapValues(c => c.serializeForQuery()),
    };
  }
}

export default ((Cohort: $Cast): Class<Zen.Model<Cohort>>);
