// @flow
import Promise from 'bluebird';

import * as Zen from 'lib/Zen';
import AndFilter from 'models/core/wip/QueryFilter/AndFilter';
import CustomizableTimeInterval from 'models/core/wip/QueryFilterItem/CustomizableTimeInterval';
import NumericValueCohortFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilter';
import NumericValueCohortFilterItemUtil from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';
import OrFilter from 'models/core/wip/QueryFilter/OrFilter';
import QueryFilterItemUtil from 'models/core/wip/QueryFilterItem/QueryFilterItemUtil';
import QueryFilterUtil from 'models/core/wip/QueryFilter/QueryFilterUtil';
import type { NumericValueCohortFilterItem } from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';
import type {
  QueryFilter,
  SerializedQueryFilter,
} from 'models/core/wip/QueryFilter/types';
import type {
  QueryFilterItem,
  SerializedQueryFilterItem,
} from 'models/core/wip/QueryFilterItem/types';
import type { Serializable } from 'lib/Zen';
import type { SetOperation } from 'models/core/wip/Calculation/CohortCalculation/types';

// NOTE(stephen): This is basically a FieldQueryFilterItem type. If that ever
// gets added, we can switch to that. For now, it was easy enough to have the
// type separated out.
export type SegmentField = {|
  +filter: QueryFilter | null,
  +name: string,
|};

// NOTE(stephen): An empty object is a valid serialized filter, but it will
// cause issues with flow if the type is defined with it. Choosing to define
// an object with an optional type property so we can get flow to properly
// refine during deserialization. It will never be used.
// TODO(stephen): Move this type somewhere else.
// TODO(stephen): Will need to create a ComplexFilter so that all fields will
// have a filter. Then, the only null filter here would be if the user selected
// "any event" for the field.
type SerializedOptionalQueryFilter =
  | SerializedQueryFilter
  | { type?: void, ... }
  | null;

type SerializedSegmentField = {|
  +filter: SerializedOptionalQueryFilter,
  +name: string,
|};

type DefaultValues = {
  +field: SegmentField | void,
  +filterOperation: SetOperation,
  +filters: Zen.Array<QueryFilterItem>,
  +invert: boolean,
  +numericValueCohortFilter: NumericValueCohortFilterItem | void,
  +timeInterval: CustomizableTimeInterval | void,
};

type SerializedCohortSegment = {
  field: SerializedSegmentField | void,
  filterOperation: SetOperation,
  filters: $ReadOnlyArray<SerializedQueryFilterItem>,
  invert: boolean,
  numericValueCohortFilter: Zen.Serialized<NumericValueCohortFilterItem> | void,
  timeInterval: Zen.Serialized<CustomizableTimeInterval> | void,
};

export type SerializedCohortSegmentForQuery = {
  filter: SerializedOptionalQueryFilter,

  // NOTE(stephen): The way `invert` works when querying is to calculate all
  // possible unique values and then perform a set difference with the unique
  // values found when the filter is applied. Since each row has a single event,
  // if we want "Sex Workers that did not receive HIV treatment" then we would
  // need to find "all Sex Workers" and then set subtract "all Sex Workers who
  // received HIV treatment".
  invert: boolean,
  numericValueCohortFilter: Zen.Serialized<NumericValueCohortFilter> | void,
};

function deserializeAsyncOptionalQueryFilter(
  filter: SerializedOptionalQueryFilter,
): Promise<QueryFilter | null> {
  if (filter === null || filter.type === undefined) {
    return Promise.resolve(null);
  }
  return QueryFilterUtil.deserializeAsync(filter);
}

function UNSAFE_deserializeOptionalQueryFilter(
  filter: SerializedOptionalQueryFilter,
): QueryFilter | null {
  if (filter === null || filter.type === undefined) {
    return null;
  }
  return QueryFilterUtil.UNSAFE_deserialize(filter);
}

/**
 * A cohort segment represents all values that pass the supplied filters. A
 * segment can contain an initial field (inside a `SegmentField`) that
 * restricts the rows to only ones that match that field's filter. An optional
 * time interval can further restrict when that field's filter must have
 * occurred. Additional filters (in the `filters` property) will be
 * applied on top. Finally, an optional numericValueCohortFilter is applied
 * which determines the number of times an id has to appear for it to be
 * included in the cohort segment
 *
 * Example:
 *   Select all rows where a *Sex Worker was visited* *at least 3 times* in the
 *   *last 14 days* *in Province X*.
 *
 * Pieces:
 *   Field: The query filter for "Sex worker was visited".
 *   Time Interval: The time interval filter for "last 14 days".
 *   Additional filters: Dimension filter for "In Province X".
 *   NumericValueCohortFilter: Specifc cohort analysis filter for "at least 3
 *     times".
 */
class CohortSegment
  extends Zen.BaseModel<CohortSegment, {}, DefaultValues>
  implements Serializable<SerializedCohortSegment>
{
  static defaultValues: DefaultValues = {
    field: undefined,
    filterOperation: 'INTERSECT',
    filters: Zen.Array.create(),
    invert: false,
    numericValueCohortFilter: undefined,
    timeInterval: undefined,
  };

  static deserializeAsync(
    values: SerializedCohortSegment,
  ): Promise<Zen.Model<CohortSegment>> {
    const {
      field,
      filterOperation,
      filters,
      invert,
      numericValueCohortFilter,
      timeInterval,
    } = values;
    const segmentFieldFilterPromise = deserializeAsyncOptionalQueryFilter(
      field !== undefined ? field.filter : null,
    );
    const timeIntervalFilterPromise =
      timeInterval !== undefined
        ? CustomizableTimeInterval.deserializeAsync(timeInterval)
        : Promise.resolve(undefined);
    const additionalFiltersPromise = Promise.all(
      filters.map(f => QueryFilterItemUtil.deserializeAsync(f)),
    );
    return Promise.all([
      segmentFieldFilterPromise,
      timeIntervalFilterPromise,
      additionalFiltersPromise,
    ]).then(([segmentFieldFilter, timeIntervalFilter, additionalFilters]) =>
      CohortSegment.create({
        filterOperation,
        invert,
        field:
          field !== undefined
            ? { filter: segmentFieldFilter, name: field.name }
            : undefined,
        filters: Zen.Array.create(additionalFilters),
        numericValueCohortFilter:
          numericValueCohortFilter !== undefined
            ? NumericValueCohortFilterItemUtil.deserialize(
                numericValueCohortFilter,
              )
            : undefined,
        timeInterval: timeIntervalFilter,
      }),
    );
  }

  static UNSAFE_deserialize(
    values: SerializedCohortSegment,
  ): Zen.Model<CohortSegment> {
    const {
      field,
      filterOperation,
      filters,
      invert,
      numericValueCohortFilter,
      timeInterval,
    } = values;
    let deserializedField;
    if (field !== undefined) {
      deserializedField = {
        filter: UNSAFE_deserializeOptionalQueryFilter(field.filter),
        name: field.name,
      };
    }
    return CohortSegment.create({
      filterOperation,
      invert,
      field: deserializedField,
      filters: Zen.Array.create(
        filters.map(QueryFilterItemUtil.UNSAFE_deserialize),
      ),
      numericValueCohortFilter:
        numericValueCohortFilter !== undefined
          ? NumericValueCohortFilterItemUtil.deserialize(
              numericValueCohortFilter,
            )
          : undefined,
      timeInterval:
        timeInterval !== undefined
          ? CustomizableTimeInterval.UNSAFE_deserialize(timeInterval)
          : undefined,
    });
  }

  serialize(): SerializedCohortSegment {
    const {
      field,
      filterOperation,
      filters,
      invert,
      numericValueCohortFilter,
      timeInterval,
    } = this.modelValues();

    let serializedField;
    if (field !== undefined) {
      serializedField = {
        filter: field.filter !== null ? field.filter.serialize() : {},
        name: field.name,
      };
    }
    return {
      filterOperation,
      invert,
      field: serializedField,
      filters: QueryFilterItemUtil.serializeAppliedItems(filters.arrayView()),
      numericValueCohortFilter:
        numericValueCohortFilter !== undefined
          ? numericValueCohortFilter.serialize()
          : undefined,
      timeInterval:
        timeInterval !== undefined ? timeInterval.serialize() : undefined,
    };
  }

  serializeForQuery(): SerializedCohortSegmentForQuery {
    const {
      field,
      filterOperation,
      filters,
      invert,
      numericValueCohortFilter,
      timeInterval,
    } = this.modelValues();

    const combinedFilters = [];
    if (field !== undefined && field.filter !== null) {
      combinedFilters.push(field.filter);
    }

    if (timeInterval !== undefined) {
      combinedFilters.push(timeInterval.filter());
    }

    const additionalFilterItems = [];
    filters.forEach(f => {
      const filter = QueryFilterItemUtil.getFilter(f);
      if (filter === undefined) {
        return;
      }
      additionalFilterItems.push(filter);
    });

    if (additionalFilterItems.length !== 0) {
      if (filterOperation === 'INTERSECT') {
        combinedFilters.push(...additionalFilterItems);
      } else {
        combinedFilters.push(
          OrFilter.create({
            fields: Zen.Array.create(additionalFilterItems),
          }),
        );
      }
    }

    const filter =
      combinedFilters.length !== 0
        ? AndFilter.create({
            fields: Zen.Array.create(combinedFilters),
          }).serialize()
        : {};
    return {
      filter,
      invert,
      numericValueCohortFilter:
        numericValueCohortFilter !== undefined
          ? numericValueCohortFilter.filter().serialize()
          : undefined,
    };
  }
}

export default ((CohortSegment: $Cast): Class<Zen.Model<CohortSegment>>);
