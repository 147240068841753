// @flow
import * as React from 'react';

import AddItemButton from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/common/AddItemButton';
import BorderlessInputText from 'components/ui/BorderlessInputText';
import CohortSegment from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';
import Group from 'components/ui/Group';
import I18N from 'lib/I18N';
import RemoveItemButton from 'components/ui/RemoveItemButton';
import { SET_OPERATION_LABEL } from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/constants';
import type CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';

type Props = {
  cohortGroup: CohortGroup,
  label: string,
  onCohortGroupChange: CohortGroup => void,

  onRemoveCohortGroup?: () => void,
  removable?: boolean,
};

// Build a pretty boolean expression that describes the cohort group's primary
// segment + additional segments.
// Example: A1 and (not A2 or A3)
function buildTitleFormula(cohortGroup: CohortGroup, label: string): string {
  const outerOpLabel =
    SET_OPERATION_LABEL[cohortGroup.outerOperation()].toLowerCase();
  const outerClausePrefix = cohortGroup.primarySegment().invert() ? 'not ' : '';
  const outerClause = `${outerClausePrefix}${label}1`;
  const additionalSegments = cohortGroup.additionalSegments();
  if (additionalSegments.isEmpty()) {
    return outerClause;
  }

  const innerOpLabel =
    SET_OPERATION_LABEL[cohortGroup.innerOperation()].toLowerCase();
  const innerClause = additionalSegments
    .mapValues(
      (segment, idx) => `${segment.invert() ? 'not ' : ''}${label}${idx + 2}`,
    )
    .join(` ${innerOpLabel} `);

  // If the set operations are the same for the outer and inner operations, then
  // we do not need to wrap the inner clause in parentheses.
  if (
    cohortGroup.outerOperation() === cohortGroup.innerOperation() ||
    additionalSegments.size() === 1
  ) {
    return `${outerClause} ${outerOpLabel} ${innerClause}`;
  }
  return `${outerClause} ${outerOpLabel} (${innerClause})`;
}

export default function GroupTitle({
  cohortGroup,
  label,
  onCohortGroupChange,
  onRemoveCohortGroup = () => {},
  removable = false,
}: Props): React.Node {
  const onAddSegment = React.useCallback(() => {
    const additionalSegments = cohortGroup.additionalSegments();
    onCohortGroupChange(
      cohortGroup.additionalSegments(
        additionalSegments.push(CohortSegment.create({})),
      ),
    );
  }, [cohortGroup, onCohortGroupChange]);

  const onChangeGroupName = React.useCallback(
    name => {
      onCohortGroupChange(cohortGroup.name(name));
    },
    [cohortGroup, onCohortGroupChange],
  );

  const groupName = cohortGroup.name();

  const displayedGroupName = groupName !== undefined ? groupName : label;

  return (
    <div className="cohort-group-title">
      <Group.Horizontal
        className="cohort-group-title__group-summary"
        spacing="xs"
      >
        <BorderlessInputText
          initialValue={displayedGroupName}
          onValueSave={onChangeGroupName}
        />
        <span className="cohort-group-title__group-summary-joiner-text">
          {I18N.text('defined by')}
        </span>
        <span>{buildTitleFormula(cohortGroup, label)}</span>
      </Group.Horizontal>
      <div className="cohort-group-title__actions">
        <AddItemButton
          onClick={onAddSegment}
          text={I18N.text('Add condition')}
        />
        {removable && (
          <RemoveItemButton
            onClick={onRemoveCohortGroup}
            tooltipText={I18N.text('Remove cohort group')}
          />
        )}
      </div>
    </div>
  );
}
